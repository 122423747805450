// source: types/v1/exam_status.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.types.v1.ExamStatus', null, global);
/**
 * @enum {number}
 */
proto.types.v1.ExamStatus = {
  EXAM_STATUS_UNSPECIFIED: 0,
  EXAM_STATUS_PUBLISH: 1,
  EXAM_STATUS_PENDING: 2,
  EXAM_STATUS_DRAFT: 3,
  EXAM_STATUS_TRASH: 4,
  EXAM_STATUS_NEW: 5,
  EXAM_STATUS_FAILED: 6,
  EXAM_STATUS_TRIM_PENDING: 7,
  EXAM_STATUS_TRIM_IN_PROGRESS: 8
};

goog.object.extend(exports, proto.types.v1);
