/**
 * @fileoverview gRPC-Web generated client stub for dash.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: dash/v1/past_exam.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var dash_v1_models_pb = require('../../dash/v1/models_pb.js')

var types_v1_academic_year_pb = require('../../types/v1/academic_year_pb.js')

var types_v1_exam_status_pb = require('../../types/v1/exam_status_pb.js')
const proto = {};
proto.dash = {};
proto.dash.v1 = require('./past_exam_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.PastExamServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.PastExamServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.GetPastExamRequest,
 *   !proto.dash.v1.GetPastExamResponse>}
 */
const methodDescriptor_PastExamService_GetPastExam = new grpc.web.MethodDescriptor(
  '/dash.v1.PastExamService/GetPastExam',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.GetPastExamRequest,
  proto.dash.v1.GetPastExamResponse,
  /**
   * @param {!proto.dash.v1.GetPastExamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.GetPastExamResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.GetPastExamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.GetPastExamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.GetPastExamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.PastExamServiceClient.prototype.getPastExam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.PastExamService/GetPastExam',
      request,
      metadata || {},
      methodDescriptor_PastExamService_GetPastExam,
      callback);
};


/**
 * @param {!proto.dash.v1.GetPastExamRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.GetPastExamResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.PastExamServicePromiseClient.prototype.getPastExam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.PastExamService/GetPastExam',
      request,
      metadata || {},
      methodDescriptor_PastExamService_GetPastExam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.PutPastExamRequest,
 *   !proto.dash.v1.PutPastExamResponse>}
 */
const methodDescriptor_PastExamService_PutPastExam = new grpc.web.MethodDescriptor(
  '/dash.v1.PastExamService/PutPastExam',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.PutPastExamRequest,
  proto.dash.v1.PutPastExamResponse,
  /**
   * @param {!proto.dash.v1.PutPastExamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.PutPastExamResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.PutPastExamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.PutPastExamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.PutPastExamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.PastExamServiceClient.prototype.putPastExam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.PastExamService/PutPastExam',
      request,
      metadata || {},
      methodDescriptor_PastExamService_PutPastExam,
      callback);
};


/**
 * @param {!proto.dash.v1.PutPastExamRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.PutPastExamResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.PastExamServicePromiseClient.prototype.putPastExam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.PastExamService/PutPastExam',
      request,
      metadata || {},
      methodDescriptor_PastExamService_PutPastExam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.TrimPastExamRequest,
 *   !proto.dash.v1.TrimPastExamResponse>}
 */
const methodDescriptor_PastExamService_TrimPastExam = new grpc.web.MethodDescriptor(
  '/dash.v1.PastExamService/TrimPastExam',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.TrimPastExamRequest,
  proto.dash.v1.TrimPastExamResponse,
  /**
   * @param {!proto.dash.v1.TrimPastExamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.TrimPastExamResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.TrimPastExamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.TrimPastExamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.TrimPastExamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.PastExamServiceClient.prototype.trimPastExam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.PastExamService/TrimPastExam',
      request,
      metadata || {},
      methodDescriptor_PastExamService_TrimPastExam,
      callback);
};


/**
 * @param {!proto.dash.v1.TrimPastExamRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.TrimPastExamResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.PastExamServicePromiseClient.prototype.trimPastExam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.PastExamService/TrimPastExam',
      request,
      metadata || {},
      methodDescriptor_PastExamService_TrimPastExam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.ListPastExamsRequest,
 *   !proto.dash.v1.ListPastExamsResponse>}
 */
const methodDescriptor_PastExamService_ListPastExams = new grpc.web.MethodDescriptor(
  '/dash.v1.PastExamService/ListPastExams',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.ListPastExamsRequest,
  proto.dash.v1.ListPastExamsResponse,
  /**
   * @param {!proto.dash.v1.ListPastExamsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.ListPastExamsResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.ListPastExamsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.ListPastExamsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.ListPastExamsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.PastExamServiceClient.prototype.listPastExams =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.PastExamService/ListPastExams',
      request,
      metadata || {},
      methodDescriptor_PastExamService_ListPastExams,
      callback);
};


/**
 * @param {!proto.dash.v1.ListPastExamsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.ListPastExamsResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.PastExamServicePromiseClient.prototype.listPastExams =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.PastExamService/ListPastExams',
      request,
      metadata || {},
      methodDescriptor_PastExamService_ListPastExams);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.CreatePastExamsRequest,
 *   !proto.dash.v1.CreatePastExamsResponse>}
 */
const methodDescriptor_PastExamService_CreatePastExams = new grpc.web.MethodDescriptor(
  '/dash.v1.PastExamService/CreatePastExams',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.CreatePastExamsRequest,
  proto.dash.v1.CreatePastExamsResponse,
  /**
   * @param {!proto.dash.v1.CreatePastExamsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.CreatePastExamsResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.CreatePastExamsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.CreatePastExamsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.CreatePastExamsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.PastExamServiceClient.prototype.createPastExams =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.PastExamService/CreatePastExams',
      request,
      metadata || {},
      methodDescriptor_PastExamService_CreatePastExams,
      callback);
};


/**
 * @param {!proto.dash.v1.CreatePastExamsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.CreatePastExamsResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.PastExamServicePromiseClient.prototype.createPastExams =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.PastExamService/CreatePastExams',
      request,
      metadata || {},
      methodDescriptor_PastExamService_CreatePastExams);
};


module.exports = proto.dash.v1;

