import { AuthStorage } from '../../helpers/manager';
import { authServiceGetAccessToken } from '../../api/auth';
/**
 * @constructor
 * @implements {UnaryInterceptor}
 */
// eslint-disable-next-line func-names
const AuthInterceptor = function () { };

/** @override */
AuthInterceptor.prototype.intercept = async (request, invoker) => await authServiceGetAccessToken()
  .catch(error => {
    AuthStorage.removeToken();
    return Promise.reject(error);
  })
  .then(token => {
    const meta = request.getMetadata();
    meta.authorization = `Bearer ${token}`;
    return invoker(request);
  });

export { AuthInterceptor };
export default { AuthInterceptor };