import ReactQuill from "react-quill"
import { useMemo, useRef } from "react";
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';
import '../../components/EditorComponent/dist/quill.css';


export default function Editor({ value, setValue }) {
    const reactQuillRef = useRef();

    let icons = ReactQuill.Quill.import('ui/icons');
    icons.italic = '<i class="bi bi-type-italic"></i>';
    icons.blockquote = '<i class="bi bi-quote"></i>';
    icons.bold = '<i class="bi bi-type-bold"></i>';
    icons.header[2] = '<i class="bi bi-2-square"></i>';
    icons.header[3] = '<i class="bi bi-3-square"></i>';
    icons.header[4] = '<i class="bi bi-4-square"></i>';
    icons.cleanHtml = '<i class="bi bi-stars"></i>';

    let bold = ReactQuill.Quill.import('formats/bold');
    bold.tagName = 'b';   // Quill uses <strong> by default
    ReactQuill.Quill.register(bold, true);

    let italic = ReactQuill.Quill.import('formats/italic');
    italic.tagName = 'i';   // Quill uses <em> by default
    ReactQuill.Quill.register(italic, true);

    const cleanHtmlHandler = () => {
        let text = reactQuillRef.current.editor.getText()
            .replace(/•/g, '\n')
            .replace(/\n\s+|\s+\n/g, function (m) {
                return m.replace(/^[ ]+|[ ]+$/g, "");
            })
            .trim();
        reactQuillRef.current.editor.setText(text);
    };


    const modules = useMemo(() => ({
        toolbar: {
            container: [
                'bold', 'italic', 'blockquote',
                { 'header': 2 },
                { 'header': 3 },
                { 'header': 4 },
                { 'header': [1, 2, 3, 4, 5, 6, false] },
                { 'list': 'ordered' },
                { 'list': 'bullet' },
                'link',
                'clean',
                'cleanHtml'
            ],
            handlers: {
                'cleanHtml': cleanHtmlHandler
            }
        },
    }), []);

    return <ReactQuill
        ref={reactQuillRef}
        theme="snow"
        modules={modules}
        // style={{ height: "100%", overflow: "auto" }}
        defaultValue={value}
        onChange={(content, delta, source, editor) => {
            setValue(editor.getHTML())
        }} />
}