/**
 * @fileoverview gRPC-Web generated client stub for dash.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: dash/v1/exam_type.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var dash_v1_models_pb = require('../../dash/v1/models_pb.js')

var types_v1_status_pb = require('../../types/v1/status_pb.js')
const proto = {};
proto.dash = {};
proto.dash.v1 = require('./exam_type_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.ExamTypeServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.ExamTypeServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.GetExamTypeRequest,
 *   !proto.dash.v1.GetExamTypeResponse>}
 */
const methodDescriptor_ExamTypeService_GetExamType = new grpc.web.MethodDescriptor(
  '/dash.v1.ExamTypeService/GetExamType',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.GetExamTypeRequest,
  proto.dash.v1.GetExamTypeResponse,
  /**
   * @param {!proto.dash.v1.GetExamTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.GetExamTypeResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.GetExamTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.GetExamTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.GetExamTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.ExamTypeServiceClient.prototype.getExamType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.ExamTypeService/GetExamType',
      request,
      metadata || {},
      methodDescriptor_ExamTypeService_GetExamType,
      callback);
};


/**
 * @param {!proto.dash.v1.GetExamTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.GetExamTypeResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.ExamTypeServicePromiseClient.prototype.getExamType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.ExamTypeService/GetExamType',
      request,
      metadata || {},
      methodDescriptor_ExamTypeService_GetExamType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.PutExamTypeRequest,
 *   !proto.dash.v1.PutExamTypeResponse>}
 */
const methodDescriptor_ExamTypeService_PutExamType = new grpc.web.MethodDescriptor(
  '/dash.v1.ExamTypeService/PutExamType',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.PutExamTypeRequest,
  proto.dash.v1.PutExamTypeResponse,
  /**
   * @param {!proto.dash.v1.PutExamTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.PutExamTypeResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.PutExamTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.PutExamTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.PutExamTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.ExamTypeServiceClient.prototype.putExamType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.ExamTypeService/PutExamType',
      request,
      metadata || {},
      methodDescriptor_ExamTypeService_PutExamType,
      callback);
};


/**
 * @param {!proto.dash.v1.PutExamTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.PutExamTypeResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.ExamTypeServicePromiseClient.prototype.putExamType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.ExamTypeService/PutExamType',
      request,
      metadata || {},
      methodDescriptor_ExamTypeService_PutExamType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.ListExamTypesRequest,
 *   !proto.dash.v1.ListExamTypesResponse>}
 */
const methodDescriptor_ExamTypeService_ListExamTypes = new grpc.web.MethodDescriptor(
  '/dash.v1.ExamTypeService/ListExamTypes',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.ListExamTypesRequest,
  proto.dash.v1.ListExamTypesResponse,
  /**
   * @param {!proto.dash.v1.ListExamTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.ListExamTypesResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.ListExamTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.ListExamTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.ListExamTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.ExamTypeServiceClient.prototype.listExamTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.ExamTypeService/ListExamTypes',
      request,
      metadata || {},
      methodDescriptor_ExamTypeService_ListExamTypes,
      callback);
};


/**
 * @param {!proto.dash.v1.ListExamTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.ListExamTypesResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.ExamTypeServicePromiseClient.prototype.listExamTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.ExamTypeService/ListExamTypes',
      request,
      metadata || {},
      methodDescriptor_ExamTypeService_ListExamTypes);
};


module.exports = proto.dash.v1;

