import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen';
import ErrorContent from '../../components/ErrorContent.js';
import Breadcrumbs from '../../components/Breadcrumbs';
import { calendarEventServiceListCalendarEvents, calendarEventServicePutCalendarEvent } from '../../api/calendar_event';
import { CalendarEvent, Area, ExamGroup, ExamType } from '../../grpc/dash/v1/models_pb';
import { areaServiceListAreas } from '../../api/area';
import { academicYear, calendarEventType, status } from '../../api/types.js';
import moment from "moment-timezone";
import { examGroupServiceListExamGroups } from '../../api/exam_group.js';
import { examTypeServiceListExamTypes } from '../../api/exam_type.js';
function CalendarEvents() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [areas, setAreas] = useState([]);
    const [examGroups, setExamGroups] = useState([]);
    const [examTypes, setExamTypes] = useState([]);
    const [breadcrumbData, setBreadcrumbData] = useState(null);

    const [formAreaId, setFormAreaId] = useState(parseInt(searchParams.get('areaId')) || 0)

    const loadScreen = async () => {
        try {
            await loadAreas();
            await loadExamGroups();
            await loadExamTypes();
            await loadCalendarEvents();
            setLoading(false);
        } catch (err) {
            console.log(err);
            setError(err);
            setLoading(false);
        }
    };

    const loadCalendarEvents = async () => {
        const response = await calendarEventServiceListCalendarEvents({ areaId: formAreaId });
        setData(response.toObject().calendarEventsList);
    }

    const loadAreas = async () => {
        const response = await areaServiceListAreas();
        setAreas(response.toObject().areasList);
    }

    const loadExamGroups = async () => {
        const response = await examGroupServiceListExamGroups({});
        setExamGroups(response.toObject().examGroupsList);
    }

    const loadExamTypes = async () => {
        const response = await examTypeServiceListExamTypes({});
        setExamTypes(response.toObject().examTypesList);
    }

    useEffect(() => {
        setSearchParams({
            areaId: formAreaId
        });
        const fetchData = async () => {
            await loadCalendarEvents()
        }
        fetchData();
    }, [formAreaId]);

    useEffect(() => {
        document.title = "TAKVİM"

        setBreadcrumbData([
            { name: 'Dash', url: '/' },
            { name: 'Takvim', url: '' },
        ]);
        loadScreen();
    }, [])

    const onChangeInput = (name, value, index) => {
        switch (name) {
            case "startDate":
            case "endDate":
                if (value === "") {
                    value = undefined;
                } else {
                    value = { seconds: Math.floor(moment.tz(value.replace("T", " "), "UTC") / 1000) }
                }
                data[index][name] = value;
                break;
            case "areaId":
                data[index].area.id = parseInt(value);
                data[index].examGroup.id = 0;
                data[index].examType.id = 0;
                break;
            case "examGroupId":
                data[index].examGroup.id = parseInt(value);
                break;
            case "examTypeId":
                data[index].examType.id = parseInt(value);
                break;

            default:
                data[index][name] = value;
                break;
        }

        setData([...data]);
    }

    const onPutCalendarEvent = async (index) => {

        var item = data[index];
        const result = await calendarEventServicePutCalendarEvent(
            {
                id: item.id,
                areaId: item.area.id,
                startDate: item.startDate,
                endDate: item.endDate,
                title: item.title,
                description: item.description,
                status: item.status,
                academicYear: item.academicYear,
                examGroupId: item.examGroup.id,
                examTypeId: item.examType.id,
                eventType: item.eventType
            }
        );
        data[index] = result.toObject().calendarEvent;
        setData([...data])
    }

    const RenderContent = () => {
        if (loading) {
            return (
                <LoadingScreen />
            );
        } else if (error !== null) {
            return (
                <ErrorContent error={error} clickFunction={() => console.log("Tekrar Yükle")} />
            );
        } else {
            return (
                <table className="card-body table table-striped">
                    <thead>
                        <tr>

                            <th scope="col">

                                <select id="form-area" className="form-select form-select-sm" select={formAreaId} value={formAreaId} onChange={e => setFormAreaId(e.target.value)}>
                                    <option key={`area-0`} value={0}>Tüm Alanlar</option>
                                    {
                                        areas.map((item, index) => (
                                            <option key={`area-${index}`} value={item.id}>{item.title}</option>
                                        ))
                                    }
                                </select>
                            </th>
                            <th>Takvim</th>
                            <th scope="col">Başlık / Açıklama</th>
                            <th scope="col">İşlemler</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data === null || data.length === 0 ? <tr><td colSpan={4} className='text-center'>Henüz içerik eklenmemiş.</td></tr> : data.map((item, index) => {

                                return (
                                    <tr key={"area_" + index}>
                                        <td>
                                            <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text w-50">Alan</span>
                                                <select className="form-select" value={item.area.id} onChange={e => onChangeInput("areaId", e.target.value, index)}>
                                                    <option key={`area-0`} value={0}>Belirtilmemiș</option>
                                                    {
                                                        areas.map((item, index) => (
                                                            <option key={`area-${index}`} value={item.id}>{item.title}</option>
                                                        ))
                                                    }

                                                </select>
                                            </div>
                                            <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text w-50">Grup</span>
                                                <select className="form-select" value={item.examGroup.id} onChange={e => onChangeInput("examGroupId", e.target.value, index)}>
                                                    <option key={`exam-group-0`} value={0}>Belirtilmemiș</option>
                                                    {
                                                        examGroups.filter(s => s.areaId == item.area.id).map((item, index) => (
                                                            <option key={`exam-group-${index}`} value={item.id}>{item.title}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text w-50">Tip</span>
                                                <select className="form-select" value={item.examType.id} onChange={e => onChangeInput("examTypeId", e.target.value, index)}>
                                                    <option key={`exam-type-0`} value={0}>Belirtilmemiș</option>
                                                    {
                                                        examTypes.filter(s => s.areaId == item.area.id).map((item, index) => (
                                                            <option key={`exam-type-${index}`} value={item.id}>{item.title}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text w-50">Akademik Yıl</span>
                                                <select className="form-select" value={item.academicYear} onChange={e => onChangeInput("academicYear", parseInt(e.target.value), index)}>
                                                    {
                                                        Object.values(academicYear()).map((value, index) => (
                                                            <option key={"academic-year" + index} value={value}>{academicYear(value)}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text w-50">Başlangıç</span>
                                                <input type="datetime-local" className="form-control"
                                                    value={item.startDate === undefined ? "" : new Date(item.startDate.seconds * 1000).toISOString().slice(0, -5)}
                                                    onChange={(e) => onChangeInput("startDate", e.target.value, index)} />
                                            </div>
                                            <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text w-50">Bitiş</span>
                                                <input type="datetime-local" className="form-control"
                                                    value={item.endDate === undefined ? "" : new Date(item.endDate.seconds * 1000).toISOString().slice(0, -5)}
                                                    onChange={(e) => onChangeInput("endDate", e.target.value, index)} />
                                            </div>
                                            <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text w-50">Durum</span>
                                                <select className="form-select form-select-sm mb-1" value={item.status} onChange={e => onChangeInput("status", parseInt(e.target.value), index)}>
                                                    {
                                                        Object.values(status()).map((value, index) => (
                                                            <option key={"status" + index} value={value}>{status(value)}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text w-50">Tip</span>
                                                <select className="form-select" value={item.eventType} onChange={e => onChangeInput("eventType", parseInt(e.target.value), index)}>
                                                    <optgroup label="Takvim">
                                                        {
                                                            Object.values(calendarEventType()).map((value, index) => (
                                                                <option key={"calendar-event-type" + index} value={value}>{calendarEventType(value)}</option>
                                                            ))
                                                        }
                                                    </optgroup>
                                                </select>
                                            </div>
                                        </td>

                                        <td className="col-4">
                                            <textarea className="form-control form-select-sm mb-1" placeholder="Başlık" rows="2" value={item.title} onChange={(e) => onChangeInput("title", e.target.value, index)} />
                                            <textarea className="form-control form-select-sm" placeholder="Açıklama" rows="3" value={item.description} onChange={(e) => onChangeInput("description", e.target.value, index)} />
                                        </td>
                                        <td>
                                            <div className="btn-Type" role="Type">
                                                <Link className="btn btn-sm btn-primary" role="button" onClick={() => onPutCalendarEvent(index)}>KAYDET</Link>
                                            </div>
                                        </td>

                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            );
        }
    };

    return (
        <>
            <div className="container-fluid">

                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <Breadcrumbs data={breadcrumbData} />
                        <div className="btn-Type">
                            <Link className="btn btn-sm btn-primary" onClick={() => {
                                setData([...data, new CalendarEvent()
                                    .setArea(new Area.Simple())
                                    .setExamGroup(new ExamGroup.Simple())
                                    .setExamType(new ExamType.Simple())
                                    .toObject()])
                            }}>+</Link>
                        </div>
                    </div>
                    {RenderContent()}

                </div>
            </div>

        </>
    );
}

export default CalendarEvents;