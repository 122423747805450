import { areaService } from './services';
import { GetAreaRequest, PutAreaRequest, ListAreasRequest } from '../grpc/dash/v1/area_pb';

export const areaServiceGetArea = (id) => areaService.getArea(new GetAreaRequest().setId(id));

export const areaServiceListAreas = () => areaService.listAreas(new ListAreasRequest());

export const areaServicePutArea = ({ id, title, status }) => areaService.putArea(new PutAreaRequest()
    .setId(id)
    .setTitle(title)
    .setStatus(status));