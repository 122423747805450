import { pastExamBookletService } from './services';
import { GetPastExamBookletRequest, PutPastExamBookletRequest, ListPastExamBookletsRequest } from '../grpc/dash/v1/past_exam_booklet_pb';
import { PastExamBooklet, File } from '../grpc/dash/v1/models_pb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

export const pastExamBookletServiceGetPastExamBooklet = (id) => pastExamBookletService.getPastExamBooklet(new GetPastExamBookletRequest().setId(id));

export const pastExamBookletServiceListPastExamBooklets = ({ areaId, examGroupId, examTypeId, academicYear }) => pastExamBookletService.listPastExamBooklets(new ListPastExamBookletsRequest()
    .setAreaId(areaId)
    .setExamGroupId(examGroupId)
    .setExamTypeId(examTypeId)
    .setAcademicYear(academicYear));

export const pastExamBookletServicePutPastExamBooklet = (booklet, file) => pastExamBookletService.putPastExamBooklet(
    new PutPastExamBookletRequest()
        .setBooklet(
            new PastExamBooklet()
                .setId(booklet.id)
                .setAreaId(booklet.areaId)
                .setExamGroupId(booklet.examGroupId)
                .setExamTypeId(booklet.examTypeId)
                .setAcademicYear(booklet.academicYear)
                .setDate(booklet.date === undefined ? null : new Timestamp()
                    .setSeconds(booklet.date.seconds)
                    .setNanos(booklet.date.nanos)))
        .setFile(file === null ? null : new File()
            .setName(file.name)
            .setContent(file.content)
            .setType(file.type)));