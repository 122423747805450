import React from 'react';

const NoContent = ({ title, message }) => {
  console.log('NoContent');
  //console.log(props);
  return (
    <div style={styles.content}>
      <p style={styles.contentTitle}>{title ?? "İçerik bulunamadı!"}</p>
      <p style={styles.contentTitle}>{message ?? "Henüz içerik eklenmemiş."}</p>
    </div>
  );
};

const styles = {
  content: {
    backgroundColor: 'white',
    padding: 22,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  contentTitle: {
    fontSize: 18,
    marginBottom: 12,
    textAlign: 'center',
  },
};

export default NoContent;