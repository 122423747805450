import { faqService } from './services';
import { PutFAQRequest, ListFAQsRequest } from '../grpc/dash/v1/faq_pb';
import { FAQ } from '../grpc/dash/v1/models_pb';


export const faqServiceListFAQs = ({ entity, entityId }) => faqService.listFAQs(new ListFAQsRequest().setEntity(entity).setEntityId(entityId));

export const faqServicePutFAQ = ({ id, status, entity, entityId, question, answer }) => faqService.putFAQ(new PutFAQRequest()
    .setId(id)
    .setStatus(status)
    .setEntity(entity)
    .setEntityId(entityId)
    .setQuestion(question)
    .setAnswer(answer)
);