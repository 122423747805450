/**
 * @fileoverview gRPC-Web generated client stub for auth.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: auth/v1/auth.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.auth = {};
proto.auth.v1 = require('./auth_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.auth.v1.AuthServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.auth.v1.AuthServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.v1.RegisterRequest,
 *   !proto.auth.v1.RegisterResponse>}
 */
const methodDescriptor_AuthService_Register = new grpc.web.MethodDescriptor(
  '/auth.v1.AuthService/Register',
  grpc.web.MethodType.UNARY,
  proto.auth.v1.RegisterRequest,
  proto.auth.v1.RegisterResponse,
  /**
   * @param {!proto.auth.v1.RegisterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.v1.RegisterResponse.deserializeBinary
);


/**
 * @param {!proto.auth.v1.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.v1.RegisterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.v1.RegisterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.v1.AuthServiceClient.prototype.register =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.v1.AuthService/Register',
      request,
      metadata || {},
      methodDescriptor_AuthService_Register,
      callback);
};


/**
 * @param {!proto.auth.v1.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.v1.RegisterResponse>}
 *     Promise that resolves to the response
 */
proto.auth.v1.AuthServicePromiseClient.prototype.register =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.v1.AuthService/Register',
      request,
      metadata || {},
      methodDescriptor_AuthService_Register);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.v1.LoginRequest,
 *   !proto.auth.v1.LoginResponse>}
 */
const methodDescriptor_AuthService_Login = new grpc.web.MethodDescriptor(
  '/auth.v1.AuthService/Login',
  grpc.web.MethodType.UNARY,
  proto.auth.v1.LoginRequest,
  proto.auth.v1.LoginResponse,
  /**
   * @param {!proto.auth.v1.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.v1.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.auth.v1.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.v1.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.v1.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.v1.AuthServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.v1.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login,
      callback);
};


/**
 * @param {!proto.auth.v1.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.v1.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.auth.v1.AuthServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.v1.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.v1.RefreshRequest,
 *   !proto.auth.v1.RefreshResponse>}
 */
const methodDescriptor_AuthService_Refresh = new grpc.web.MethodDescriptor(
  '/auth.v1.AuthService/Refresh',
  grpc.web.MethodType.UNARY,
  proto.auth.v1.RefreshRequest,
  proto.auth.v1.RefreshResponse,
  /**
   * @param {!proto.auth.v1.RefreshRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.v1.RefreshResponse.deserializeBinary
);


/**
 * @param {!proto.auth.v1.RefreshRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.v1.RefreshResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.v1.RefreshResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.v1.AuthServiceClient.prototype.refresh =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.v1.AuthService/Refresh',
      request,
      metadata || {},
      methodDescriptor_AuthService_Refresh,
      callback);
};


/**
 * @param {!proto.auth.v1.RefreshRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.v1.RefreshResponse>}
 *     Promise that resolves to the response
 */
proto.auth.v1.AuthServicePromiseClient.prototype.refresh =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.v1.AuthService/Refresh',
      request,
      metadata || {},
      methodDescriptor_AuthService_Refresh);
};


module.exports = proto.auth.v1;

