import { lessonService } from './services';
import { GetLessonRequest, PutLessonRequest, ListLessonsRequest } from '../grpc/dash/v1/lesson_pb';
import { Lesson } from '../grpc/dash/v1/models_pb';

export const lessonServiceGetLesson = (id) => lessonService.getLesson(new GetLessonRequest().setId(id));

export const lessonServiceListLessons = ({ areaId }) => lessonService.listLessons(new ListLessonsRequest().setAreaId(areaId || 0));

export const lessonServicePutLesson = ({ id, areaId, title, code, status }) => lessonService.putLesson(new PutLessonRequest()
    .setId(id)
    .setAreaId(areaId)
    .setTitle(title)
    .setCode(code)
    .setStatus(status)
);