import { authClient, mutex } from './services';
import { LoginRequest, RefreshRequest, } from '../grpc/auth/v1/auth_pb';
import { AuthStorage } from '../helpers/manager';

export const authServiceLogin = (email, password) => authClient.login(new LoginRequest()
    .setEmail(email)
    .setPassword(password)
);

export const authServiceRefresh = (refreshToken) => authClient.refresh(new RefreshRequest().setRefreshToken(refreshToken));


export const authServiceGetAccessToken = () => mutex
    .runExclusive(async () => {
        let token = AuthStorage.getToken();
        if (token !== null && token.accessToken !== undefined && token.accessTokenExpiration > new Date()) {
            return token.accessToken;
        }
        if (token.refreshToken === undefined || token.refreshTokenExpiration < (new Date())) {
            throw new Error("no access token");
        }

        const responseRefresh = await authClient.refresh(new RefreshRequest().setRefreshToken(token.refreshToken));
        token = responseRefresh.toObject();
        AuthStorage.setToken(token);
        return token.accessToken;
    }).then((token) => {
        return Promise.resolve(token)
    }).catch((error) => {
        return Promise.reject(error)
    });