// source: dash/v1/faq.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var dash_v1_models_pb = require('../../dash/v1/models_pb.js');
goog.object.extend(proto, dash_v1_models_pb);
var types_v1_entity_pb = require('../../types/v1/entity_pb.js');
goog.object.extend(proto, types_v1_entity_pb);
var types_v1_status_pb = require('../../types/v1/status_pb.js');
goog.object.extend(proto, types_v1_status_pb);
goog.exportSymbol('proto.dash.v1.ListFAQsRequest', null, global);
goog.exportSymbol('proto.dash.v1.ListFAQsResponse', null, global);
goog.exportSymbol('proto.dash.v1.PutFAQRequest', null, global);
goog.exportSymbol('proto.dash.v1.PutFAQResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.PutFAQRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.PutFAQRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.PutFAQRequest.displayName = 'proto.dash.v1.PutFAQRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.PutFAQResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.PutFAQResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.PutFAQResponse.displayName = 'proto.dash.v1.PutFAQResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.ListFAQsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.ListFAQsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.ListFAQsRequest.displayName = 'proto.dash.v1.ListFAQsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.ListFAQsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dash.v1.ListFAQsResponse.repeatedFields_, null);
};
goog.inherits(proto.dash.v1.ListFAQsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.ListFAQsResponse.displayName = 'proto.dash.v1.ListFAQsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.PutFAQRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.PutFAQRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.PutFAQRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutFAQRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    entity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    entityId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    question: jspb.Message.getFieldWithDefault(msg, 5, ""),
    answer: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.PutFAQRequest}
 */
proto.dash.v1.PutFAQRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.PutFAQRequest;
  return proto.dash.v1.PutFAQRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.PutFAQRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.PutFAQRequest}
 */
proto.dash.v1.PutFAQRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.types.v1.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {!proto.types.v1.Entity} */ (reader.readEnum());
      msg.setEntity(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEntityId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.PutFAQRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.PutFAQRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.PutFAQRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutFAQRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getEntity();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getEntityId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getQuestion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.PutFAQRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PutFAQRequest} returns this
 */
proto.dash.v1.PutFAQRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional types.v1.Status status = 2;
 * @return {!proto.types.v1.Status}
 */
proto.dash.v1.PutFAQRequest.prototype.getStatus = function() {
  return /** @type {!proto.types.v1.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.v1.Status} value
 * @return {!proto.dash.v1.PutFAQRequest} returns this
 */
proto.dash.v1.PutFAQRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional types.v1.Entity entity = 3;
 * @return {!proto.types.v1.Entity}
 */
proto.dash.v1.PutFAQRequest.prototype.getEntity = function() {
  return /** @type {!proto.types.v1.Entity} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.types.v1.Entity} value
 * @return {!proto.dash.v1.PutFAQRequest} returns this
 */
proto.dash.v1.PutFAQRequest.prototype.setEntity = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 entity_id = 4;
 * @return {number}
 */
proto.dash.v1.PutFAQRequest.prototype.getEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PutFAQRequest} returns this
 */
proto.dash.v1.PutFAQRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string question = 5;
 * @return {string}
 */
proto.dash.v1.PutFAQRequest.prototype.getQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.PutFAQRequest} returns this
 */
proto.dash.v1.PutFAQRequest.prototype.setQuestion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string answer = 6;
 * @return {string}
 */
proto.dash.v1.PutFAQRequest.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.PutFAQRequest} returns this
 */
proto.dash.v1.PutFAQRequest.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.PutFAQResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.PutFAQResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.PutFAQResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutFAQResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    faq: (f = msg.getFaq()) && dash_v1_models_pb.FAQ.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.PutFAQResponse}
 */
proto.dash.v1.PutFAQResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.PutFAQResponse;
  return proto.dash.v1.PutFAQResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.PutFAQResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.PutFAQResponse}
 */
proto.dash.v1.PutFAQResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dash_v1_models_pb.FAQ;
      reader.readMessage(value,dash_v1_models_pb.FAQ.deserializeBinaryFromReader);
      msg.setFaq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.PutFAQResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.PutFAQResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.PutFAQResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutFAQResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFaq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dash_v1_models_pb.FAQ.serializeBinaryToWriter
    );
  }
};


/**
 * optional FAQ faq = 1;
 * @return {?proto.dash.v1.FAQ}
 */
proto.dash.v1.PutFAQResponse.prototype.getFaq = function() {
  return /** @type{?proto.dash.v1.FAQ} */ (
    jspb.Message.getWrapperField(this, dash_v1_models_pb.FAQ, 1));
};


/**
 * @param {?proto.dash.v1.FAQ|undefined} value
 * @return {!proto.dash.v1.PutFAQResponse} returns this
*/
proto.dash.v1.PutFAQResponse.prototype.setFaq = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PutFAQResponse} returns this
 */
proto.dash.v1.PutFAQResponse.prototype.clearFaq = function() {
  return this.setFaq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PutFAQResponse.prototype.hasFaq = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.ListFAQsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.ListFAQsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.ListFAQsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListFAQsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entity: jspb.Message.getFieldWithDefault(msg, 1, 0),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.ListFAQsRequest}
 */
proto.dash.v1.ListFAQsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.ListFAQsRequest;
  return proto.dash.v1.ListFAQsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.ListFAQsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.ListFAQsRequest}
 */
proto.dash.v1.ListFAQsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.types.v1.Entity} */ (reader.readEnum());
      msg.setEntity(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.ListFAQsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.ListFAQsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.ListFAQsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListFAQsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntity();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional types.v1.Entity entity = 1;
 * @return {!proto.types.v1.Entity}
 */
proto.dash.v1.ListFAQsRequest.prototype.getEntity = function() {
  return /** @type {!proto.types.v1.Entity} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.types.v1.Entity} value
 * @return {!proto.dash.v1.ListFAQsRequest} returns this
 */
proto.dash.v1.ListFAQsRequest.prototype.setEntity = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 entity_id = 2;
 * @return {number}
 */
proto.dash.v1.ListFAQsRequest.prototype.getEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.ListFAQsRequest} returns this
 */
proto.dash.v1.ListFAQsRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dash.v1.ListFAQsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.ListFAQsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.ListFAQsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.ListFAQsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListFAQsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    faqsList: jspb.Message.toObjectList(msg.getFaqsList(),
    dash_v1_models_pb.FAQ.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.ListFAQsResponse}
 */
proto.dash.v1.ListFAQsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.ListFAQsResponse;
  return proto.dash.v1.ListFAQsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.ListFAQsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.ListFAQsResponse}
 */
proto.dash.v1.ListFAQsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dash_v1_models_pb.FAQ;
      reader.readMessage(value,dash_v1_models_pb.FAQ.deserializeBinaryFromReader);
      msg.addFaqs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.ListFAQsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.ListFAQsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.ListFAQsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListFAQsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFaqsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      dash_v1_models_pb.FAQ.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FAQ faqs = 1;
 * @return {!Array<!proto.dash.v1.FAQ>}
 */
proto.dash.v1.ListFAQsResponse.prototype.getFaqsList = function() {
  return /** @type{!Array<!proto.dash.v1.FAQ>} */ (
    jspb.Message.getRepeatedWrapperField(this, dash_v1_models_pb.FAQ, 1));
};


/**
 * @param {!Array<!proto.dash.v1.FAQ>} value
 * @return {!proto.dash.v1.ListFAQsResponse} returns this
*/
proto.dash.v1.ListFAQsResponse.prototype.setFaqsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dash.v1.FAQ=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dash.v1.FAQ}
 */
proto.dash.v1.ListFAQsResponse.prototype.addFaqs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dash.v1.FAQ, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dash.v1.ListFAQsResponse} returns this
 */
proto.dash.v1.ListFAQsResponse.prototype.clearFaqsList = function() {
  return this.setFaqsList([]);
};


goog.object.extend(exports, proto.dash.v1);
