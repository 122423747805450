import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import LoadingScreen from "../../components/LoadingScreen";
import ErrorContent from "../../components/ErrorContent";
import LoadingButton from "../../components/LoadingButton";
import { Lesson } from "../../grpc/dash/v1/models_pb";
import { areaServiceListAreas } from "../../api/area";
import { lessonServiceGetLesson, lessonServicePutLesson } from "../../api/lesson";
import { status } from "../../api/types";

function LessonEdit() {
    let { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(new Lesson().toObject());
    const [areas, setAreas] = useState([]);
    const [breadcrumbData, setBreadcrumbData] = useState(null);

    const loadAreas = async () => {
        const response = await areaServiceListAreas();
        setAreas(response.toObject().areasList);
    }

    const loadScreen = async () => {
        try {
            await loadAreas();

            const breadcrumbItems = [{ name: "DERSLER", url: "/lessons" }];
            if (id === undefined) {
                breadcrumbItems.push({ name: "YENİ", url: "" });
            } else {
                const result = await lessonServiceGetLesson(id);
                console.log(result.toObject().lesson)
                setData(result.toObject().lesson)
                breadcrumbItems.push({ name: `#${id}`, url: "" });
            }
            setBreadcrumbData(breadcrumbItems);
            setLoading(false);
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    }
    const setCustomData = (key, value) => {

        switch (key) {
            case "areaId":
                data["area"] = { id: parseInt(value) };
                break;
            case "status":
                data[key] = parseInt(value);
                break;
            default:
                data[key] = value;
                break;
        }

        setData({ ...data })
    }
    const onPressForm = async (e) => {
        e.preventDefault();
        try {
            let result = await lessonServicePutLesson({ id: data.id, areaId: data.area.id, title: data.title, code: data.code, status: data.status });
            navigate(`/lessons?areaId=${result.toObject().lesson.area.id}`);
        } catch (err) {
            console.log(err);
            setError(err);
        }
    }
    const RenderContent = () => {
        if (loading)
            return <LoadingScreen />;
        if (error !== null)
            return <ErrorContent error={error} />
        return <form className="card-body row g-3">

            <div className="col-md-8 py-1">
                <label htmlFor="form-area" className="form-label">ALAN</label>
                <select id="form-area" className="form-select" defaultValue={data.area?.id} onChange={e => setCustomData("areaId", e.target.value)}>
                    <option key={`area-0`} value={0}>Belirtilmemiş</option>
                    {
                        areas.map((item, index) => <option key={`area-${index}`} value={item.id}>{item.title}</option>)
                    }
                </select>
            </div>
            <div className="col-md-4 py-1">
                <label htmlFor="form-status" className="form-label">DURUM</label>
                <select id="form-status" className="form-select" defaultValue={data.status} onChange={e => setCustomData("status", e.target.value)}>
                    {
                        Object.values(status()).map((value, index) => (
                            <option key={"status" + index} value={index}>{status(value)}</option>
                        ))
                    }
                </select>
            </div>

            <div className="col-md-8 py-1">
                <label htmlFor="form-title" className="form-label">BAŞLIK</label>
                <input type="text" className="form-control" id="form-title" value={data.title} onChange={e => setCustomData("title", e.target.value)} />
            </div>
            <div className="col-md-4 py-1">
                <label htmlFor="form-code" className="form-label">KOD</label>
                <input type="text" className="form-control" id="form-code" value={data.code} onChange={e => setCustomData("code", e.target.value)} />
            </div>
            <div className="col-md-8 py-1">
                <table className="table">
                    <thead><tr><th>Bağlantı</th></tr></thead>
                    <tbody>
                        {data.slugsList
                            .sort((a, b) => { return b.createdAt.seconds - a.createdAt.seconds; })
                            .map((item, index) => {
                                return <tr scope="row" key={"slug_" + index}><td>
                                    <span className="badge text-bg-light">{new Date(item.createdAt.seconds * 1000).toLocaleString("tr")}</span>
                                    <span className={index == 0 ? "badge text-bg-success" : "badge text-bg-secondary"}>{item.value}</span>

                                </td></tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="col-12">
                <LoadingButton onClick={onPressForm} Text="KAYDET" />
            </div>
        </form >
    };
    useEffect(() => {
        loadScreen();
    }, []);
    return <div className="container">
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <Breadcrumbs data={breadcrumbData} />
            </div>
            {RenderContent()}
        </div>
    </div>;
}

export default LessonEdit;