import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen.js';
import ErrorContent from '../../components/ErrorContent.js';
import NoContent from '../../components/NoContent.js';
import Breadcrumbs from '../../components/Breadcrumbs.js';
import { areaServiceListAreas } from '../../api/area.js';
import { lessonBookServiceListLessonBooks } from '../../api/lesson_book';
import { lessonServiceListLessons } from '../../api/lesson.js';
import { academicYear, lessonContentType, status } from '../../api/types.js';

function LessonBooks() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [breadcrumbData, setBreadcrumbData] = useState(null);

    const [areas, setAreas] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [formAreaId, setFormAreaId] = useState(parseInt(searchParams.get('areaId')) || 0)
    const [formLessonId, setFormLessonId] = useState(parseInt(searchParams.get('lesson')) || 0)
    const [formStatus, setFormStatus] = useState(parseInt(searchParams.get('status')) || 0)

    const loadScreen = async () => {
        try {
            await loadAreas();
            await loadLessons();
            await loadLessonBooks();
            setLoading(false);
        } catch (err) {
            console.log(err);
            setError(err);
            setLoading(false);
        }
    };

    const loadAreas = async () => {
        const response = await areaServiceListAreas();
        setAreas(response.toObject().areasList);
    }

    const loadLessons = async () => {
        const response = await lessonServiceListLessons(formAreaId);
        console.log(response)
        setLessons(response.toObject().lessonsList);
    }

    const loadLessonBooks = async () => {
        const response = await lessonBookServiceListLessonBooks({ areaId: formAreaId, lessonId: formLessonId, status: formStatus });
        const object = response.toObject();
        setData(object.lessonBooksList);
    }
    useEffect(() => {
        setSearchParams({
            areaId: formAreaId,
            lessonId: formLessonId,
            status: formStatus,
        });

        console.log("reload data");
        const fetchData = async () => {

            await loadLessonBooks();
        }
        fetchData();
    }, [formAreaId, formLessonId, formStatus]);

    useEffect(() => {
        document.title = "Ders - Kitapları"

        setBreadcrumbData([
            { name: 'Dash', url: '/' },
            { name: 'Ders Kitapları', url: '' },
        ]);
        loadScreen();
    }, []);

    const RenderContent = () => {
        if (loading) {
            return (
                <LoadingScreen />
            );
        }
        else if (error !== null) {
            return (
                <ErrorContent error={error} clickFunction={() => console.log("Tekrar Yükle")} />
            );
        }
        else {
            return (
                <table className="card-body table">
                    <thead>
                        <tr>
                            <th scope="col">Alan</th>
                            <th scope="col">Ders</th>
                            <th scope="col">İçerik Tipi</th>
                            <th scope="col">Hedef Url</th>
                            <th scope="col">Durum</th>
                            <th scope="col">Son Güncelleme</th>
                            <th scope="col">İşlemler</th>
                        </tr>
                        <tr>
                            <th scope="col">
                                <select id="form-area" className="form-select form-select-sm" select={formAreaId} value={formAreaId} onChange={e => {
                                    setFormAreaId(e.target.value);
                                    setFormLessonId(0);
                                }}>
                                    <option key={`area-0`} value={""}>Belirtilmemiş</option>
                                    {
                                        areas.map((item, index) => (
                                            <option key={`area-${index}`} value={item.id}>{item.title}</option>
                                        ))
                                    }
                                </select>
                            </th>
                 
                            <th scope="col">
                                <select disabled={formAreaId == ""} id="form-lesson" className="form-select form-select-sm" select={formLessonId} value={formLessonId} onChange={e => setFormLessonId(e.target.value)}>
                                    <option key={`lesson-0`} value={""}>Belirtilmemiş</option>
                                    {
                                        lessons.filter(s => s.area.id == formAreaId).map((item, index) => (
                                            <option key={`lesson-${index}`} value={item.id}>{item.title}</option>
                                        ))
                                    }
                                </select>
                            </th>
                            {/* <th>
                                <select id="form-lesson-content-type" className="form-select form-select-sm" select={0} value={0} onChange={e => setFormStatus(e.target.value)}>
                                    {
                                        Object.values(status()).map((value, index) => (
                                            <option key={"status" + index} value={value}>{status(value)}</option>
                                        ))
                                    }
                                </select>
                            </th> */}
                            {/* <th>
                                <select id="form-status" className="form-select form-select-sm" select={formStatus} value={formStatus} onChange={e => setFormStatus(e.target.value)}>
                                    {
                                        Object.values(status()).map((value, index) => (
                                            <option key={"status" + index} value={value}>{status(value)}</option>
                                        ))
                                    }
                                </select>
                            </th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data === null || data.length === 0 ? <tr><td colSpan={7} className='text-center'>Henüz içerik eklenmemiş.</td></tr> : data.map((item, Index) => {
                                console.log(item)
                                return (
                                    <tr key={"area_" + Index}>
                                        <td >{areas.find(s => s.id == item.area.id)?.title ?? "-"} </td>
                                        <td >{lessons.find(s => s.id == item.lesson.id)?.title ?? "-"} </td>
                                        <td>{lessonContentType(item.lessonContentType )}</td>
                                        <th><a href={item.targetUrl} target='__blank'>Git.</a></th>
                                        <td>{status(item.status )}</td>
                                        <td>{item.updatedAt ? new Date(item.updatedAt.seconds * 1000).toISOString().slice(0, -5).replace("T", " ") : "-"}</td>
                                        <td>
                                            <div className="btn-group" role="group">
                                                <Link to={item.id.toString()} className="btn btn-sm btn-primary" role="button">DÜZENLE</Link>
                                                <Link target='_blank' to={`https://fma.sinavhane.net/lessons/books/${item.id}.pdf`} className="btn btn-sm btn-secondary" role="button">ÖNİZLE</Link>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            );
        }
    };

    return (
        <>
            <div className="container-fluid">

                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <Breadcrumbs data={breadcrumbData} />
                        <div className="btn-group">
                            <Link to="create" className="btn btn-sm btn-primary">+</Link>
                        </div>
                    </div>
                    {RenderContent()}

                </div>
            </div>

        </>
    );
}

export default LessonBooks;