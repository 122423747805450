// source: dash/v1/calendar_event.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var dash_v1_models_pb = require('../../dash/v1/models_pb.js');
goog.object.extend(proto, dash_v1_models_pb);
var types_v1_academic_year_pb = require('../../types/v1/academic_year_pb.js');
goog.object.extend(proto, types_v1_academic_year_pb);
var types_v1_calendar_event_type_pb = require('../../types/v1/calendar_event_type_pb.js');
goog.object.extend(proto, types_v1_calendar_event_type_pb);
var types_v1_status_pb = require('../../types/v1/status_pb.js');
goog.object.extend(proto, types_v1_status_pb);
goog.exportSymbol('proto.dash.v1.GetCalendarEventRequest', null, global);
goog.exportSymbol('proto.dash.v1.GetCalendarEventResponse', null, global);
goog.exportSymbol('proto.dash.v1.ListCalendarEventsRequest', null, global);
goog.exportSymbol('proto.dash.v1.ListCalendarEventsResponse', null, global);
goog.exportSymbol('proto.dash.v1.PutCalendarEventRequest', null, global);
goog.exportSymbol('proto.dash.v1.PutCalendarEventResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.GetCalendarEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.GetCalendarEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.GetCalendarEventRequest.displayName = 'proto.dash.v1.GetCalendarEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.GetCalendarEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.GetCalendarEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.GetCalendarEventResponse.displayName = 'proto.dash.v1.GetCalendarEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.PutCalendarEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.PutCalendarEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.PutCalendarEventRequest.displayName = 'proto.dash.v1.PutCalendarEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.PutCalendarEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.PutCalendarEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.PutCalendarEventResponse.displayName = 'proto.dash.v1.PutCalendarEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.ListCalendarEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.ListCalendarEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.ListCalendarEventsRequest.displayName = 'proto.dash.v1.ListCalendarEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.ListCalendarEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dash.v1.ListCalendarEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.dash.v1.ListCalendarEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.ListCalendarEventsResponse.displayName = 'proto.dash.v1.ListCalendarEventsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.GetCalendarEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.GetCalendarEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.GetCalendarEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.GetCalendarEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.GetCalendarEventRequest}
 */
proto.dash.v1.GetCalendarEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.GetCalendarEventRequest;
  return proto.dash.v1.GetCalendarEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.GetCalendarEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.GetCalendarEventRequest}
 */
proto.dash.v1.GetCalendarEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.GetCalendarEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.GetCalendarEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.GetCalendarEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.GetCalendarEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.GetCalendarEventRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.GetCalendarEventRequest} returns this
 */
proto.dash.v1.GetCalendarEventRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.GetCalendarEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.GetCalendarEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.GetCalendarEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.GetCalendarEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    calendarEvent: (f = msg.getCalendarEvent()) && dash_v1_models_pb.CalendarEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.GetCalendarEventResponse}
 */
proto.dash.v1.GetCalendarEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.GetCalendarEventResponse;
  return proto.dash.v1.GetCalendarEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.GetCalendarEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.GetCalendarEventResponse}
 */
proto.dash.v1.GetCalendarEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dash_v1_models_pb.CalendarEvent;
      reader.readMessage(value,dash_v1_models_pb.CalendarEvent.deserializeBinaryFromReader);
      msg.setCalendarEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.GetCalendarEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.GetCalendarEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.GetCalendarEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.GetCalendarEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCalendarEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dash_v1_models_pb.CalendarEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional CalendarEvent calendar_event = 1;
 * @return {?proto.dash.v1.CalendarEvent}
 */
proto.dash.v1.GetCalendarEventResponse.prototype.getCalendarEvent = function() {
  return /** @type{?proto.dash.v1.CalendarEvent} */ (
    jspb.Message.getWrapperField(this, dash_v1_models_pb.CalendarEvent, 1));
};


/**
 * @param {?proto.dash.v1.CalendarEvent|undefined} value
 * @return {!proto.dash.v1.GetCalendarEventResponse} returns this
*/
proto.dash.v1.GetCalendarEventResponse.prototype.setCalendarEvent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.GetCalendarEventResponse} returns this
 */
proto.dash.v1.GetCalendarEventResponse.prototype.clearCalendarEvent = function() {
  return this.setCalendarEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.GetCalendarEventResponse.prototype.hasCalendarEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.PutCalendarEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.PutCalendarEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.PutCalendarEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutCalendarEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    areaId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    eventType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    title: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    examGroupId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    examTypeId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    academicYear: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.PutCalendarEventRequest}
 */
proto.dash.v1.PutCalendarEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.PutCalendarEventRequest;
  return proto.dash.v1.PutCalendarEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.PutCalendarEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.PutCalendarEventRequest}
 */
proto.dash.v1.PutCalendarEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAreaId(value);
      break;
    case 3:
      var value = /** @type {!proto.types.v1.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {!proto.types.v1.CalendarEventType} */ (reader.readEnum());
      msg.setEventType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExamGroupId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExamTypeId(value);
      break;
    case 11:
      var value = /** @type {!proto.types.v1.AcademicYear} */ (reader.readEnum());
      msg.setAcademicYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.PutCalendarEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.PutCalendarEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.PutCalendarEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutCalendarEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAreaId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getEventType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExamGroupId();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getExamTypeId();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getAcademicYear();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.PutCalendarEventRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PutCalendarEventRequest} returns this
 */
proto.dash.v1.PutCalendarEventRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 area_id = 2;
 * @return {number}
 */
proto.dash.v1.PutCalendarEventRequest.prototype.getAreaId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PutCalendarEventRequest} returns this
 */
proto.dash.v1.PutCalendarEventRequest.prototype.setAreaId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional types.v1.Status status = 3;
 * @return {!proto.types.v1.Status}
 */
proto.dash.v1.PutCalendarEventRequest.prototype.getStatus = function() {
  return /** @type {!proto.types.v1.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.types.v1.Status} value
 * @return {!proto.dash.v1.PutCalendarEventRequest} returns this
 */
proto.dash.v1.PutCalendarEventRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional types.v1.CalendarEventType event_type = 4;
 * @return {!proto.types.v1.CalendarEventType}
 */
proto.dash.v1.PutCalendarEventRequest.prototype.getEventType = function() {
  return /** @type {!proto.types.v1.CalendarEventType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.types.v1.CalendarEventType} value
 * @return {!proto.dash.v1.PutCalendarEventRequest} returns this
 */
proto.dash.v1.PutCalendarEventRequest.prototype.setEventType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string title = 5;
 * @return {string}
 */
proto.dash.v1.PutCalendarEventRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.PutCalendarEventRequest} returns this
 */
proto.dash.v1.PutCalendarEventRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.dash.v1.PutCalendarEventRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.PutCalendarEventRequest} returns this
 */
proto.dash.v1.PutCalendarEventRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp start_date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.PutCalendarEventRequest.prototype.getStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.PutCalendarEventRequest} returns this
*/
proto.dash.v1.PutCalendarEventRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PutCalendarEventRequest} returns this
 */
proto.dash.v1.PutCalendarEventRequest.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PutCalendarEventRequest.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp end_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.PutCalendarEventRequest.prototype.getEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.PutCalendarEventRequest} returns this
*/
proto.dash.v1.PutCalendarEventRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PutCalendarEventRequest} returns this
 */
proto.dash.v1.PutCalendarEventRequest.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PutCalendarEventRequest.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 exam_group_id = 9;
 * @return {number}
 */
proto.dash.v1.PutCalendarEventRequest.prototype.getExamGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PutCalendarEventRequest} returns this
 */
proto.dash.v1.PutCalendarEventRequest.prototype.setExamGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 exam_type_id = 10;
 * @return {number}
 */
proto.dash.v1.PutCalendarEventRequest.prototype.getExamTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PutCalendarEventRequest} returns this
 */
proto.dash.v1.PutCalendarEventRequest.prototype.setExamTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional types.v1.AcademicYear academic_year = 11;
 * @return {!proto.types.v1.AcademicYear}
 */
proto.dash.v1.PutCalendarEventRequest.prototype.getAcademicYear = function() {
  return /** @type {!proto.types.v1.AcademicYear} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.types.v1.AcademicYear} value
 * @return {!proto.dash.v1.PutCalendarEventRequest} returns this
 */
proto.dash.v1.PutCalendarEventRequest.prototype.setAcademicYear = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.PutCalendarEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.PutCalendarEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.PutCalendarEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutCalendarEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    calendarEvent: (f = msg.getCalendarEvent()) && dash_v1_models_pb.CalendarEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.PutCalendarEventResponse}
 */
proto.dash.v1.PutCalendarEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.PutCalendarEventResponse;
  return proto.dash.v1.PutCalendarEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.PutCalendarEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.PutCalendarEventResponse}
 */
proto.dash.v1.PutCalendarEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dash_v1_models_pb.CalendarEvent;
      reader.readMessage(value,dash_v1_models_pb.CalendarEvent.deserializeBinaryFromReader);
      msg.setCalendarEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.PutCalendarEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.PutCalendarEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.PutCalendarEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutCalendarEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCalendarEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dash_v1_models_pb.CalendarEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional CalendarEvent calendar_event = 1;
 * @return {?proto.dash.v1.CalendarEvent}
 */
proto.dash.v1.PutCalendarEventResponse.prototype.getCalendarEvent = function() {
  return /** @type{?proto.dash.v1.CalendarEvent} */ (
    jspb.Message.getWrapperField(this, dash_v1_models_pb.CalendarEvent, 1));
};


/**
 * @param {?proto.dash.v1.CalendarEvent|undefined} value
 * @return {!proto.dash.v1.PutCalendarEventResponse} returns this
*/
proto.dash.v1.PutCalendarEventResponse.prototype.setCalendarEvent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PutCalendarEventResponse} returns this
 */
proto.dash.v1.PutCalendarEventResponse.prototype.clearCalendarEvent = function() {
  return this.setCalendarEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PutCalendarEventResponse.prototype.hasCalendarEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.ListCalendarEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.ListCalendarEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.ListCalendarEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListCalendarEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    areaId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    eventType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.ListCalendarEventsRequest}
 */
proto.dash.v1.ListCalendarEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.ListCalendarEventsRequest;
  return proto.dash.v1.ListCalendarEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.ListCalendarEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.ListCalendarEventsRequest}
 */
proto.dash.v1.ListCalendarEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAreaId(value);
      break;
    case 2:
      var value = /** @type {!proto.types.v1.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {!proto.types.v1.CalendarEventType} */ (reader.readEnum());
      msg.setEventType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.ListCalendarEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.ListCalendarEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.ListCalendarEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListCalendarEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAreaId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getEventType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int32 area_id = 1;
 * @return {number}
 */
proto.dash.v1.ListCalendarEventsRequest.prototype.getAreaId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.ListCalendarEventsRequest} returns this
 */
proto.dash.v1.ListCalendarEventsRequest.prototype.setAreaId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional types.v1.Status status = 2;
 * @return {!proto.types.v1.Status}
 */
proto.dash.v1.ListCalendarEventsRequest.prototype.getStatus = function() {
  return /** @type {!proto.types.v1.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.types.v1.Status} value
 * @return {!proto.dash.v1.ListCalendarEventsRequest} returns this
 */
proto.dash.v1.ListCalendarEventsRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional types.v1.CalendarEventType event_type = 3;
 * @return {!proto.types.v1.CalendarEventType}
 */
proto.dash.v1.ListCalendarEventsRequest.prototype.getEventType = function() {
  return /** @type {!proto.types.v1.CalendarEventType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.types.v1.CalendarEventType} value
 * @return {!proto.dash.v1.ListCalendarEventsRequest} returns this
 */
proto.dash.v1.ListCalendarEventsRequest.prototype.setEventType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dash.v1.ListCalendarEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.ListCalendarEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.ListCalendarEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.ListCalendarEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListCalendarEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    calendarEventsList: jspb.Message.toObjectList(msg.getCalendarEventsList(),
    dash_v1_models_pb.CalendarEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.ListCalendarEventsResponse}
 */
proto.dash.v1.ListCalendarEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.ListCalendarEventsResponse;
  return proto.dash.v1.ListCalendarEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.ListCalendarEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.ListCalendarEventsResponse}
 */
proto.dash.v1.ListCalendarEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dash_v1_models_pb.CalendarEvent;
      reader.readMessage(value,dash_v1_models_pb.CalendarEvent.deserializeBinaryFromReader);
      msg.addCalendarEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.ListCalendarEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.ListCalendarEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.ListCalendarEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListCalendarEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCalendarEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      dash_v1_models_pb.CalendarEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CalendarEvent calendar_events = 1;
 * @return {!Array<!proto.dash.v1.CalendarEvent>}
 */
proto.dash.v1.ListCalendarEventsResponse.prototype.getCalendarEventsList = function() {
  return /** @type{!Array<!proto.dash.v1.CalendarEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, dash_v1_models_pb.CalendarEvent, 1));
};


/**
 * @param {!Array<!proto.dash.v1.CalendarEvent>} value
 * @return {!proto.dash.v1.ListCalendarEventsResponse} returns this
*/
proto.dash.v1.ListCalendarEventsResponse.prototype.setCalendarEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dash.v1.CalendarEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dash.v1.CalendarEvent}
 */
proto.dash.v1.ListCalendarEventsResponse.prototype.addCalendarEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dash.v1.CalendarEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dash.v1.ListCalendarEventsResponse} returns this
 */
proto.dash.v1.ListCalendarEventsResponse.prototype.clearCalendarEventsList = function() {
  return this.setCalendarEventsList([]);
};


goog.object.extend(exports, proto.dash.v1);
