


export default function FileToBase64(file) {
    return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        fileReader.onload = function () {
            return resolve({
                data: fileReader.result.replace(/^data:(.*);base64,/, ''),
                name: file.name,
                size: file.size,
                type: file.type
            });
        }
        fileReader.readAsDataURL(file);
    })
}