import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen';
import ErrorContent from '../../components/ErrorContent.js';
import NoContent from '../../components/NoContent';
import Breadcrumbs from '../../components/Breadcrumbs';
import { areaServiceListAreas } from '../../api/area';
import { status } from "../../api/types";

function Areas() {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [breadcrumbData, setBreadcrumbData] = useState(null);

    const loadScreen = async () => {
        try {
            await loadAreas();
            setLoading(false);
        } catch (err) {
            console.log(err);
            setError(err);
            setLoading(false);
        }
    };

    const loadAreas = async () => {

        const response = await areaServiceListAreas();
        setData(response.toObject().areasList);
    }

    useEffect(() => {
        document.title = "ALANLAR"

        setBreadcrumbData([
            { name: 'Dash', url: '/' },
            { name: 'Alanlar', url: '' },
        ]);
        loadScreen();
    }, [])

    const RenderContent = () => {
        if (loading) {
            return (
                <LoadingScreen />
            );
        }
        else if (error !== null) {
            return (
                <ErrorContent error={error} clickFunction={() => console.log("Tekrar Yükle")} />
            );
        }
        else if (data === null || data.length === 0) {
            return (
                <NoContent />
            );
        }
        else {
            return (
                <table className="card-body table">
                    <thead>
                        <tr>
                            <th scope="col">Başlık</th>
                            <th scope="col">Durum</th>
                            <th scope="col" >İşlemler</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((item, Index) => {
                                return (
                                    <tr key={"area_" + Index}>
                                        <td >{item.title} </td>
                                        <td >{status(item.status)} </td>
                                        <td>
                                            <div className="btn-group" role="group">
                                                <Link to={item.id.toString()} className="btn btn-sm btn-primary" role="button">DÜZENLE</Link>
                                                <div className="btn-group" role="group">
                                                    <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">İÇERİKLER</button>
                                                    <ul className="dropdown-menu">
                                                        <li><Link to={`/booklets?areaId=${item.id}`} className="dropdown-item" role="button">KİTAPÇIKLAR</Link></li>
                                                        <li><Link to={`/exam-groups?areaId=${item.id}`} className="dropdown-item" role="button">SINAV GRUPLARI</Link></li>
                                                        <li><Link to={`/exam-types?areaId=${item.id}`} className="dropdown-item" role="button">SINAV TÜRLERİ</Link></li>
                                                        <li><Link to={`/lessons?areaId=${item.id}`} className="dropdown-item" role="button">DERSLER</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            );
        }
    };

    return (
        <>
            <div className="container-fluid">

                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <Breadcrumbs data={breadcrumbData} />
                        <div className="btn-group">
                            <Link to="create" className="btn btn-sm btn-primary">+</Link>
                        </div>
                    </div>
                    {RenderContent()}

                </div>
            </div>

        </>
    );
}

export default Areas;