import { useEffect, useState } from "react";
import { faqServiceListFAQs, faqServicePut, faqServicePutFAQ } from "../api/faq";
import { status } from "../api/types";
import { FAQ } from "../grpc/dash/v1/models_pb";
import moment from "moment";

function FAQs({ entity, entityId }) {
    const [data, setData] = useState([]);
    const loadData = async () => {
        const result = await faqServiceListFAQs({ entity: entity, entityId: entityId });
        setData(result.toObject().faqsList);
    }
    const onChangeInput = (name, value, index) => {
        data[index][name] = value;
        setData([...data]);
    }

    const onPutFAQ = async (index) => {
        let faq = data[index];
        const result = await faqServicePutFAQ({
            id: faq.id,
            status: faq.status,
            entity: faq.entity,
            entityId: faq.entityId,
            question: faq.question,
            answer: faq.answer
        });
        data[index] = result.toObject().faq;
        setData([...data])
    }

    useEffect(() => {
        loadData();
    }, []);

    if (entity == undefined || entity == 0 || entityId == undefined || entityId == 0)
        return "";


    if (entity == undefined || entity == 0 || entityId == undefined || entityId == 0)
        return "";
    return <div className="card mb-3">
        <div className="card-header d-flex justify-content-between align-items-center">
            SSS
            <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                setData([...data, new FAQ()
                    .setEntity(entity)
                    .setEntityId(entityId)
                    .setStatus(status().STATUS_ACTIVE)
                    .toObject()])
            }}>EKLE</button>
        </div>
        <table className="card-body table mb-0">
            <thead>
                <tr>
                    <th>SORU</th>
                    <th>CEVAP</th>
                    <th>DURUM</th>
                    <th>SON GÜNCELLEME</th>
                    <th>İŞLEMLER</th>
                </tr>
            </thead>
            <tbody>
                {
                    data.map((item, index) => {
                        return <tr key={"event-" + index}>
                            <td className="col-4">
                                <textarea rows="2" className="form-control form-select-sm" value={item.question} onChange={(e) => onChangeInput("question", e.target.value, index)} />
                            </td>
                            <td className="col-4">
                                <textarea rows="2" className="form-control form-select-sm" value={item.answer} onChange={(e) => onChangeInput("answer", e.target.value, index)} />
                            </td>
                            <td>
                                <select className="form-select form-select-sm" defaultValue={item.status} onChange={e => onChangeInput("status", parseInt(e.target.value), index)}>
                                    {
                                        Object.values(status()).map((value, index) => (
                                            <option key={"status" + index} value={value}>{status(value)}</option>
                                        ))
                                    }
                                </select>
                            </td>
                            <td>
                                {item.updatedAt == undefined ? "-" : moment.utc(item.updatedAt.seconds * 1000).format("YYYY-MM-DD HH:mm:ss")}
                            </td>
                            <td>
                                <div className="btn-group">
                                    <button type="button" className="btn btn-sm btn-outline-primary" role="button" onClick={() => onPutFAQ(index)}>
                                        KAYDET
                                    </button>
                                </div>
                            </td>
                        </tr>
                    })
                }
            </tbody>
        </table>

    </div>;
}

export default FAQs;