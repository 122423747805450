// source: dash/v1/models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var types_v1_academic_year_pb = require('../../types/v1/academic_year_pb.js');
goog.object.extend(proto, types_v1_academic_year_pb);
var types_v1_calendar_event_type_pb = require('../../types/v1/calendar_event_type_pb.js');
goog.object.extend(proto, types_v1_calendar_event_type_pb);
var types_v1_entity_pb = require('../../types/v1/entity_pb.js');
goog.object.extend(proto, types_v1_entity_pb);
var types_v1_exam_status_pb = require('../../types/v1/exam_status_pb.js');
goog.object.extend(proto, types_v1_exam_status_pb);
var types_v1_lesson_content_type_pb = require('../../types/v1/lesson_content_type_pb.js');
goog.object.extend(proto, types_v1_lesson_content_type_pb);
var types_v1_post_status_pb = require('../../types/v1/post_status_pb.js');
goog.object.extend(proto, types_v1_post_status_pb);
var types_v1_post_type_pb = require('../../types/v1/post_type_pb.js');
goog.object.extend(proto, types_v1_post_type_pb);
var types_v1_question_option_pb = require('../../types/v1/question_option_pb.js');
goog.object.extend(proto, types_v1_question_option_pb);
var types_v1_status_pb = require('../../types/v1/status_pb.js');
goog.object.extend(proto, types_v1_status_pb);
goog.exportSymbol('proto.dash.v1.Area', null, global);
goog.exportSymbol('proto.dash.v1.Area.Simple', null, global);
goog.exportSymbol('proto.dash.v1.BookletFileInfo', null, global);
goog.exportSymbol('proto.dash.v1.CalendarEvent', null, global);
goog.exportSymbol('proto.dash.v1.ExamGroup', null, global);
goog.exportSymbol('proto.dash.v1.ExamGroup.Simple', null, global);
goog.exportSymbol('proto.dash.v1.ExamType', null, global);
goog.exportSymbol('proto.dash.v1.ExamType.Simple', null, global);
goog.exportSymbol('proto.dash.v1.FAQ', null, global);
goog.exportSymbol('proto.dash.v1.File', null, global);
goog.exportSymbol('proto.dash.v1.Lesson', null, global);
goog.exportSymbol('proto.dash.v1.Lesson.Simple', null, global);
goog.exportSymbol('proto.dash.v1.LessonBook', null, global);
goog.exportSymbol('proto.dash.v1.PastExam', null, global);
goog.exportSymbol('proto.dash.v1.PastExamBooklet', null, global);
goog.exportSymbol('proto.dash.v1.PastExamQuestion', null, global);
goog.exportSymbol('proto.dash.v1.PastExamQuestion.Mark', null, global);
goog.exportSymbol('proto.dash.v1.Post', null, global);
goog.exportSymbol('proto.dash.v1.Slug', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.Slug = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.Slug, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.Slug.displayName = 'proto.dash.v1.Slug';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.Area = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dash.v1.Area.repeatedFields_, null);
};
goog.inherits(proto.dash.v1.Area, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.Area.displayName = 'proto.dash.v1.Area';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.Area.Simple = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.Area.Simple, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.Area.Simple.displayName = 'proto.dash.v1.Area.Simple';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.ExamType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.ExamType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.ExamType.displayName = 'proto.dash.v1.ExamType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.ExamType.Simple = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.ExamType.Simple, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.ExamType.Simple.displayName = 'proto.dash.v1.ExamType.Simple';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.ExamGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.ExamGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.ExamGroup.displayName = 'proto.dash.v1.ExamGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.ExamGroup.Simple = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.ExamGroup.Simple, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.ExamGroup.Simple.displayName = 'proto.dash.v1.ExamGroup.Simple';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.FAQ = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.FAQ, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.FAQ.displayName = 'proto.dash.v1.FAQ';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.LessonBook = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.LessonBook, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.LessonBook.displayName = 'proto.dash.v1.LessonBook';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.Lesson = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dash.v1.Lesson.repeatedFields_, null);
};
goog.inherits(proto.dash.v1.Lesson, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.Lesson.displayName = 'proto.dash.v1.Lesson';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.Lesson.Simple = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.Lesson.Simple, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.Lesson.Simple.displayName = 'proto.dash.v1.Lesson.Simple';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.PastExamBooklet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.PastExamBooklet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.PastExamBooklet.displayName = 'proto.dash.v1.PastExamBooklet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.BookletFileInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dash.v1.BookletFileInfo.repeatedFields_, null);
};
goog.inherits(proto.dash.v1.BookletFileInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.BookletFileInfo.displayName = 'proto.dash.v1.BookletFileInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.PastExam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dash.v1.PastExam.repeatedFields_, null);
};
goog.inherits(proto.dash.v1.PastExam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.PastExam.displayName = 'proto.dash.v1.PastExam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.Post = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dash.v1.Post.repeatedFields_, null);
};
goog.inherits(proto.dash.v1.Post, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.Post.displayName = 'proto.dash.v1.Post';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.File = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.File, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.File.displayName = 'proto.dash.v1.File';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.PastExamQuestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.PastExamQuestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.PastExamQuestion.displayName = 'proto.dash.v1.PastExamQuestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.PastExamQuestion.Mark = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.PastExamQuestion.Mark, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.PastExamQuestion.Mark.displayName = 'proto.dash.v1.PastExamQuestion.Mark';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.CalendarEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.CalendarEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.CalendarEvent.displayName = 'proto.dash.v1.CalendarEvent';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.Slug.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.Slug.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.Slug} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.Slug.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.Slug}
 */
proto.dash.v1.Slug.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.Slug;
  return proto.dash.v1.Slug.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.Slug} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.Slug}
 */
proto.dash.v1.Slug.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.Slug.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.Slug.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.Slug} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.Slug.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp created_at = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.Slug.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.Slug} returns this
*/
proto.dash.v1.Slug.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.Slug} returns this
 */
proto.dash.v1.Slug.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.Slug.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.dash.v1.Slug.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.Slug} returns this
 */
proto.dash.v1.Slug.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dash.v1.Area.repeatedFields_ = [8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.Area.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.Area.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.Area} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.Area.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    content: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    examGroupList: jspb.Message.toObjectList(msg.getExamGroupList(),
    proto.dash.v1.ExamGroup.toObject, includeInstance),
    examTypesList: jspb.Message.toObjectList(msg.getExamTypesList(),
    proto.dash.v1.ExamType.toObject, includeInstance),
    slugsList: jspb.Message.toObjectList(msg.getSlugsList(),
    proto.dash.v1.Slug.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.Area}
 */
proto.dash.v1.Area.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.Area;
  return proto.dash.v1.Area.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.Area} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.Area}
 */
proto.dash.v1.Area.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 5:
      var value = /** @type {!proto.types.v1.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 8:
      var value = new proto.dash.v1.ExamGroup;
      reader.readMessage(value,proto.dash.v1.ExamGroup.deserializeBinaryFromReader);
      msg.addExamGroup(value);
      break;
    case 9:
      var value = new proto.dash.v1.ExamType;
      reader.readMessage(value,proto.dash.v1.ExamType.deserializeBinaryFromReader);
      msg.addExamTypes(value);
      break;
    case 10:
      var value = new proto.dash.v1.Slug;
      reader.readMessage(value,proto.dash.v1.Slug.deserializeBinaryFromReader);
      msg.addSlugs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.Area.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.Area.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.Area} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.Area.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExamGroupList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.dash.v1.ExamGroup.serializeBinaryToWriter
    );
  }
  f = message.getExamTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.dash.v1.ExamType.serializeBinaryToWriter
    );
  }
  f = message.getSlugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.dash.v1.Slug.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.Area.Simple.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.Area.Simple.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.Area.Simple} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.Area.Simple.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.Area.Simple}
 */
proto.dash.v1.Area.Simple.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.Area.Simple;
  return proto.dash.v1.Area.Simple.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.Area.Simple} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.Area.Simple}
 */
proto.dash.v1.Area.Simple.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.Area.Simple.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.Area.Simple.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.Area.Simple} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.Area.Simple.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.Area.Simple.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.Area.Simple} returns this
 */
proto.dash.v1.Area.Simple.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.dash.v1.Area.Simple.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.Area.Simple} returns this
 */
proto.dash.v1.Area.Simple.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.Area.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.Area} returns this
 */
proto.dash.v1.Area.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.dash.v1.Area.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.Area} returns this
 */
proto.dash.v1.Area.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.dash.v1.Area.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.Area} returns this
 */
proto.dash.v1.Area.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string content = 4;
 * @return {string}
 */
proto.dash.v1.Area.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.Area} returns this
 */
proto.dash.v1.Area.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional types.v1.Status status = 5;
 * @return {!proto.types.v1.Status}
 */
proto.dash.v1.Area.prototype.getStatus = function() {
  return /** @type {!proto.types.v1.Status} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.types.v1.Status} value
 * @return {!proto.dash.v1.Area} returns this
 */
proto.dash.v1.Area.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.Area.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.Area} returns this
*/
proto.dash.v1.Area.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.Area} returns this
 */
proto.dash.v1.Area.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.Area.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.Area.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.Area} returns this
*/
proto.dash.v1.Area.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.Area} returns this
 */
proto.dash.v1.Area.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.Area.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated ExamGroup exam_group = 8;
 * @return {!Array<!proto.dash.v1.ExamGroup>}
 */
proto.dash.v1.Area.prototype.getExamGroupList = function() {
  return /** @type{!Array<!proto.dash.v1.ExamGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dash.v1.ExamGroup, 8));
};


/**
 * @param {!Array<!proto.dash.v1.ExamGroup>} value
 * @return {!proto.dash.v1.Area} returns this
*/
proto.dash.v1.Area.prototype.setExamGroupList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.dash.v1.ExamGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dash.v1.ExamGroup}
 */
proto.dash.v1.Area.prototype.addExamGroup = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.dash.v1.ExamGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dash.v1.Area} returns this
 */
proto.dash.v1.Area.prototype.clearExamGroupList = function() {
  return this.setExamGroupList([]);
};


/**
 * repeated ExamType exam_types = 9;
 * @return {!Array<!proto.dash.v1.ExamType>}
 */
proto.dash.v1.Area.prototype.getExamTypesList = function() {
  return /** @type{!Array<!proto.dash.v1.ExamType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dash.v1.ExamType, 9));
};


/**
 * @param {!Array<!proto.dash.v1.ExamType>} value
 * @return {!proto.dash.v1.Area} returns this
*/
proto.dash.v1.Area.prototype.setExamTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.dash.v1.ExamType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dash.v1.ExamType}
 */
proto.dash.v1.Area.prototype.addExamTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.dash.v1.ExamType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dash.v1.Area} returns this
 */
proto.dash.v1.Area.prototype.clearExamTypesList = function() {
  return this.setExamTypesList([]);
};


/**
 * repeated Slug slugs = 10;
 * @return {!Array<!proto.dash.v1.Slug>}
 */
proto.dash.v1.Area.prototype.getSlugsList = function() {
  return /** @type{!Array<!proto.dash.v1.Slug>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dash.v1.Slug, 10));
};


/**
 * @param {!Array<!proto.dash.v1.Slug>} value
 * @return {!proto.dash.v1.Area} returns this
*/
proto.dash.v1.Area.prototype.setSlugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.dash.v1.Slug=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dash.v1.Slug}
 */
proto.dash.v1.Area.prototype.addSlugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.dash.v1.Slug, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dash.v1.Area} returns this
 */
proto.dash.v1.Area.prototype.clearSlugsList = function() {
  return this.setSlugsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.ExamType.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.ExamType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.ExamType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ExamType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    areaId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    area: (f = msg.getArea()) && proto.dash.v1.Area.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.ExamType}
 */
proto.dash.v1.ExamType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.ExamType;
  return proto.dash.v1.ExamType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.ExamType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.ExamType}
 */
proto.dash.v1.ExamType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAreaId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = new proto.dash.v1.Area;
      reader.readMessage(value,proto.dash.v1.Area.deserializeBinaryFromReader);
      msg.setArea(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.ExamType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.ExamType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.ExamType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ExamType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAreaId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getArea();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.dash.v1.Area.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.ExamType.Simple.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.ExamType.Simple.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.ExamType.Simple} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ExamType.Simple.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.ExamType.Simple}
 */
proto.dash.v1.ExamType.Simple.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.ExamType.Simple;
  return proto.dash.v1.ExamType.Simple.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.ExamType.Simple} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.ExamType.Simple}
 */
proto.dash.v1.ExamType.Simple.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.ExamType.Simple.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.ExamType.Simple.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.ExamType.Simple} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ExamType.Simple.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.ExamType.Simple.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.ExamType.Simple} returns this
 */
proto.dash.v1.ExamType.Simple.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.dash.v1.ExamType.Simple.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.ExamType.Simple} returns this
 */
proto.dash.v1.ExamType.Simple.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.ExamType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.ExamType} returns this
 */
proto.dash.v1.ExamType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 area_id = 2;
 * @return {number}
 */
proto.dash.v1.ExamType.prototype.getAreaId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.ExamType} returns this
 */
proto.dash.v1.ExamType.prototype.setAreaId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.dash.v1.ExamType.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.ExamType} returns this
 */
proto.dash.v1.ExamType.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Area area = 4;
 * @return {?proto.dash.v1.Area}
 */
proto.dash.v1.ExamType.prototype.getArea = function() {
  return /** @type{?proto.dash.v1.Area} */ (
    jspb.Message.getWrapperField(this, proto.dash.v1.Area, 4));
};


/**
 * @param {?proto.dash.v1.Area|undefined} value
 * @return {!proto.dash.v1.ExamType} returns this
*/
proto.dash.v1.ExamType.prototype.setArea = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.ExamType} returns this
 */
proto.dash.v1.ExamType.prototype.clearArea = function() {
  return this.setArea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.ExamType.prototype.hasArea = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.ExamGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.ExamGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.ExamGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ExamGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    areaId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    area: (f = msg.getArea()) && proto.dash.v1.Area.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.ExamGroup}
 */
proto.dash.v1.ExamGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.ExamGroup;
  return proto.dash.v1.ExamGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.ExamGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.ExamGroup}
 */
proto.dash.v1.ExamGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAreaId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = new proto.dash.v1.Area;
      reader.readMessage(value,proto.dash.v1.Area.deserializeBinaryFromReader);
      msg.setArea(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.ExamGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.ExamGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.ExamGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ExamGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAreaId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getArea();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.dash.v1.Area.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.ExamGroup.Simple.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.ExamGroup.Simple.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.ExamGroup.Simple} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ExamGroup.Simple.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.ExamGroup.Simple}
 */
proto.dash.v1.ExamGroup.Simple.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.ExamGroup.Simple;
  return proto.dash.v1.ExamGroup.Simple.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.ExamGroup.Simple} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.ExamGroup.Simple}
 */
proto.dash.v1.ExamGroup.Simple.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.ExamGroup.Simple.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.ExamGroup.Simple.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.ExamGroup.Simple} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ExamGroup.Simple.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.ExamGroup.Simple.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.ExamGroup.Simple} returns this
 */
proto.dash.v1.ExamGroup.Simple.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.dash.v1.ExamGroup.Simple.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.ExamGroup.Simple} returns this
 */
proto.dash.v1.ExamGroup.Simple.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.ExamGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.ExamGroup} returns this
 */
proto.dash.v1.ExamGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 area_id = 2;
 * @return {number}
 */
proto.dash.v1.ExamGroup.prototype.getAreaId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.ExamGroup} returns this
 */
proto.dash.v1.ExamGroup.prototype.setAreaId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.dash.v1.ExamGroup.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.ExamGroup} returns this
 */
proto.dash.v1.ExamGroup.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Area area = 4;
 * @return {?proto.dash.v1.Area}
 */
proto.dash.v1.ExamGroup.prototype.getArea = function() {
  return /** @type{?proto.dash.v1.Area} */ (
    jspb.Message.getWrapperField(this, proto.dash.v1.Area, 4));
};


/**
 * @param {?proto.dash.v1.Area|undefined} value
 * @return {!proto.dash.v1.ExamGroup} returns this
*/
proto.dash.v1.ExamGroup.prototype.setArea = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.ExamGroup} returns this
 */
proto.dash.v1.ExamGroup.prototype.clearArea = function() {
  return this.setArea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.ExamGroup.prototype.hasArea = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.FAQ.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.FAQ.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.FAQ} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.FAQ.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    entity: jspb.Message.getFieldWithDefault(msg, 5, 0),
    entityId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    question: jspb.Message.getFieldWithDefault(msg, 7, ""),
    answer: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.FAQ}
 */
proto.dash.v1.FAQ.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.FAQ;
  return proto.dash.v1.FAQ.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.FAQ} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.FAQ}
 */
proto.dash.v1.FAQ.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {!proto.types.v1.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {!proto.types.v1.Entity} */ (reader.readEnum());
      msg.setEntity(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEntityId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestion(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.FAQ.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.FAQ.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.FAQ} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.FAQ.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getEntity();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getEntityId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getQuestion();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.FAQ.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.FAQ} returns this
 */
proto.dash.v1.FAQ.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.FAQ.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.FAQ} returns this
*/
proto.dash.v1.FAQ.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.FAQ} returns this
 */
proto.dash.v1.FAQ.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.FAQ.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.FAQ.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.FAQ} returns this
*/
proto.dash.v1.FAQ.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.FAQ} returns this
 */
proto.dash.v1.FAQ.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.FAQ.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional types.v1.Status status = 4;
 * @return {!proto.types.v1.Status}
 */
proto.dash.v1.FAQ.prototype.getStatus = function() {
  return /** @type {!proto.types.v1.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.types.v1.Status} value
 * @return {!proto.dash.v1.FAQ} returns this
 */
proto.dash.v1.FAQ.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional types.v1.Entity entity = 5;
 * @return {!proto.types.v1.Entity}
 */
proto.dash.v1.FAQ.prototype.getEntity = function() {
  return /** @type {!proto.types.v1.Entity} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.types.v1.Entity} value
 * @return {!proto.dash.v1.FAQ} returns this
 */
proto.dash.v1.FAQ.prototype.setEntity = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int32 entity_id = 6;
 * @return {number}
 */
proto.dash.v1.FAQ.prototype.getEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.FAQ} returns this
 */
proto.dash.v1.FAQ.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string question = 7;
 * @return {string}
 */
proto.dash.v1.FAQ.prototype.getQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.FAQ} returns this
 */
proto.dash.v1.FAQ.prototype.setQuestion = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string answer = 8;
 * @return {string}
 */
proto.dash.v1.FAQ.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.FAQ} returns this
 */
proto.dash.v1.FAQ.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.LessonBook.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.LessonBook.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.LessonBook} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.LessonBook.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    lessonContentType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    area: (f = msg.getArea()) && proto.dash.v1.Area.Simple.toObject(includeInstance, f),
    lesson: (f = msg.getLesson()) && proto.dash.v1.Lesson.Simple.toObject(includeInstance, f),
    targetUrl: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.LessonBook}
 */
proto.dash.v1.LessonBook.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.LessonBook;
  return proto.dash.v1.LessonBook.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.LessonBook} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.LessonBook}
 */
proto.dash.v1.LessonBook.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {!proto.types.v1.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {!proto.types.v1.LessonContentType} */ (reader.readEnum());
      msg.setLessonContentType(value);
      break;
    case 6:
      var value = new proto.dash.v1.Area.Simple;
      reader.readMessage(value,proto.dash.v1.Area.Simple.deserializeBinaryFromReader);
      msg.setArea(value);
      break;
    case 7:
      var value = new proto.dash.v1.Lesson.Simple;
      reader.readMessage(value,proto.dash.v1.Lesson.Simple.deserializeBinaryFromReader);
      msg.setLesson(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.LessonBook.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.LessonBook.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.LessonBook} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.LessonBook.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getLessonContentType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getArea();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.dash.v1.Area.Simple.serializeBinaryToWriter
    );
  }
  f = message.getLesson();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.dash.v1.Lesson.Simple.serializeBinaryToWriter
    );
  }
  f = message.getTargetUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.LessonBook.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.LessonBook} returns this
 */
proto.dash.v1.LessonBook.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.LessonBook.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.LessonBook} returns this
*/
proto.dash.v1.LessonBook.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.LessonBook} returns this
 */
proto.dash.v1.LessonBook.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.LessonBook.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.LessonBook.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.LessonBook} returns this
*/
proto.dash.v1.LessonBook.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.LessonBook} returns this
 */
proto.dash.v1.LessonBook.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.LessonBook.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional types.v1.Status status = 4;
 * @return {!proto.types.v1.Status}
 */
proto.dash.v1.LessonBook.prototype.getStatus = function() {
  return /** @type {!proto.types.v1.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.types.v1.Status} value
 * @return {!proto.dash.v1.LessonBook} returns this
 */
proto.dash.v1.LessonBook.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional types.v1.LessonContentType lesson_content_type = 5;
 * @return {!proto.types.v1.LessonContentType}
 */
proto.dash.v1.LessonBook.prototype.getLessonContentType = function() {
  return /** @type {!proto.types.v1.LessonContentType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.types.v1.LessonContentType} value
 * @return {!proto.dash.v1.LessonBook} returns this
 */
proto.dash.v1.LessonBook.prototype.setLessonContentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional Area.Simple area = 6;
 * @return {?proto.dash.v1.Area.Simple}
 */
proto.dash.v1.LessonBook.prototype.getArea = function() {
  return /** @type{?proto.dash.v1.Area.Simple} */ (
    jspb.Message.getWrapperField(this, proto.dash.v1.Area.Simple, 6));
};


/**
 * @param {?proto.dash.v1.Area.Simple|undefined} value
 * @return {!proto.dash.v1.LessonBook} returns this
*/
proto.dash.v1.LessonBook.prototype.setArea = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.LessonBook} returns this
 */
proto.dash.v1.LessonBook.prototype.clearArea = function() {
  return this.setArea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.LessonBook.prototype.hasArea = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Lesson.Simple lesson = 7;
 * @return {?proto.dash.v1.Lesson.Simple}
 */
proto.dash.v1.LessonBook.prototype.getLesson = function() {
  return /** @type{?proto.dash.v1.Lesson.Simple} */ (
    jspb.Message.getWrapperField(this, proto.dash.v1.Lesson.Simple, 7));
};


/**
 * @param {?proto.dash.v1.Lesson.Simple|undefined} value
 * @return {!proto.dash.v1.LessonBook} returns this
*/
proto.dash.v1.LessonBook.prototype.setLesson = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.LessonBook} returns this
 */
proto.dash.v1.LessonBook.prototype.clearLesson = function() {
  return this.setLesson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.LessonBook.prototype.hasLesson = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string target_url = 8;
 * @return {string}
 */
proto.dash.v1.LessonBook.prototype.getTargetUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.LessonBook} returns this
 */
proto.dash.v1.LessonBook.prototype.setTargetUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dash.v1.Lesson.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.Lesson.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.Lesson.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.Lesson} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.Lesson.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    area: (f = msg.getArea()) && proto.dash.v1.Area.Simple.toObject(includeInstance, f),
    slugsList: jspb.Message.toObjectList(msg.getSlugsList(),
    proto.dash.v1.Slug.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.Lesson}
 */
proto.dash.v1.Lesson.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.Lesson;
  return proto.dash.v1.Lesson.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.Lesson} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.Lesson}
 */
proto.dash.v1.Lesson.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = /** @type {!proto.types.v1.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = new proto.dash.v1.Area.Simple;
      reader.readMessage(value,proto.dash.v1.Area.Simple.deserializeBinaryFromReader);
      msg.setArea(value);
      break;
    case 6:
      var value = new proto.dash.v1.Slug;
      reader.readMessage(value,proto.dash.v1.Slug.deserializeBinaryFromReader);
      msg.addSlugs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.Lesson.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.Lesson.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.Lesson} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.Lesson.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getArea();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.dash.v1.Area.Simple.serializeBinaryToWriter
    );
  }
  f = message.getSlugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.dash.v1.Slug.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.Lesson.Simple.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.Lesson.Simple.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.Lesson.Simple} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.Lesson.Simple.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.Lesson.Simple}
 */
proto.dash.v1.Lesson.Simple.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.Lesson.Simple;
  return proto.dash.v1.Lesson.Simple.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.Lesson.Simple} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.Lesson.Simple}
 */
proto.dash.v1.Lesson.Simple.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.Lesson.Simple.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.Lesson.Simple.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.Lesson.Simple} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.Lesson.Simple.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.Lesson.Simple.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.Lesson.Simple} returns this
 */
proto.dash.v1.Lesson.Simple.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.dash.v1.Lesson.Simple.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.Lesson.Simple} returns this
 */
proto.dash.v1.Lesson.Simple.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.dash.v1.Lesson.Simple.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.Lesson.Simple} returns this
 */
proto.dash.v1.Lesson.Simple.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.Lesson.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.Lesson} returns this
 */
proto.dash.v1.Lesson.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.dash.v1.Lesson.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.Lesson} returns this
 */
proto.dash.v1.Lesson.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.dash.v1.Lesson.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.Lesson} returns this
 */
proto.dash.v1.Lesson.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional types.v1.Status status = 4;
 * @return {!proto.types.v1.Status}
 */
proto.dash.v1.Lesson.prototype.getStatus = function() {
  return /** @type {!proto.types.v1.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.types.v1.Status} value
 * @return {!proto.dash.v1.Lesson} returns this
 */
proto.dash.v1.Lesson.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional Area.Simple area = 5;
 * @return {?proto.dash.v1.Area.Simple}
 */
proto.dash.v1.Lesson.prototype.getArea = function() {
  return /** @type{?proto.dash.v1.Area.Simple} */ (
    jspb.Message.getWrapperField(this, proto.dash.v1.Area.Simple, 5));
};


/**
 * @param {?proto.dash.v1.Area.Simple|undefined} value
 * @return {!proto.dash.v1.Lesson} returns this
*/
proto.dash.v1.Lesson.prototype.setArea = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.Lesson} returns this
 */
proto.dash.v1.Lesson.prototype.clearArea = function() {
  return this.setArea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.Lesson.prototype.hasArea = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated Slug slugs = 6;
 * @return {!Array<!proto.dash.v1.Slug>}
 */
proto.dash.v1.Lesson.prototype.getSlugsList = function() {
  return /** @type{!Array<!proto.dash.v1.Slug>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dash.v1.Slug, 6));
};


/**
 * @param {!Array<!proto.dash.v1.Slug>} value
 * @return {!proto.dash.v1.Lesson} returns this
*/
proto.dash.v1.Lesson.prototype.setSlugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.dash.v1.Slug=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dash.v1.Slug}
 */
proto.dash.v1.Lesson.prototype.addSlugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.dash.v1.Slug, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dash.v1.Lesson} returns this
 */
proto.dash.v1.Lesson.prototype.clearSlugsList = function() {
  return this.setSlugsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.PastExamBooklet.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.PastExamBooklet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.PastExamBooklet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PastExamBooklet.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    areaId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    examGroupId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    examTypeId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    lessonId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    academicYear: jspb.Message.getFieldWithDefault(msg, 6, 0),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.PastExamBooklet}
 */
proto.dash.v1.PastExamBooklet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.PastExamBooklet;
  return proto.dash.v1.PastExamBooklet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.PastExamBooklet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.PastExamBooklet}
 */
proto.dash.v1.PastExamBooklet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAreaId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExamGroupId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExamTypeId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLessonId(value);
      break;
    case 6:
      var value = /** @type {!proto.types.v1.AcademicYear} */ (reader.readEnum());
      msg.setAcademicYear(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.PastExamBooklet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.PastExamBooklet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.PastExamBooklet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PastExamBooklet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAreaId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getExamGroupId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getExamTypeId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLessonId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getAcademicYear();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.PastExamBooklet.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PastExamBooklet} returns this
 */
proto.dash.v1.PastExamBooklet.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 area_id = 2;
 * @return {number}
 */
proto.dash.v1.PastExamBooklet.prototype.getAreaId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PastExamBooklet} returns this
 */
proto.dash.v1.PastExamBooklet.prototype.setAreaId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 exam_group_id = 3;
 * @return {number}
 */
proto.dash.v1.PastExamBooklet.prototype.getExamGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PastExamBooklet} returns this
 */
proto.dash.v1.PastExamBooklet.prototype.setExamGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 exam_type_id = 4;
 * @return {number}
 */
proto.dash.v1.PastExamBooklet.prototype.getExamTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PastExamBooklet} returns this
 */
proto.dash.v1.PastExamBooklet.prototype.setExamTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 lesson_id = 5;
 * @return {number}
 */
proto.dash.v1.PastExamBooklet.prototype.getLessonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PastExamBooklet} returns this
 */
proto.dash.v1.PastExamBooklet.prototype.setLessonId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional types.v1.AcademicYear academic_year = 6;
 * @return {!proto.types.v1.AcademicYear}
 */
proto.dash.v1.PastExamBooklet.prototype.getAcademicYear = function() {
  return /** @type {!proto.types.v1.AcademicYear} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.types.v1.AcademicYear} value
 * @return {!proto.dash.v1.PastExamBooklet} returns this
 */
proto.dash.v1.PastExamBooklet.prototype.setAcademicYear = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.PastExamBooklet.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.PastExamBooklet} returns this
*/
proto.dash.v1.PastExamBooklet.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PastExamBooklet} returns this
 */
proto.dash.v1.PastExamBooklet.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PastExamBooklet.prototype.hasDate = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dash.v1.BookletFileInfo.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.BookletFileInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.BookletFileInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.BookletFileInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.BookletFileInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    width: jspb.Message.getFieldWithDefault(msg, 1, 0),
    height: jspb.Message.getFieldWithDefault(msg, 2, 0),
    resolution: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pagesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.BookletFileInfo}
 */
proto.dash.v1.BookletFileInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.BookletFileInfo;
  return proto.dash.v1.BookletFileInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.BookletFileInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.BookletFileInfo}
 */
proto.dash.v1.BookletFileInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResolution(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.BookletFileInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.BookletFileInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.BookletFileInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.BookletFileInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getResolution();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional int32 width = 1;
 * @return {number}
 */
proto.dash.v1.BookletFileInfo.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.BookletFileInfo} returns this
 */
proto.dash.v1.BookletFileInfo.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 height = 2;
 * @return {number}
 */
proto.dash.v1.BookletFileInfo.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.BookletFileInfo} returns this
 */
proto.dash.v1.BookletFileInfo.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 resolution = 3;
 * @return {number}
 */
proto.dash.v1.BookletFileInfo.prototype.getResolution = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.BookletFileInfo} returns this
 */
proto.dash.v1.BookletFileInfo.prototype.setResolution = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated string pages = 4;
 * @return {!Array<string>}
 */
proto.dash.v1.BookletFileInfo.prototype.getPagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dash.v1.BookletFileInfo} returns this
 */
proto.dash.v1.BookletFileInfo.prototype.setPagesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dash.v1.BookletFileInfo} returns this
 */
proto.dash.v1.BookletFileInfo.prototype.addPages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dash.v1.BookletFileInfo} returns this
 */
proto.dash.v1.BookletFileInfo.prototype.clearPagesList = function() {
  return this.setPagesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dash.v1.PastExam.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.PastExam.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.PastExam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.PastExam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PastExam.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bookletId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    academicYear: jspb.Message.getFieldWithDefault(msg, 7, 0),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    proto.dash.v1.PastExamQuestion.toObject, includeInstance),
    area: (f = msg.getArea()) && proto.dash.v1.Area.Simple.toObject(includeInstance, f),
    examGroup: (f = msg.getExamGroup()) && proto.dash.v1.ExamGroup.Simple.toObject(includeInstance, f),
    examType: (f = msg.getExamType()) && proto.dash.v1.ExamType.Simple.toObject(includeInstance, f),
    lesson: (f = msg.getLesson()) && proto.dash.v1.Lesson.Simple.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.PastExam}
 */
proto.dash.v1.PastExam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.PastExam;
  return proto.dash.v1.PastExam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.PastExam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.PastExam}
 */
proto.dash.v1.PastExam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBookletId(value);
      break;
    case 7:
      var value = /** @type {!proto.types.v1.AcademicYear} */ (reader.readEnum());
      msg.setAcademicYear(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 9:
      var value = /** @type {!proto.types.v1.ExamStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 12:
      var value = new proto.dash.v1.PastExamQuestion;
      reader.readMessage(value,proto.dash.v1.PastExamQuestion.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    case 13:
      var value = new proto.dash.v1.Area.Simple;
      reader.readMessage(value,proto.dash.v1.Area.Simple.deserializeBinaryFromReader);
      msg.setArea(value);
      break;
    case 14:
      var value = new proto.dash.v1.ExamGroup.Simple;
      reader.readMessage(value,proto.dash.v1.ExamGroup.Simple.deserializeBinaryFromReader);
      msg.setExamGroup(value);
      break;
    case 15:
      var value = new proto.dash.v1.ExamType.Simple;
      reader.readMessage(value,proto.dash.v1.ExamType.Simple.deserializeBinaryFromReader);
      msg.setExamType(value);
      break;
    case 16:
      var value = new proto.dash.v1.Lesson.Simple;
      reader.readMessage(value,proto.dash.v1.Lesson.Simple.deserializeBinaryFromReader);
      msg.setLesson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.PastExam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.PastExam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.PastExam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PastExam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBookletId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAcademicYear();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.dash.v1.PastExamQuestion.serializeBinaryToWriter
    );
  }
  f = message.getArea();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.dash.v1.Area.Simple.serializeBinaryToWriter
    );
  }
  f = message.getExamGroup();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.dash.v1.ExamGroup.Simple.serializeBinaryToWriter
    );
  }
  f = message.getExamType();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.dash.v1.ExamType.Simple.serializeBinaryToWriter
    );
  }
  f = message.getLesson();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.dash.v1.Lesson.Simple.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.PastExam.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PastExam} returns this
 */
proto.dash.v1.PastExam.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 booklet_id = 3;
 * @return {number}
 */
proto.dash.v1.PastExam.prototype.getBookletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PastExam} returns this
 */
proto.dash.v1.PastExam.prototype.setBookletId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional types.v1.AcademicYear academic_year = 7;
 * @return {!proto.types.v1.AcademicYear}
 */
proto.dash.v1.PastExam.prototype.getAcademicYear = function() {
  return /** @type {!proto.types.v1.AcademicYear} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.types.v1.AcademicYear} value
 * @return {!proto.dash.v1.PastExam} returns this
 */
proto.dash.v1.PastExam.prototype.setAcademicYear = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.PastExam.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.PastExam} returns this
*/
proto.dash.v1.PastExam.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PastExam} returns this
 */
proto.dash.v1.PastExam.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PastExam.prototype.hasDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional types.v1.ExamStatus status = 9;
 * @return {!proto.types.v1.ExamStatus}
 */
proto.dash.v1.PastExam.prototype.getStatus = function() {
  return /** @type {!proto.types.v1.ExamStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.types.v1.ExamStatus} value
 * @return {!proto.dash.v1.PastExam} returns this
 */
proto.dash.v1.PastExam.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.PastExam.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.PastExam} returns this
*/
proto.dash.v1.PastExam.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PastExam} returns this
 */
proto.dash.v1.PastExam.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PastExam.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.PastExam.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.PastExam} returns this
*/
proto.dash.v1.PastExam.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PastExam} returns this
 */
proto.dash.v1.PastExam.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PastExam.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated PastExamQuestion questions = 12;
 * @return {!Array<!proto.dash.v1.PastExamQuestion>}
 */
proto.dash.v1.PastExam.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.dash.v1.PastExamQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dash.v1.PastExamQuestion, 12));
};


/**
 * @param {!Array<!proto.dash.v1.PastExamQuestion>} value
 * @return {!proto.dash.v1.PastExam} returns this
*/
proto.dash.v1.PastExam.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.dash.v1.PastExamQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dash.v1.PastExamQuestion}
 */
proto.dash.v1.PastExam.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.dash.v1.PastExamQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dash.v1.PastExam} returns this
 */
proto.dash.v1.PastExam.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};


/**
 * optional Area.Simple area = 13;
 * @return {?proto.dash.v1.Area.Simple}
 */
proto.dash.v1.PastExam.prototype.getArea = function() {
  return /** @type{?proto.dash.v1.Area.Simple} */ (
    jspb.Message.getWrapperField(this, proto.dash.v1.Area.Simple, 13));
};


/**
 * @param {?proto.dash.v1.Area.Simple|undefined} value
 * @return {!proto.dash.v1.PastExam} returns this
*/
proto.dash.v1.PastExam.prototype.setArea = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PastExam} returns this
 */
proto.dash.v1.PastExam.prototype.clearArea = function() {
  return this.setArea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PastExam.prototype.hasArea = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional ExamGroup.Simple exam_group = 14;
 * @return {?proto.dash.v1.ExamGroup.Simple}
 */
proto.dash.v1.PastExam.prototype.getExamGroup = function() {
  return /** @type{?proto.dash.v1.ExamGroup.Simple} */ (
    jspb.Message.getWrapperField(this, proto.dash.v1.ExamGroup.Simple, 14));
};


/**
 * @param {?proto.dash.v1.ExamGroup.Simple|undefined} value
 * @return {!proto.dash.v1.PastExam} returns this
*/
proto.dash.v1.PastExam.prototype.setExamGroup = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PastExam} returns this
 */
proto.dash.v1.PastExam.prototype.clearExamGroup = function() {
  return this.setExamGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PastExam.prototype.hasExamGroup = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional ExamType.Simple exam_type = 15;
 * @return {?proto.dash.v1.ExamType.Simple}
 */
proto.dash.v1.PastExam.prototype.getExamType = function() {
  return /** @type{?proto.dash.v1.ExamType.Simple} */ (
    jspb.Message.getWrapperField(this, proto.dash.v1.ExamType.Simple, 15));
};


/**
 * @param {?proto.dash.v1.ExamType.Simple|undefined} value
 * @return {!proto.dash.v1.PastExam} returns this
*/
proto.dash.v1.PastExam.prototype.setExamType = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PastExam} returns this
 */
proto.dash.v1.PastExam.prototype.clearExamType = function() {
  return this.setExamType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PastExam.prototype.hasExamType = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional Lesson.Simple lesson = 16;
 * @return {?proto.dash.v1.Lesson.Simple}
 */
proto.dash.v1.PastExam.prototype.getLesson = function() {
  return /** @type{?proto.dash.v1.Lesson.Simple} */ (
    jspb.Message.getWrapperField(this, proto.dash.v1.Lesson.Simple, 16));
};


/**
 * @param {?proto.dash.v1.Lesson.Simple|undefined} value
 * @return {!proto.dash.v1.PastExam} returns this
*/
proto.dash.v1.PastExam.prototype.setLesson = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PastExam} returns this
 */
proto.dash.v1.PastExam.prototype.clearLesson = function() {
  return this.setLesson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PastExam.prototype.hasLesson = function() {
  return jspb.Message.getField(this, 16) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dash.v1.Post.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.Post.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.Post.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.Post} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.Post.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    publishedAt: (f = msg.getPublishedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 5, ""),
    image: jspb.Message.getFieldWithDefault(msg, 6, ""),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    content: jspb.Message.getFieldWithDefault(msg, 8, ""),
    postType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    status: jspb.Message.getFieldWithDefault(msg, 10, 0),
    area: (f = msg.getArea()) && proto.dash.v1.Area.Simple.toObject(includeInstance, f),
    slugsList: jspb.Message.toObjectList(msg.getSlugsList(),
    proto.dash.v1.Slug.toObject, includeInstance),
    seoTitle: jspb.Message.getFieldWithDefault(msg, 13, ""),
    seoDescription: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.Post}
 */
proto.dash.v1.Post.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.Post;
  return proto.dash.v1.Post.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.Post} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.Post}
 */
proto.dash.v1.Post.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPublishedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 9:
      var value = /** @type {!proto.types.v1.PostType} */ (reader.readEnum());
      msg.setPostType(value);
      break;
    case 10:
      var value = /** @type {!proto.types.v1.PostStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 11:
      var value = new proto.dash.v1.Area.Simple;
      reader.readMessage(value,proto.dash.v1.Area.Simple.deserializeBinaryFromReader);
      msg.setArea(value);
      break;
    case 12:
      var value = new proto.dash.v1.Slug;
      reader.readMessage(value,proto.dash.v1.Slug.deserializeBinaryFromReader);
      msg.addSlugs(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeoTitle(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeoDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.Post.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.Post.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.Post} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.Post.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPublishedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPostType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getArea();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.dash.v1.Area.Simple.serializeBinaryToWriter
    );
  }
  f = message.getSlugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.dash.v1.Slug.serializeBinaryToWriter
    );
  }
  f = message.getSeoTitle();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSeoDescription();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.Post.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.Post} returns this
 */
proto.dash.v1.Post.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.Post.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.Post} returns this
*/
proto.dash.v1.Post.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.Post} returns this
 */
proto.dash.v1.Post.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.Post.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.Post.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.Post} returns this
*/
proto.dash.v1.Post.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.Post} returns this
 */
proto.dash.v1.Post.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.Post.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp published_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.Post.prototype.getPublishedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.Post} returns this
*/
proto.dash.v1.Post.prototype.setPublishedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.Post} returns this
 */
proto.dash.v1.Post.prototype.clearPublishedAt = function() {
  return this.setPublishedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.Post.prototype.hasPublishedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string title = 5;
 * @return {string}
 */
proto.dash.v1.Post.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.Post} returns this
 */
proto.dash.v1.Post.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string image = 6;
 * @return {string}
 */
proto.dash.v1.Post.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.Post} returns this
 */
proto.dash.v1.Post.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.dash.v1.Post.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.Post} returns this
 */
proto.dash.v1.Post.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string content = 8;
 * @return {string}
 */
proto.dash.v1.Post.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.Post} returns this
 */
proto.dash.v1.Post.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional types.v1.PostType post_type = 9;
 * @return {!proto.types.v1.PostType}
 */
proto.dash.v1.Post.prototype.getPostType = function() {
  return /** @type {!proto.types.v1.PostType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.types.v1.PostType} value
 * @return {!proto.dash.v1.Post} returns this
 */
proto.dash.v1.Post.prototype.setPostType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional types.v1.PostStatus status = 10;
 * @return {!proto.types.v1.PostStatus}
 */
proto.dash.v1.Post.prototype.getStatus = function() {
  return /** @type {!proto.types.v1.PostStatus} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.types.v1.PostStatus} value
 * @return {!proto.dash.v1.Post} returns this
 */
proto.dash.v1.Post.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional Area.Simple area = 11;
 * @return {?proto.dash.v1.Area.Simple}
 */
proto.dash.v1.Post.prototype.getArea = function() {
  return /** @type{?proto.dash.v1.Area.Simple} */ (
    jspb.Message.getWrapperField(this, proto.dash.v1.Area.Simple, 11));
};


/**
 * @param {?proto.dash.v1.Area.Simple|undefined} value
 * @return {!proto.dash.v1.Post} returns this
*/
proto.dash.v1.Post.prototype.setArea = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.Post} returns this
 */
proto.dash.v1.Post.prototype.clearArea = function() {
  return this.setArea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.Post.prototype.hasArea = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated Slug slugs = 12;
 * @return {!Array<!proto.dash.v1.Slug>}
 */
proto.dash.v1.Post.prototype.getSlugsList = function() {
  return /** @type{!Array<!proto.dash.v1.Slug>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dash.v1.Slug, 12));
};


/**
 * @param {!Array<!proto.dash.v1.Slug>} value
 * @return {!proto.dash.v1.Post} returns this
*/
proto.dash.v1.Post.prototype.setSlugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.dash.v1.Slug=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dash.v1.Slug}
 */
proto.dash.v1.Post.prototype.addSlugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.dash.v1.Slug, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dash.v1.Post} returns this
 */
proto.dash.v1.Post.prototype.clearSlugsList = function() {
  return this.setSlugsList([]);
};


/**
 * optional string seo_title = 13;
 * @return {string}
 */
proto.dash.v1.Post.prototype.getSeoTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.Post} returns this
 */
proto.dash.v1.Post.prototype.setSeoTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string seo_description = 14;
 * @return {string}
 */
proto.dash.v1.Post.prototype.getSeoDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.Post} returns this
 */
proto.dash.v1.Post.prototype.setSeoDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.File.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.File.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.File} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.File.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    content: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.File}
 */
proto.dash.v1.File.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.File;
  return proto.dash.v1.File.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.File} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.File}
 */
proto.dash.v1.File.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.File.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.File.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.File} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.File.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dash.v1.File.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.File} returns this
 */
proto.dash.v1.File.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.dash.v1.File.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.File} returns this
 */
proto.dash.v1.File.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string content = 3;
 * @return {string}
 */
proto.dash.v1.File.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.File} returns this
 */
proto.dash.v1.File.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.PastExamQuestion.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.PastExamQuestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.PastExamQuestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PastExamQuestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    examId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    contentMark: (f = msg.getContentMark()) && proto.dash.v1.PastExamQuestion.Mark.toObject(includeInstance, f),
    contentText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    hintMark: (f = msg.getHintMark()) && proto.dash.v1.PastExamQuestion.Mark.toObject(includeInstance, f),
    hintText: jspb.Message.getFieldWithDefault(msg, 6, ""),
    infoMark: (f = msg.getInfoMark()) && proto.dash.v1.PastExamQuestion.Mark.toObject(includeInstance, f),
    infoText: jspb.Message.getFieldWithDefault(msg, 8, ""),
    correct: jspb.Message.getFieldWithDefault(msg, 9, 0),
    questionNumber: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.PastExamQuestion}
 */
proto.dash.v1.PastExamQuestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.PastExamQuestion;
  return proto.dash.v1.PastExamQuestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.PastExamQuestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.PastExamQuestion}
 */
proto.dash.v1.PastExamQuestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExamId(value);
      break;
    case 3:
      var value = new proto.dash.v1.PastExamQuestion.Mark;
      reader.readMessage(value,proto.dash.v1.PastExamQuestion.Mark.deserializeBinaryFromReader);
      msg.setContentMark(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentText(value);
      break;
    case 5:
      var value = new proto.dash.v1.PastExamQuestion.Mark;
      reader.readMessage(value,proto.dash.v1.PastExamQuestion.Mark.deserializeBinaryFromReader);
      msg.setHintMark(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHintText(value);
      break;
    case 7:
      var value = new proto.dash.v1.PastExamQuestion.Mark;
      reader.readMessage(value,proto.dash.v1.PastExamQuestion.Mark.deserializeBinaryFromReader);
      msg.setInfoMark(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setInfoText(value);
      break;
    case 9:
      var value = /** @type {!proto.types.v1.QuestionOption} */ (reader.readEnum());
      msg.setCorrect(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuestionNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.PastExamQuestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.PastExamQuestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.PastExamQuestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PastExamQuestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getExamId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getContentMark();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dash.v1.PastExamQuestion.Mark.serializeBinaryToWriter
    );
  }
  f = message.getContentText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHintMark();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.dash.v1.PastExamQuestion.Mark.serializeBinaryToWriter
    );
  }
  f = message.getHintText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getInfoMark();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.dash.v1.PastExamQuestion.Mark.serializeBinaryToWriter
    );
  }
  f = message.getInfoText();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCorrect();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getQuestionNumber();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.PastExamQuestion.Mark.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.PastExamQuestion.Mark.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.PastExamQuestion.Mark} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PastExamQuestion.Mark.toObject = function(includeInstance, msg) {
  var f, obj = {
    width: jspb.Message.getFieldWithDefault(msg, 1, 0),
    height: jspb.Message.getFieldWithDefault(msg, 2, 0),
    x: jspb.Message.getFieldWithDefault(msg, 3, 0),
    y: jspb.Message.getFieldWithDefault(msg, 4, 0),
    page: jspb.Message.getFieldWithDefault(msg, 5, 0),
    resolution: jspb.Message.getFieldWithDefault(msg, 6, 0),
    handcrafted: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.PastExamQuestion.Mark}
 */
proto.dash.v1.PastExamQuestion.Mark.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.PastExamQuestion.Mark;
  return proto.dash.v1.PastExamQuestion.Mark.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.PastExamQuestion.Mark} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.PastExamQuestion.Mark}
 */
proto.dash.v1.PastExamQuestion.Mark.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setX(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setY(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResolution(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHandcrafted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.PastExamQuestion.Mark.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.PastExamQuestion.Mark.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.PastExamQuestion.Mark} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PastExamQuestion.Mark.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getX();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getY();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getResolution();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getHandcrafted();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int32 width = 1;
 * @return {number}
 */
proto.dash.v1.PastExamQuestion.Mark.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PastExamQuestion.Mark} returns this
 */
proto.dash.v1.PastExamQuestion.Mark.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 height = 2;
 * @return {number}
 */
proto.dash.v1.PastExamQuestion.Mark.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PastExamQuestion.Mark} returns this
 */
proto.dash.v1.PastExamQuestion.Mark.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 x = 3;
 * @return {number}
 */
proto.dash.v1.PastExamQuestion.Mark.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PastExamQuestion.Mark} returns this
 */
proto.dash.v1.PastExamQuestion.Mark.prototype.setX = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 y = 4;
 * @return {number}
 */
proto.dash.v1.PastExamQuestion.Mark.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PastExamQuestion.Mark} returns this
 */
proto.dash.v1.PastExamQuestion.Mark.prototype.setY = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 page = 5;
 * @return {number}
 */
proto.dash.v1.PastExamQuestion.Mark.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PastExamQuestion.Mark} returns this
 */
proto.dash.v1.PastExamQuestion.Mark.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 resolution = 6;
 * @return {number}
 */
proto.dash.v1.PastExamQuestion.Mark.prototype.getResolution = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PastExamQuestion.Mark} returns this
 */
proto.dash.v1.PastExamQuestion.Mark.prototype.setResolution = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool handcrafted = 7;
 * @return {boolean}
 */
proto.dash.v1.PastExamQuestion.Mark.prototype.getHandcrafted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dash.v1.PastExamQuestion.Mark} returns this
 */
proto.dash.v1.PastExamQuestion.Mark.prototype.setHandcrafted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.PastExamQuestion.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PastExamQuestion} returns this
 */
proto.dash.v1.PastExamQuestion.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 exam_id = 2;
 * @return {number}
 */
proto.dash.v1.PastExamQuestion.prototype.getExamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PastExamQuestion} returns this
 */
proto.dash.v1.PastExamQuestion.prototype.setExamId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Mark content_mark = 3;
 * @return {?proto.dash.v1.PastExamQuestion.Mark}
 */
proto.dash.v1.PastExamQuestion.prototype.getContentMark = function() {
  return /** @type{?proto.dash.v1.PastExamQuestion.Mark} */ (
    jspb.Message.getWrapperField(this, proto.dash.v1.PastExamQuestion.Mark, 3));
};


/**
 * @param {?proto.dash.v1.PastExamQuestion.Mark|undefined} value
 * @return {!proto.dash.v1.PastExamQuestion} returns this
*/
proto.dash.v1.PastExamQuestion.prototype.setContentMark = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PastExamQuestion} returns this
 */
proto.dash.v1.PastExamQuestion.prototype.clearContentMark = function() {
  return this.setContentMark(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PastExamQuestion.prototype.hasContentMark = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string content_text = 4;
 * @return {string}
 */
proto.dash.v1.PastExamQuestion.prototype.getContentText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.PastExamQuestion} returns this
 */
proto.dash.v1.PastExamQuestion.prototype.setContentText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Mark hint_mark = 5;
 * @return {?proto.dash.v1.PastExamQuestion.Mark}
 */
proto.dash.v1.PastExamQuestion.prototype.getHintMark = function() {
  return /** @type{?proto.dash.v1.PastExamQuestion.Mark} */ (
    jspb.Message.getWrapperField(this, proto.dash.v1.PastExamQuestion.Mark, 5));
};


/**
 * @param {?proto.dash.v1.PastExamQuestion.Mark|undefined} value
 * @return {!proto.dash.v1.PastExamQuestion} returns this
*/
proto.dash.v1.PastExamQuestion.prototype.setHintMark = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PastExamQuestion} returns this
 */
proto.dash.v1.PastExamQuestion.prototype.clearHintMark = function() {
  return this.setHintMark(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PastExamQuestion.prototype.hasHintMark = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string hint_text = 6;
 * @return {string}
 */
proto.dash.v1.PastExamQuestion.prototype.getHintText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.PastExamQuestion} returns this
 */
proto.dash.v1.PastExamQuestion.prototype.setHintText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Mark info_mark = 7;
 * @return {?proto.dash.v1.PastExamQuestion.Mark}
 */
proto.dash.v1.PastExamQuestion.prototype.getInfoMark = function() {
  return /** @type{?proto.dash.v1.PastExamQuestion.Mark} */ (
    jspb.Message.getWrapperField(this, proto.dash.v1.PastExamQuestion.Mark, 7));
};


/**
 * @param {?proto.dash.v1.PastExamQuestion.Mark|undefined} value
 * @return {!proto.dash.v1.PastExamQuestion} returns this
*/
proto.dash.v1.PastExamQuestion.prototype.setInfoMark = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PastExamQuestion} returns this
 */
proto.dash.v1.PastExamQuestion.prototype.clearInfoMark = function() {
  return this.setInfoMark(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PastExamQuestion.prototype.hasInfoMark = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string info_text = 8;
 * @return {string}
 */
proto.dash.v1.PastExamQuestion.prototype.getInfoText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.PastExamQuestion} returns this
 */
proto.dash.v1.PastExamQuestion.prototype.setInfoText = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional types.v1.QuestionOption correct = 9;
 * @return {!proto.types.v1.QuestionOption}
 */
proto.dash.v1.PastExamQuestion.prototype.getCorrect = function() {
  return /** @type {!proto.types.v1.QuestionOption} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.types.v1.QuestionOption} value
 * @return {!proto.dash.v1.PastExamQuestion} returns this
 */
proto.dash.v1.PastExamQuestion.prototype.setCorrect = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional int32 question_number = 10;
 * @return {number}
 */
proto.dash.v1.PastExamQuestion.prototype.getQuestionNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PastExamQuestion} returns this
 */
proto.dash.v1.PastExamQuestion.prototype.setQuestionNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.CalendarEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.CalendarEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.CalendarEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.CalendarEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    area: (f = msg.getArea()) && proto.dash.v1.Area.Simple.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    eventType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    title: jspb.Message.getFieldWithDefault(msg, 7, ""),
    description: jspb.Message.getFieldWithDefault(msg, 8, ""),
    startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    examGroup: (f = msg.getExamGroup()) && proto.dash.v1.ExamGroup.Simple.toObject(includeInstance, f),
    examType: (f = msg.getExamType()) && proto.dash.v1.ExamType.Simple.toObject(includeInstance, f),
    academicYear: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.CalendarEvent}
 */
proto.dash.v1.CalendarEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.CalendarEvent;
  return proto.dash.v1.CalendarEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.CalendarEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.CalendarEvent}
 */
proto.dash.v1.CalendarEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = new proto.dash.v1.Area.Simple;
      reader.readMessage(value,proto.dash.v1.Area.Simple.deserializeBinaryFromReader);
      msg.setArea(value);
      break;
    case 5:
      var value = /** @type {!proto.types.v1.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {!proto.types.v1.CalendarEventType} */ (reader.readEnum());
      msg.setEventType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    case 11:
      var value = new proto.dash.v1.ExamGroup.Simple;
      reader.readMessage(value,proto.dash.v1.ExamGroup.Simple.deserializeBinaryFromReader);
      msg.setExamGroup(value);
      break;
    case 12:
      var value = new proto.dash.v1.ExamType.Simple;
      reader.readMessage(value,proto.dash.v1.ExamType.Simple.deserializeBinaryFromReader);
      msg.setExamType(value);
      break;
    case 13:
      var value = /** @type {!proto.types.v1.AcademicYear} */ (reader.readEnum());
      msg.setAcademicYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.CalendarEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.CalendarEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.CalendarEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.CalendarEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getArea();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.dash.v1.Area.Simple.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getEventType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExamGroup();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.dash.v1.ExamGroup.Simple.serializeBinaryToWriter
    );
  }
  f = message.getExamType();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.dash.v1.ExamType.Simple.serializeBinaryToWriter
    );
  }
  f = message.getAcademicYear();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.CalendarEvent.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.CalendarEvent} returns this
 */
proto.dash.v1.CalendarEvent.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.CalendarEvent.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.CalendarEvent} returns this
*/
proto.dash.v1.CalendarEvent.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.CalendarEvent} returns this
 */
proto.dash.v1.CalendarEvent.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.CalendarEvent.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.CalendarEvent.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.CalendarEvent} returns this
*/
proto.dash.v1.CalendarEvent.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.CalendarEvent} returns this
 */
proto.dash.v1.CalendarEvent.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.CalendarEvent.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Area.Simple area = 4;
 * @return {?proto.dash.v1.Area.Simple}
 */
proto.dash.v1.CalendarEvent.prototype.getArea = function() {
  return /** @type{?proto.dash.v1.Area.Simple} */ (
    jspb.Message.getWrapperField(this, proto.dash.v1.Area.Simple, 4));
};


/**
 * @param {?proto.dash.v1.Area.Simple|undefined} value
 * @return {!proto.dash.v1.CalendarEvent} returns this
*/
proto.dash.v1.CalendarEvent.prototype.setArea = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.CalendarEvent} returns this
 */
proto.dash.v1.CalendarEvent.prototype.clearArea = function() {
  return this.setArea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.CalendarEvent.prototype.hasArea = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional types.v1.Status status = 5;
 * @return {!proto.types.v1.Status}
 */
proto.dash.v1.CalendarEvent.prototype.getStatus = function() {
  return /** @type {!proto.types.v1.Status} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.types.v1.Status} value
 * @return {!proto.dash.v1.CalendarEvent} returns this
 */
proto.dash.v1.CalendarEvent.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional types.v1.CalendarEventType event_type = 6;
 * @return {!proto.types.v1.CalendarEventType}
 */
proto.dash.v1.CalendarEvent.prototype.getEventType = function() {
  return /** @type {!proto.types.v1.CalendarEventType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.types.v1.CalendarEventType} value
 * @return {!proto.dash.v1.CalendarEvent} returns this
 */
proto.dash.v1.CalendarEvent.prototype.setEventType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string title = 7;
 * @return {string}
 */
proto.dash.v1.CalendarEvent.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.CalendarEvent} returns this
 */
proto.dash.v1.CalendarEvent.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.dash.v1.CalendarEvent.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.CalendarEvent} returns this
 */
proto.dash.v1.CalendarEvent.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp start_date = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.CalendarEvent.prototype.getStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.CalendarEvent} returns this
*/
proto.dash.v1.CalendarEvent.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.CalendarEvent} returns this
 */
proto.dash.v1.CalendarEvent.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.CalendarEvent.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp end_date = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dash.v1.CalendarEvent.prototype.getEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dash.v1.CalendarEvent} returns this
*/
proto.dash.v1.CalendarEvent.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.CalendarEvent} returns this
 */
proto.dash.v1.CalendarEvent.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.CalendarEvent.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ExamGroup.Simple exam_group = 11;
 * @return {?proto.dash.v1.ExamGroup.Simple}
 */
proto.dash.v1.CalendarEvent.prototype.getExamGroup = function() {
  return /** @type{?proto.dash.v1.ExamGroup.Simple} */ (
    jspb.Message.getWrapperField(this, proto.dash.v1.ExamGroup.Simple, 11));
};


/**
 * @param {?proto.dash.v1.ExamGroup.Simple|undefined} value
 * @return {!proto.dash.v1.CalendarEvent} returns this
*/
proto.dash.v1.CalendarEvent.prototype.setExamGroup = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.CalendarEvent} returns this
 */
proto.dash.v1.CalendarEvent.prototype.clearExamGroup = function() {
  return this.setExamGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.CalendarEvent.prototype.hasExamGroup = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ExamType.Simple exam_type = 12;
 * @return {?proto.dash.v1.ExamType.Simple}
 */
proto.dash.v1.CalendarEvent.prototype.getExamType = function() {
  return /** @type{?proto.dash.v1.ExamType.Simple} */ (
    jspb.Message.getWrapperField(this, proto.dash.v1.ExamType.Simple, 12));
};


/**
 * @param {?proto.dash.v1.ExamType.Simple|undefined} value
 * @return {!proto.dash.v1.CalendarEvent} returns this
*/
proto.dash.v1.CalendarEvent.prototype.setExamType = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.CalendarEvent} returns this
 */
proto.dash.v1.CalendarEvent.prototype.clearExamType = function() {
  return this.setExamType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.CalendarEvent.prototype.hasExamType = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional types.v1.AcademicYear academic_year = 13;
 * @return {!proto.types.v1.AcademicYear}
 */
proto.dash.v1.CalendarEvent.prototype.getAcademicYear = function() {
  return /** @type {!proto.types.v1.AcademicYear} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.types.v1.AcademicYear} value
 * @return {!proto.dash.v1.CalendarEvent} returns this
 */
proto.dash.v1.CalendarEvent.prototype.setAcademicYear = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


goog.object.extend(exports, proto.dash.v1);
