import React from 'react';
import { useLocation, Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { AuthStorage } from '../helpers/manager';


const RequireAuth = ({ children }) => {
  let auth = React.useContext(AuthContext);
  let location = useLocation();

  const token = AuthStorage.getToken();
  if (token === null){
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  const now = new Date();
  if (token.accessTokenExpiration < now && token.refreshTokenExpiration < now) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

export default RequireAuth;