import { lessonBookService } from './services';
import { GetLessonBookRequest, PutLessonBookRequest, ListLessonBooksRequest } from '../grpc/dash/v1/lesson_book_pb';
import { LessonBook, Area, Lesson, File } from '../grpc/dash/v1/models_pb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

export const lessonBookServiceGetLessonBook = (id) => lessonBookService.getLessonBook(new GetLessonBookRequest().setId(id));

export const lessonBookServiceListLessonBooks = ({ areaId, lessonId, status }) => lessonBookService.listLessonBooks(new ListLessonBooksRequest()
    .setAreaId(areaId)
    .setLessonId(lessonId)
    .setStatus(status));

export const lessonBookServicePutLessonBook = (lessonBook, file) => lessonBookService.putLessonBook(
    new PutLessonBookRequest()
        .setLessonBook(
            new LessonBook()
                .setId(lessonBook.id)
                .setArea(new Area().setId(lessonBook.area.id))
                .setLesson(new Lesson().setId(lessonBook.lesson.id))
                .setLessonContentType(lessonBook.lessonContentType)
                .setStatus(lessonBook.status)
                .setTargetUrl(lessonBook.targetUrl)
        )
        .setFile(file === null ? null : new File()
            .setName(file.name)
            .setContent(file.content)
            .setType(file.type)));