import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen';
import ErrorContent from '../../components/ErrorContent.js';
import Breadcrumbs from '../../components/Breadcrumbs';
import { examTypeServiceListExamTypes } from '../../api/exam_type';
import { areaServiceListAreas } from '../../api/area.js';

function ExamTypes() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [areas, setAreas] = useState([]);
    const [breadcrumbData, setBreadcrumbData] = useState(null);

    const [formAreaId, setFormAreaId] = useState(parseInt(searchParams.get('areaId')) || 0)

    const loadScreen = async () => {
        try {
            await loadAreas();
            await loadExamTypes();
            setLoading(false);
        } catch (err) {
            console.log(err);
            setError(err);
            setLoading(false);
        }
    };

    const loadExamTypes = async () => {

        const response = await examTypeServiceListExamTypes({ areaId: formAreaId });
        setData(response.toObject().examTypesList);
    }

    const loadAreas = async () => {
        const response = await areaServiceListAreas();
        setAreas(response.toObject().areasList);
    }

    useEffect(() => {
        setSearchParams({
            areaId: formAreaId
        });

        console.log("reload data");
        const fetchData = async () => {
            const response = await examTypeServiceListExamTypes({ areaId: formAreaId });;
            setData(response.toObject().examTypesList);
        }
        fetchData();
    }, [formAreaId]);

    useEffect(() => {
        document.title = "SINAV TİPLERİ"

        setBreadcrumbData([
            { name: 'Dash', url: '/' },
            { name: 'Sınav Tipleri', url: '' },
        ]);
        loadScreen();
    }, [])

    const RenderContent = () => {
        if (loading) {
            return (
                <LoadingScreen />
            );
        } else if (error !== null) {
            return (
                <ErrorContent error={error} clickFunction={() => console.log("Tekrar Yükle")} />
            );
        } else {
            return (
                <table className="card-body table">
                    <thead>
                        <tr>
                            <th scope="col" rowSpan={2} className="text-end">#</th>
                            <th scope="col">Alan</th>
                            <th scope="col">Başlık</th>
                            <th scope="col">İşlemler</th>
                        </tr>
                        <tr>

                            <th>
                                <select id="form-area" className="form-select form-select-sm" select={formAreaId} value={formAreaId} onChange={e => setFormAreaId(e.target.value)}>
                                    <option key={`area-0`} value={0}>Belirtilmemiş</option>
                                    {
                                        areas.map((item, index) => (
                                            <option key={`area-${index}`} value={item.id}>{item.title}</option>
                                        ))
                                    }
                                </select>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data === null || data.length === 0 ? <tr><td colSpan={4} className='text-center'>Henüz içerik eklenmemiş.</td></tr> : data.map((item, Index) => {
                                return (
                                    <tr key={"area_" + Index}>
                                        <td className="text-end">{item.id} </td>
                                        <td>{areas.find(s => s.id == item.areaId)?.title} </td>
                                        <td>{item.title} </td>
                                        <td>
                                            <div className="btn-Type" role="Type">
                                                <Link to={item.id.toString()} className="btn btn-sm btn-primary" role="button">DÜZENLE</Link>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            );
        }
    };

    return (
        <>
            <div className="container-fluid">

                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <Breadcrumbs data={breadcrumbData} />
                        <div className="btn-Type">
                            <Link to="create" className="btn btn-sm btn-primary">+</Link>
                        </div>
                    </div>
                    {RenderContent()}

                </div>
            </div>

        </>
    );
}

export default ExamTypes;