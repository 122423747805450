import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import LoadingScreen from "../../components/LoadingScreen";
import ErrorContent from "../../components/ErrorContent";
import LoadingButton from "../../components/LoadingButton";
import { lessonContentType, status } from "../../api/types";
import { LessonBook, File, Area, Lesson } from "../../grpc/dash/v1/models_pb";
import { areaServiceListAreas } from "../../api/area";
import { lessonServiceListLessons } from "../../api/lesson";
import { fileServiceFileUpload } from "../../api/file";
import { lessonBookServiceGetLessonBook, lessonBookServicePutLessonBook } from "../../api/lesson_book";
import FileToBase64 from "../../helpers/fileToBase64";
import moment from "moment-timezone";

function LessonBookEdit() {
    let { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(new LessonBook().setArea(new Area()).setLesson(new Lesson()).toObject());
    const [file, setFile] = useState(null);
    const [breadcrumbData, setBreadcrumbData] = useState(null);

    const [areas, setAreas] = useState([]);
    const [lessons, setLessons] = useState([]);

    const loadAreas = async () => {
        const response = await areaServiceListAreas();
        console.log("areas", response.toObject().areasList);
        setAreas(response.toObject().areasList);
    }

    const loadLessons = async () => {
        const response = await lessonServiceListLessons({});
        setLessons(response.toObject().lessonsList);
    }

    const loadScreen = async () => {
        try {

            await loadAreas();
            await loadLessons();

            const breadcrumbItems = [{ name: "DERS - KİTAPLARI", url: "/lesson-books" }];

            if (id === undefined) {
                breadcrumbItems.push({ name: "YENİ", url: "" });
            } else {
                const result = await lessonBookServiceGetLessonBook(id);
                setData(result.toObject().lessonBook)
                breadcrumbItems.push({ name: `#${id}`, url: "" });
            }
            setBreadcrumbData(breadcrumbItems);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setError(err);
            setLoading(false);
        }
    }
    const setCustomData = (key, value) => {
        switch (key) {
            case "areaId":
                // use state uygulanacak

                data["area"]["id"] = parseInt(value);
                data["lesson"]["id"] = 0;
                break;
            case "lessonId":
                data["lesson"]["id"] = parseInt(value);
                break;
            case "lessonContentType":
            case "status":
                data[key] = parseInt(value)
                break;
            default:
                data[key] = value;
                break;
        }

        setData({ ...data })
    }
    const onPressForm = async (e) => {
        e.preventDefault();
        try {
            let result = await lessonBookServicePutLessonBook(data, file);
            let object = result.toObject().lessonBook
            console.log(object)
            navigate(`/lesson-books?areaId=${object.area.id}&lessonId=${object.lesson.id}`);
        } catch (err) {
            console.log(err);
            setError(err);
        }
    }

    const handleFileChange = async event => {
        const _file = event.target.files && event.target.files[0];
        if (!_file) {
            return;
        }

        const object = await FileToBase64(_file);
        setFile(new File()
            .setName(object.name)
            .setType(object.type)
            .setContent(object.data)
            .toObject());
    };


    const RenderContent = () => {
        if (loading)
            return <LoadingScreen />;
        if (error !== null)
            return <ErrorContent error={error} />

        console.log(data)
        return <form className="card-body row g-3">
            <div className="col-md-12 py-1">
                <label htmlFor="form-area" className="form-label">ALAN</label>
                <select id="form-area" className="form-select" defaultValue={data.area.id} onChange={e => setCustomData("areaId", e.target.value)}>
                    <option key={`area-0`} value={""}>Belirtilmemiş</option>
                    {
                        areas.map((item, index) => <option key={`area-${index}`} value={item.id}>{item.title}</option>)
                    }
                </select>
            </div>
            <div className="col-md-12 py-1">
                <label htmlFor="form-lesson" className="form-label">DERS</label>
                <select disabled={data.area.id == 0} id="form-lesson" className="form-select" defaultValue={data.lesson.id} onChange={e => setCustomData("lessonId", e.target.value)}>
                    <option key={`lesson-0`} value={""}>Belirtilmemiş</option>
                    {
                        lessons.filter(s => s.area.id == data.area.id).map((item, index) => (<option key={`lesson-${index}`} value={item.id}>{item.title} ({item.code})</option>))
                    }
                </select>
            </div>
            <div className="col-md-12 py-1">
                <label htmlFor="form-lesson-content-type" className="form-label">İÇERİK TİPİ</label>
                <select id="form-lesson-content-type" className="form-select" defaultValue={data.lessonContentType} onChange={e => setCustomData("lessonContentType", e.target.value)}>
                    {
                        Object.values(lessonContentType()).map((value, index) => (
                            <option key={"lesson_content_type" + index} value={value}>{lessonContentType(value)}</option>
                        ))
                    }
                </select>
            </div>

            <div className="col-md-12 py-1">
                <label htmlFor="form-status" className="form-label">DURUM</label>
                <select id="form-status" className="form-select" defaultValue={data.status} onChange={e => setCustomData("status", e.target.value)}>
                    {
                        Object.values(status()).map((value, index) => (
                            <option key={"status" + index} value={value}>{status(value)}</option>
                        ))
                    }
                </select>
            </div>

            <div className="col-md-8 py-1">
                <label htmlFor="form-target-url" className="form-label">HEDEF URL</label>
                <input type="text" className="form-control" id="form-target-url" value={data.targetUrl} onChange={e => setCustomData("targetUrl", e.target.value)} />
            </div>

            <div className="col-md-6">
                <label htmlFor="form-content" className="form-label">PDF</label>
                <input type="file" className="form-control" id="form-content" onChange={handleFileChange} />
            </div>
            <div className="col-12">
                <LoadingButton onClick={onPressForm} Text="KAYDET" />
            </div>
        </form >
    };
    useEffect(() => {
        loadScreen();
    }, []);
    return <div className="container">
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <Breadcrumbs data={breadcrumbData} />
            </div>
            {RenderContent()}
        </div>
    </div>;
}

export default LessonBookEdit;