import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import LoadingScreen from "../../components/LoadingScreen";
import ErrorContent from "../../components/ErrorContent";
import LoadingButton from "../../components/LoadingButton";
import { ExamGroup } from "../../grpc/dash/v1/models_pb";
import { areaServiceListAreas } from "../../api/area";
import { examGroupServiceGetExamGroup, examGroupServicePutExamGroup } from "../../api/exam_group";

function ExamGroupEdit() {
    let { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(new ExamGroup().toObject());
    const [areas, setAreas] = useState([]);
    const [breadcrumbData, setBreadcrumbData] = useState(null);

    const loadAreas = async () => {
        const response = await areaServiceListAreas();
        setAreas(response.toObject().areasList);
    }

    const loadScreen = async () => {
        try {
            await loadAreas();

            const breadcrumbItems = [{ name: "SINAV GRUPLARI", url: "/exam-groups" }];
            if (id === undefined) {
                breadcrumbItems.push({ name: "YENİ", url: "" });
            } else {
                const result = await examGroupServiceGetExamGroup(id);
                setData(result.toObject().examGroup)
                breadcrumbItems.push({ name: `#${id}`, url: "" });
            }
            setBreadcrumbData(breadcrumbItems);
            setLoading(false);
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    }
    const setCustomData = (key, value) => {
        data[key] = value;
        setData({ ...data })
    }
    const onPressForm = async (e) => {
        e.preventDefault();
        try {
            await examGroupServicePutExamGroup(data);
            navigate(`/exam-groups?areaId={$areaId}`);
        } catch (err) {
            console.log(err);
            setError(err);
        }
    }
    const RenderContent = () => {
        if (loading)
            return <LoadingScreen />;
        if (error !== null)
            return <ErrorContent error={error} />
        return <form className="card-body row g-3">

            <div className="col-md-12 py-1">
                <label htmlFor="form-area" className="form-label">ALAN</label>
                <select id="form-area" className="form-select" defaultValue={data.areaId} onChange={e => setCustomData("areaId", e.target.value)}>
                    <option key={`area-0`} value={0}>Belirtilmemiş</option>
                    {
                        areas.map((item, index) => <option key={`area-${index}`} value={item.id}>{item.title}</option>)
                    }
                </select>
            </div>
            <div className="col-md-12 py-1">
                <label htmlFor="form-title" className="form-label">BAŞLIK</label>
                <input type="text" className="form-control" id="form-title" value={data.title} onChange={e => setCustomData("title", e.target.value)} />
            </div>
            <div className="col-12">
                <LoadingButton onClick={onPressForm} Text="KAYDET" />
            </div>
        </form >
    };
    useEffect(() => {
        loadScreen();
    }, []);
    return <div className="container">
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <Breadcrumbs data={breadcrumbData} />
            </div>
            {RenderContent()}
        </div>
    </div>;
}

export default ExamGroupEdit;