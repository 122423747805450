import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import LoadingScreen from "../../components/LoadingScreen";
import ErrorContent from "../../components/ErrorContent";
import LoadingButton from "../../components/LoadingButton";
import { academicYear, status } from "../../api/types";
import { PastExamBooklet, PastExam, Area, ExamGroup, ExamType, Lesson } from "../../grpc/dash/v1/models_pb";
import { areaServiceListAreas } from "../../api/area";
import { fileServiceFileUpload } from "../../api/file";
import { pastExamBookletServiceListPastExamBooklets } from "../../api/past_exam_booklet";
import { examGroupServiceListExamGroups } from "../../api/exam_group";
import { examTypeServiceListExamTypes } from "../../api/exam_type";
import { lessonServiceListLessons } from "../../api/lesson";
import moment from "moment-timezone";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { pastExamServiceCreatePastExams } from "../../api/past_exam";

function ExamCreate() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(new PastExam().toObject());
    const [breadcrumbData, setBreadcrumbData] = useState(null);

    const [file, setFile] = useState(null);

    const [areas, setAreas] = useState([]);
    const [booklets, setBooklets] = useState([]);
    const [examGroups, setExamGroups] = useState([]);
    const [examTypes, setExamTypes] = useState([]);
    const [lessons, setLessons] = useState([]);

    const [exams, setExams] = useState([]);

    const loadAreas = async () => {
        const response = await areaServiceListAreas();
        setAreas(response.toObject().areasList);
    }


    const loadBooklets = async () => {
        const response = await pastExamBookletServiceListPastExamBooklets({});
        setBooklets(response.toObject().bookletsList);
    }

    const loadExamGroups = async () => {
        const response = await examGroupServiceListExamGroups({});
        setExamGroups(response.toObject().examGroupsList);
    }

    const loadExamTypes = async () => {
        const response = await examTypeServiceListExamTypes({});
        setExamTypes(response.toObject().examTypesList);
    }

    const loadLessons = async () => {
        const response = await lessonServiceListLessons({});
        setLessons(response.toObject().lessonsList);
    }

    const loadScreen = async () => {
        try {
            await loadAreas();
            await loadBooklets();
            await loadExamGroups();
            await loadExamTypes();
            await loadLessons();

            setBreadcrumbData([{ name: "SINAVLAR", url: "/exams" }, { name: "YENİ", url: "" }]);

            setLoading(false);
        } catch (err) {
            console.log(err);
            setError(err);
            setLoading(false);
        }
    }
    const setCustomData = (key, value) => {
        switch (key) {
            case "date":
                if (value === "") {
                    value = undefined;
                } else {
                    value = { seconds: Math.floor(moment.tz(value.replace("T", " "), "UTC") / 1000) }
                }
                data[key] = value;

                console.log(value)
                break;
            case "bookletId":
                let booklet = booklets.find(s => s.id == value);
                data["examTypeId"] = booklet?.examTypeId ?? "";
                data["examGroupId"] = booklet?.examGroupId ?? "";
                data["lessonId"] = booklet?.lessonId ?? "";
                data["academicYear"] = booklet?.academicYear ?? 0;
                data["date"] = booklet?.date ?? undefined;
                data[key] = value;
                setFile(`https://fma.sinavhane.net/booklet/${value}.pdf`)
                break;
            case "areaId":
                data["examGroupId"] = "";
                data["examTypeId"] = "";
                data["bookletId"] = "";
                data[key] = value;
                break;
            default:
                data[key] = value;
                break;
        }

        setData({ ...data })
    }
    const onPressNewItem = async (e) => {
        e.preventDefault();

        if (data.bookletId == undefined || data.bookletId == "")
            return;
        try {


            setExams([...exams, new PastExam()
                .setBookletId(data.bookletId)
                .setArea(data.areaId == "" ? undefined : new Area.Simple().setId(data.areaId))
                .setExamGroup(data.examGroupId == "" ? undefined : new ExamGroup.Simple().setId(data.examGroupId))
                .setExamType(data.examTypeId == "" ? undefined : new ExamType.Simple().setId(data.examTypeId))
                .setLesson(data.lessonId == "" ? undefined : new Lesson.Simple().setId(data.lessonId))
                .setAcademicYear(data.academicYear)
                .setDate(data.date ? new Timestamp().setSeconds(data.date.seconds).setNanos(data.date.nanos) : undefined)
                .toObject()
            ]);
        } catch (err) {
            console.log(err);
            setError(err);
        }
    }
    const onPressCreateExams = async (e) => {
        e.preventDefault();
        try {
            let result = await pastExamServiceCreatePastExams(exams);
            navigate(`/exams`);
        } catch (err) {
            console.log(err);
            setError(err);
        }
    }

    const RenderContent = () => {
        if (loading)
            return <LoadingScreen />;
        if (error !== null)
            return <ErrorContent error={error} />


        return <form className="card-body row g-3">


            <div className="col-6">
                <div className="row">
                    <div className="col-md-12 py-1">
                        <label htmlFor="form-area" className="form-label">ALAN</label>
                        <select id="form-area" className="form-select" defaultValue={data.areaId} onChange={e => setCustomData("areaId", e.target.value)}>
                            <option key={`area-0`} value={""}>Belirtilmemiş</option>
                            {
                                areas.map((item, index) => <option key={`area-${index}`} value={item.id}>{item.title}</option>)
                            }
                        </select>
                    </div>
                    <div className="col-md-12 py-1">
                        <label htmlFor="form-booklet" className="form-label">Kitapçık</label>
                        <select disabled={data.areaId == ""} id="form-booklet" className="form-select" defaultValue={data.bookletId} onChange={e => setCustomData("bookletId", e.target.value)}>
                            <option key={`booklet-0`} value={""}>Belirtilmemiş</option>
                            {
                                booklets.filter(s => s.areaId == data.areaId).map((item, index) => (<option key={`exam-type-${index}`} value={item.id}>
                                    Grup: {examGroups.find(s => s.id == item.examGroupId)?.title ?? "Belirtilmemiş"} #
                                    Tip: {examTypes.find(s => s.id == item.examTypeId)?.title ?? "Belirtilmemiş"}  #
                                    Akademik Yıl: {academicYear(item.academicYear)} #
                                    Ders: {lessons.find(s => s.id == item.lessonId)?.title ?? "Belirtilmemiş"} #
                                    Tarih: {item.date ? new Date(item.date.seconds * 1000).toISOString().slice(0, -5).replace("T", " ") : "-"}
                                </option>))
                            }
                        </select>
                    </div>
                    <div className="col-md-2 py-1">
                        <label htmlFor="form-exam-group" className="form-label">Grup</label>
                        <select disabled={booklets.find(s => s.id == data.bookletId)?.examGroupId != ""} id="form-exam-group" className="form-select" value={data.examGroupId} onChange={e => setCustomData("examGroupId", e.target.value)}>
                            <option key={`exam-group-0`} value={""}>Belirtilmemiş</option>
                            {
                                examGroups.filter(s => s.areaId == data.areaId).map((item, index) => (<option key={`exam-group-${index}`} value={item.id} >{item.title}</option>))
                            }
                        </select>
                    </div>
                    <div className="col-md-2 py-1">
                        <label htmlFor="form-exam-type" className="form-label">Tip</label>
                        <select disabled={booklets.find(s => s.id == data.bookletId)?.examTypeId != ""} id="form-exam-type" className="form-select" value={data.examTypeId} onChange={e => setCustomData("examTypeId", e.target.value)}>
                            <option key={`exam-type-0`} value={""}>Belirtilmemiş</option>
                            {
                                examTypes.filter(s => s.areaId == data.areaId).map((item, index) => (<option key={`exam-type-${index}`} value={item.id} >{item.title}</option>))
                            }
                        </select>
                    </div>
                    <div className="col-md-3 py-1">
                        <label htmlFor="form-academic-year" className="form-label">Akademik Yıl</label>
                        <select disabled={booklets.find(s => s.id == data.bookletId)?.academicYear != 0} id="form-academic-year" className="form-select" value={data.academicYear} onChange={e => setCustomData("academicYear", parseInt(e.target.value))}>
                            {
                                Object.values(academicYear()).map((value, index) => (
                                    <option key={"academic-year" + index} value={value}>{academicYear(value)}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="col-md-3 py-1">
                        <label htmlFor="form-lesson" className="form-label">Ders</label>
                        <select disabled={booklets.find(s => s.id == data.bookletId)?.lessonId != 0} id="form-lesson" className="form-select" value={data.lessonId} onChange={e => setCustomData("lessonId", e.target.value)}>
                            <option key={`lesson-0`} value={""}>Belirtilmemiş</option>
                            {
                                lessons.filter(s => s.area.id == data.areaId).map((item, index) => (<option key={`lesson-${index}`} value={item.id} >{item.title} ({item.code})</option>))
                            }
                        </select>
                    </div>
                    <div className="col-md-2 py-1">
                        <label htmlFor="form-lesson-code" className="form-label">Kod</label>
                        <select disabled={booklets.find(s => s.id == data.bookletId)?.lessonId != 0} id="form-lesson-code" className="form-select" value={data.lessonId} onChange={e => setCustomData("lessonId", e.target.value)}>
                            <option key={`lesson-code-0`} value={""}>Belirtilmemiş</option>
                            {
                                lessons.filter(s => s.area.id == data.areaId).map((item, index) => (<option key={`lesson-code-${index}`} value={item.id}>{item.code}</option>))
                            }
                        </select>
                    </div>
                    <div className="col-12 py-1 text-end">
                        <LoadingButton onClick={onPressNewItem} Text="EKLE" />
                    </div>

                    <div className="col-12">
                        <table className="card-body table">
                            <thead>
                                <tr>
                                    
                                    <th scope="col">Grup</th>
                                    <th scope="col">Tip</th>
                                    <th scope="col">Akademik Yıl</th>
                                    <th scope="col">Ders</th>
                                    <th scope="col">Kod</th>
                                    <th scope="col">Tarih</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    exams.map((item, Index) => {
                                        var booklet = booklets.find(s => s.id == item.bookletId);

                                        console.log(item);
                                        return (
                                            <tr key={"exam_" + Index}>
                                                <td className={booklet?.examGroupId == item.examGroup?.id ? "text-secondary" : "text-primary"}>{examGroups.find(s => s.id == item.examGroup?.id)?.title ?? "-"} </td>
                                                <td className={booklet?.examTypeId == item.examType?.id ? "text-secondary" : "text-primary"} >{examTypes.find(s => s.id == item.examType?.id)?.title ?? "-"} </td>
                                                <td className={booklet?.academicYear == item.academicYear ? "text-secondary" : "text-primary"}>{academicYear(item.academicYear)}</td>
                                                <td className={booklet?.lessonId == item.lesson?.id ? "text-secondary" : "text-primary"}>{lessons.find(s => s.id == item.lesson?.id)?.title ?? "-"} </td>
                                                <td className={booklet?.lessonId == item.lesson?.id ? "text-secondary" : "text-primary"}>{lessons.find(s => s.id == item.lesson?.id)?.code ?? "-"}</td>
                                                {/* <td>{item.date === undefined ? "-" : new Date(item.date.seconds * 1000).toISOString().slice(0, -8).replace("T", " ")}</td> */}
                                                <td>
                                                    <div className="btn-group" role="group">
                                                        <Link onClick={() => {

                                                            setExams([...exams.filter((_, i) => i !== Index)]);
                                                        }} className="btn btn-sm btn-primary" role="button">SİL</Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="col-12 py-1 text-end">
                        <LoadingButton onClick={onPressCreateExams} Text="OLUŞTUR" />
                    </div>
                </div>
            </div>
            <div className="col-6 sticky-top overflow-scroll" style={{ height: "80vh" }}>
                {
                    data.bookletId == "" ? "" : <object className="pdf" data={file} width={"100%"} height={"100%"}></object>
                }

            </div>


        </form >
    };
    useEffect(() => {
        loadScreen();
    }, []);
    return <div className="container-fluid">
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <Breadcrumbs data={breadcrumbData} />
            </div>
            {RenderContent()}
        </div>
    </div>;
}

export default ExamCreate;