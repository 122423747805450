import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import LoadingScreen from "../../components/LoadingScreen";
import ErrorContent from "../../components/ErrorContent";
import LoadingButton from "../../components/LoadingButton";
import { academicYear, status } from "../../api/types";
import { PastExamBooklet, File } from "../../grpc/dash/v1/models_pb";
import { areaServiceListAreas } from "../../api/area";
import { fileServiceFileUpload } from "../../api/file";
import { pastExamBookletServiceGetPastExamBooklet, pastExamBookletServicePutPastExamBooklet } from "../../api/past_exam_booklet";
import { examGroupServiceListExamGroups } from "../../api/exam_group";
import { examTypeServiceListExamTypes } from "../../api/exam_type";
import FileToBase64 from "../../helpers/fileToBase64";
import moment from "moment-timezone";

function PastExamBookletEdit() {
    let { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(new PastExamBooklet().toObject());
    const [file, setFile] = useState(null);
    const [breadcrumbData, setBreadcrumbData] = useState(null);

    const [areas, setAreas] = useState([]);
    const [examGroups, setExamGroups] = useState([]);
    const [examTypes, setExamTypes] = useState([]);

    const loadAreas = async () => {
        const response = await areaServiceListAreas();
        console.log("areas",response.toObject().areasList);
        setAreas(response.toObject().areasList);
    }

    const loadExamGroups = async () => {
        const response = await examGroupServiceListExamGroups({});
        setExamGroups(response.toObject().examGroupsList);
    }

    const loadExamTypes = async () => {
        const response = await examTypeServiceListExamTypes({});
        setExamTypes(response.toObject().examTypesList);
    }

    const loadScreen = async () => {
        try {

            await loadAreas();
            await loadExamGroups();
            await loadExamTypes();

            const breadcrumbItems = [{ name: "GEÇMİŞ SINAVLAR - KİTAPÇIKLAR", url: "/past-exam-booklets" }];

            console.log("aa",id);
            if (id === undefined) {
                breadcrumbItems.push({ name: "YENİ", url: "" });
            } else {
                const result = await pastExamBookletServiceGetPastExamBooklet(id);
                setData(result.toObject().booklet)
                breadcrumbItems.push({ name: `#${id}`, url: "" });
            }
            setBreadcrumbData(breadcrumbItems);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setError(err);
            setLoading(false);
        }
    }
    const setCustomData = (key, value) => {
        switch (key) {
            case "date":
                if (value === "") {
                    value = undefined;
                } else {
                    value = { seconds: Math.floor(moment.tz(value.replace("T", " "), "UTC") / 1000) }
                }
                data[key] = value;

                console.log(value)
                break;
            case "areaId":
                // use state uygulanacak
                data["examGroupId"] = "";
                data["examTypeId"] = "";
                data[key] = value;
                break;
            default:
                data[key] = value;
                break;
        }

        setData({ ...data })
    }
    const onPressForm = async (e) => {
        e.preventDefault();
        try {
            console.log("aa",file === null);
            let result = await pastExamBookletServicePutPastExamBooklet(data, file);
            navigate(`/past-exam-booklets?areaId=${result.toObject().areaId}`);
        } catch (err) {
            console.log(err);
            setError(err);
        }
    }

    const handleFileChange = async event => {
        const _file = event.target.files && event.target.files[0];
        if (!_file) {
            return;
        }
        
        const object = await FileToBase64(_file);
        setFile(new File()
            .setName(object.name)
            .setType(object.type)
            .setContent(object.data)
            .toObject());
    };


    const RenderContent = () => {
        if (loading)
            return <LoadingScreen />;
        if (error !== null)
            return <ErrorContent error={error} />
        return <form className="card-body row g-3">
            <div className="col-md-12 py-1">
                <label htmlFor="form-area" className="form-label">ALAN</label>
                <select id="form-area" className="form-select" defaultValue={data.areaId} onChange={e => setCustomData("areaId", e.target.value)}>
                    <option key={`area-0`} value={""}>Belirtilmemiş</option>
                    {
                        areas.map((item, index) => <option key={`area-${index}`} value={item.id}>{item.title}</option>)
                    }
                </select>
            </div>
            <div className="col-md-12 py-1">
                <label htmlFor="form-exam-group" className="form-label">Grup</label>
                <select disabled={data.areaId == ""} id="form-exam-group" className="form-select" defaultValue={data.examGroupId} onChange={e => setCustomData("examGroupId", e.target.value)}>
                    <option key={`exam-group-0`} value={""}>Belirtilmemiş</option>
                    {
                        examGroups.filter(s => s.areaId == data.areaId).map((item, index) => (<option key={`exam-group-${index}`} value={item.id}>{item.title}</option>))
                    }
                </select>
            </div>
            <div className="col-md-12 py-1">
                <label htmlFor="form-exam-type" className="form-label">Tip</label>
                <select disabled={data.areaId == ""} id="form-exam-type" className="form-select" defaultValue={data.examTypeId} onChange={e => setCustomData("examTypeId", e.target.value)}>
                    <option key={`exam-type-0`} value={""}>Belirtilmemiş</option>
                    {
                        examTypes.filter(s => s.areaId == data.areaId).map((item, index) => (<option key={`exam-type-${index}`} value={item.id}>{item.title}</option>))
                    }
                </select>
            </div>


            <div className="col-md-12 py-1">
                <label htmlFor="form-academic-year" className="form-label">Akademik Yıl</label>
                <select id="form-academic-year" className="form-select" defaultValue={data.academicYear} onChange={e => setCustomData("academicYear", e.target.value)}>
                    {
                        Object.values(academicYear()).map((value, index) => (
                            <option key={"academic-year" + index} value={value}>{academicYear(value)}</option>
                        ))
                    }
                </select>
            </div>
            <div className="col-md-12 py-1">
                <label htmlFor="form-date" className="form-label">Tarih</label>
                <input id="form-date" type="datetime-local" className="form-control form-select-sm"
                    defaultValue={data.date === undefined ? "" : new Date(data.date.seconds * 1000).toISOString().slice(0, -5)}
                    onChange={(e) => setCustomData("date", e.target.value)}
                />

            </div>

            <div className="col-md-6">
                <label htmlFor="form-content" className="form-label">PDF</label>
                <input type="file" className="form-control" id="form-content" onChange={handleFileChange} />
            </div>
            <div className="col-12">
                <LoadingButton onClick={onPressForm} Text="KAYDET" />
            </div>
        </form >
    };
    useEffect(() => {
        loadScreen();
    }, []);
    return <div className="container">
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <Breadcrumbs data={breadcrumbData} />
            </div>
            {RenderContent()}
        </div>
    </div>;
}

export default PastExamBookletEdit;