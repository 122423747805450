import { examGroupService } from './services';
import { GetExamGroupRequest, PutExamGroupRequest, ListExamGroupsRequest } from '../grpc/dash/v1/exam_group_pb';
import { ExamGroup } from '../grpc/dash/v1/models_pb';

export const examGroupServiceGetExamGroup = (id) => examGroupService.getExamGroup(new GetExamGroupRequest().setId(id));

export const examGroupServiceListExamGroups = ({ areaId }) => examGroupService.listExamGroups(new ListExamGroupsRequest().setAreaId(areaId || 0));

export const examGroupServicePutExamGroup = (examGroup) => examGroupService.putExamGroup(new PutExamGroupRequest()
    .setExamGroup(
        new ExamGroup()
            .setId(examGroup.id)
            .setAreaId(examGroup.areaId)
            .setTitle(examGroup.title)
    ));