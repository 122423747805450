// source: types/v1/academic_year.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.types.v1.AcademicYear', null, global);
/**
 * @enum {number}
 */
proto.types.v1.AcademicYear = {
  ACADEMIC_YEAR_UNSPECIFIED: 0,
  ACADEMIC_YEAR_2010: 2010,
  ACADEMIC_YEAR_2011: 2011,
  ACADEMIC_YEAR_2012: 2012,
  ACADEMIC_YEAR_2013: 2013,
  ACADEMIC_YEAR_2014: 2014,
  ACADEMIC_YEAR_2015: 2015,
  ACADEMIC_YEAR_2016: 2016,
  ACADEMIC_YEAR_2017: 2017,
  ACADEMIC_YEAR_2018: 2018,
  ACADEMIC_YEAR_2019: 2019,
  ACADEMIC_YEAR_2020: 2020,
  ACADEMIC_YEAR_2021: 2021,
  ACADEMIC_YEAR_2022: 2022,
  ACADEMIC_YEAR_2023: 2023,
  ACADEMIC_YEAR_2024: 2024,
  ACADEMIC_YEAR_2025: 2025
};

goog.object.extend(exports, proto.types.v1);
