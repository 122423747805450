// source: types/v1/entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.types.v1.Entity', null, global);
/**
 * @enum {number}
 */
proto.types.v1.Entity = {
  ENTITY_UNSPECIFIED: 0,
  ENTITY_BOOKLET: 1,
  ENTITY_AREA: 2,
  ENTITY_EXAM_GROUP: 3,
  ENTITY_EXAM_TYPE: 4,
  ENTITY_LESSON: 5,
  ENTITY_PAST_EXAM: 6,
  ENTITY_POST: 7,
  ENTITY_PAST_EXAM_QUESTION: 8
};

goog.object.extend(exports, proto.types.v1);
