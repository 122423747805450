/**
 * @fileoverview gRPC-Web generated client stub for dash.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: dash/v1/post.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var dash_v1_models_pb = require('../../dash/v1/models_pb.js')

var types_v1_post_status_pb = require('../../types/v1/post_status_pb.js')

var types_v1_post_type_pb = require('../../types/v1/post_type_pb.js')
const proto = {};
proto.dash = {};
proto.dash.v1 = require('./post_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.PostServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.PostServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.GetPostRequest,
 *   !proto.dash.v1.GetPostResponse>}
 */
const methodDescriptor_PostService_GetPost = new grpc.web.MethodDescriptor(
  '/dash.v1.PostService/GetPost',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.GetPostRequest,
  proto.dash.v1.GetPostResponse,
  /**
   * @param {!proto.dash.v1.GetPostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.GetPostResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.GetPostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.GetPostResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.GetPostResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.PostServiceClient.prototype.getPost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.PostService/GetPost',
      request,
      metadata || {},
      methodDescriptor_PostService_GetPost,
      callback);
};


/**
 * @param {!proto.dash.v1.GetPostRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.GetPostResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.PostServicePromiseClient.prototype.getPost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.PostService/GetPost',
      request,
      metadata || {},
      methodDescriptor_PostService_GetPost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.PutPostRequest,
 *   !proto.dash.v1.PutPostResponse>}
 */
const methodDescriptor_PostService_PutPost = new grpc.web.MethodDescriptor(
  '/dash.v1.PostService/PutPost',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.PutPostRequest,
  proto.dash.v1.PutPostResponse,
  /**
   * @param {!proto.dash.v1.PutPostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.PutPostResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.PutPostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.PutPostResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.PutPostResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.PostServiceClient.prototype.putPost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.PostService/PutPost',
      request,
      metadata || {},
      methodDescriptor_PostService_PutPost,
      callback);
};


/**
 * @param {!proto.dash.v1.PutPostRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.PutPostResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.PostServicePromiseClient.prototype.putPost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.PostService/PutPost',
      request,
      metadata || {},
      methodDescriptor_PostService_PutPost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.ListPostsRequest,
 *   !proto.dash.v1.ListPostsResponse>}
 */
const methodDescriptor_PostService_ListPosts = new grpc.web.MethodDescriptor(
  '/dash.v1.PostService/ListPosts',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.ListPostsRequest,
  proto.dash.v1.ListPostsResponse,
  /**
   * @param {!proto.dash.v1.ListPostsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.ListPostsResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.ListPostsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.ListPostsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.ListPostsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.PostServiceClient.prototype.listPosts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.PostService/ListPosts',
      request,
      metadata || {},
      methodDescriptor_PostService_ListPosts,
      callback);
};


/**
 * @param {!proto.dash.v1.ListPostsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.ListPostsResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.PostServicePromiseClient.prototype.listPosts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.PostService/ListPosts',
      request,
      metadata || {},
      methodDescriptor_PostService_ListPosts);
};


module.exports = proto.dash.v1;

