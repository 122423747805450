import React from 'react';

const ErrorContent = ({error, clickFunction}) => {
  console.log('ErrorContent',error);
  if (error.toString().indexOf('Network Error') > -1) {
    return (
      <div style={styles.content}>
          <p style={styles.contentHTitle}>İnternet Bağlantısı Yok!</p>
          <p style={styles.contentTitle}>Bağlantınızı kontrol edip tekrar deneyiniz.</p>
          <button type="button" className="btn btn-primary" onClick={() => clickFunction()}>Yeniden Dene</button>
      </div>
    );
  }
  else if (error.code === 7) { //yetkisiz sayfa görüntüleme
    return (
      <div style={styles.content}>
        <p style={styles.contentTitle2}>Sayfa içeriğini görebilmek için yetkiniz bulunmamaktadır. Lütfen Yönetici ile iletişime geçiniz.</p>
      </div>
    );
  }
  else {
    return (
      <div style={styles.content}>
        <p style={styles.contentTitle2}>Beklenmedik bir hata oluştu lütfen daha sonra tekrar sayfayı ziyaret ediniz!</p>
      </div>
    );
  }
};

const styles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  content: {
    backgroundColor: 'white',
    padding: 22,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  contentHTitle: {
    fontSize: 23,
    marginBottom: 12,
    fontWeight:'700',
  },
  contentTitle: {
    fontSize: 20,
    marginBottom: 12,
    textAlign:'center',
  },
  contentTitle2: {
    fontSize: 18,
    marginBottom: 12,
    textAlign:'center',
  },
  contentButton: {
    marginBottom: 10,
    paddingHorizontal:20,
  },
  bottomModal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
};

export default ErrorContent;