import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import LoadingScreen from "../../components/LoadingScreen";
import ErrorContent from "../../components/ErrorContent";
import LoadingButton from "../../components/LoadingButton";
import { status } from "../../api/types";
import { Area } from "../../grpc/dash/v1/models_pb";
import { areaServiceGetArea, areaServicePutArea } from "../../api/area";

function AreaEdit() {
    let { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(new Area().toObject());
    const [breadcrumbData, setBreadcrumbData] = useState(null);

    const loadScreen = async () => {
        try {

            const breadcrumbItems = [{ name: "ALANLAR", url: "/areas" }];
            if (id === undefined) {
                breadcrumbItems.push({ name: "YENİ", url: "" });
            } else {
                const result = await areaServiceGetArea(id);

                setData(result.toObject().area)
                breadcrumbItems.push({ name: `#${id}`, url: "" });
            }
            setBreadcrumbData(breadcrumbItems);
            setLoading(false);
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    }
    const setCustomData = (key, value) => {
        data[key] = value;
        setData({ ...data })
    }
    const onPressForm = async (e) => {
        e.preventDefault();
        try {
            await areaServicePutArea({ id: data.id, title: data.title, status: data.status });
            navigate(`/areas`);
        } catch (err) {
            console.log(err);
            setError(err);
        }
    }
    const RenderContent = () => {
        if (loading)
            return <LoadingScreen />;
        if (error !== null)
            return <ErrorContent error={error} />
        return <form className="card-body row g-3">

            <div className="col-md-8 py-1">
                <label htmlFor="form-title" className="form-label">BAŞLIK</label>
                <input type="text" className="form-control" id="form-title" value={data.title} onChange={e => setCustomData("title", e.target.value)} />
            </div>
            <div className="col-md-4 py-1">
                <label htmlFor="form-status" className="form-label">DURUM</label>
                <select id="form-status" className="form-select" defaultValue={data.status} onChange={e => setCustomData("status", e.target.value)}>
                    {
                        Object.values(status()).map((value, index) => (
                            <option key={"status" + index} value={index}>{status(value)}</option>
                        ))
                    }
                </select>
            </div>

            <div className="col-md-8 py-1">
                <table className="table">
                    <thead><tr><th>Bağlantı</th></tr></thead>
                    <tbody>
                        {data.slugsList
                            .sort((a, b) => { return b.createdAt.seconds - a.createdAt.seconds; })
                            .map((item, index) => {
                                return <tr scope="row" key={"slug_" + index}><td>
                                    <span className="badge text-bg-light">{new Date(item.createdAt.seconds * 1000).toLocaleString("tr")}</span>
                                    <span className={index == 0 ? "badge text-bg-success" : "badge text-bg-secondary"}>{item.value}</span>

                                </td></tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="col-12">
                <LoadingButton onClick={onPressForm} Text="KAYDET" />
            </div>
        </form >
    };
    useEffect(() => {
        loadScreen();
    }, []);
    return <div className="container">
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <Breadcrumbs data={breadcrumbData} />
            </div>
            {RenderContent()}
        </div>
    </div>;
}

export default AreaEdit;