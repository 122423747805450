/**
 * @fileoverview gRPC-Web generated client stub for dash.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: dash/v1/lesson_book.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var dash_v1_models_pb = require('../../dash/v1/models_pb.js')

var types_v1_status_pb = require('../../types/v1/status_pb.js')
const proto = {};
proto.dash = {};
proto.dash.v1 = require('./lesson_book_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.LessonBookServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.LessonBookServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.GetLessonBookRequest,
 *   !proto.dash.v1.GetLessonBookResponse>}
 */
const methodDescriptor_LessonBookService_GetLessonBook = new grpc.web.MethodDescriptor(
  '/dash.v1.LessonBookService/GetLessonBook',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.GetLessonBookRequest,
  proto.dash.v1.GetLessonBookResponse,
  /**
   * @param {!proto.dash.v1.GetLessonBookRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.GetLessonBookResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.GetLessonBookRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.GetLessonBookResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.GetLessonBookResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.LessonBookServiceClient.prototype.getLessonBook =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.LessonBookService/GetLessonBook',
      request,
      metadata || {},
      methodDescriptor_LessonBookService_GetLessonBook,
      callback);
};


/**
 * @param {!proto.dash.v1.GetLessonBookRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.GetLessonBookResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.LessonBookServicePromiseClient.prototype.getLessonBook =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.LessonBookService/GetLessonBook',
      request,
      metadata || {},
      methodDescriptor_LessonBookService_GetLessonBook);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.PutLessonBookRequest,
 *   !proto.dash.v1.PutLessonBookResponse>}
 */
const methodDescriptor_LessonBookService_PutLessonBook = new grpc.web.MethodDescriptor(
  '/dash.v1.LessonBookService/PutLessonBook',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.PutLessonBookRequest,
  proto.dash.v1.PutLessonBookResponse,
  /**
   * @param {!proto.dash.v1.PutLessonBookRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.PutLessonBookResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.PutLessonBookRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.PutLessonBookResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.PutLessonBookResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.LessonBookServiceClient.prototype.putLessonBook =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.LessonBookService/PutLessonBook',
      request,
      metadata || {},
      methodDescriptor_LessonBookService_PutLessonBook,
      callback);
};


/**
 * @param {!proto.dash.v1.PutLessonBookRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.PutLessonBookResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.LessonBookServicePromiseClient.prototype.putLessonBook =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.LessonBookService/PutLessonBook',
      request,
      metadata || {},
      methodDescriptor_LessonBookService_PutLessonBook);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.ListLessonBooksRequest,
 *   !proto.dash.v1.ListLessonBooksResponse>}
 */
const methodDescriptor_LessonBookService_ListLessonBooks = new grpc.web.MethodDescriptor(
  '/dash.v1.LessonBookService/ListLessonBooks',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.ListLessonBooksRequest,
  proto.dash.v1.ListLessonBooksResponse,
  /**
   * @param {!proto.dash.v1.ListLessonBooksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.ListLessonBooksResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.ListLessonBooksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.ListLessonBooksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.ListLessonBooksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.LessonBookServiceClient.prototype.listLessonBooks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.LessonBookService/ListLessonBooks',
      request,
      metadata || {},
      methodDescriptor_LessonBookService_ListLessonBooks,
      callback);
};


/**
 * @param {!proto.dash.v1.ListLessonBooksRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.ListLessonBooksResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.LessonBookServicePromiseClient.prototype.listLessonBooks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.LessonBookService/ListLessonBooks',
      request,
      metadata || {},
      methodDescriptor_LessonBookService_ListLessonBooks);
};


module.exports = proto.dash.v1;

