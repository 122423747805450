/**
 * @fileoverview gRPC-Web generated client stub for dash.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: dash/v1/calendar_event.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var dash_v1_models_pb = require('../../dash/v1/models_pb.js')

var types_v1_academic_year_pb = require('../../types/v1/academic_year_pb.js')

var types_v1_calendar_event_type_pb = require('../../types/v1/calendar_event_type_pb.js')

var types_v1_status_pb = require('../../types/v1/status_pb.js')
const proto = {};
proto.dash = {};
proto.dash.v1 = require('./calendar_event_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.CalendarEventServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.CalendarEventServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.GetCalendarEventRequest,
 *   !proto.dash.v1.GetCalendarEventResponse>}
 */
const methodDescriptor_CalendarEventService_GetCalendarEvent = new grpc.web.MethodDescriptor(
  '/dash.v1.CalendarEventService/GetCalendarEvent',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.GetCalendarEventRequest,
  proto.dash.v1.GetCalendarEventResponse,
  /**
   * @param {!proto.dash.v1.GetCalendarEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.GetCalendarEventResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.GetCalendarEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.GetCalendarEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.GetCalendarEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.CalendarEventServiceClient.prototype.getCalendarEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.CalendarEventService/GetCalendarEvent',
      request,
      metadata || {},
      methodDescriptor_CalendarEventService_GetCalendarEvent,
      callback);
};


/**
 * @param {!proto.dash.v1.GetCalendarEventRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.GetCalendarEventResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.CalendarEventServicePromiseClient.prototype.getCalendarEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.CalendarEventService/GetCalendarEvent',
      request,
      metadata || {},
      methodDescriptor_CalendarEventService_GetCalendarEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.PutCalendarEventRequest,
 *   !proto.dash.v1.PutCalendarEventResponse>}
 */
const methodDescriptor_CalendarEventService_PutCalendarEvent = new grpc.web.MethodDescriptor(
  '/dash.v1.CalendarEventService/PutCalendarEvent',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.PutCalendarEventRequest,
  proto.dash.v1.PutCalendarEventResponse,
  /**
   * @param {!proto.dash.v1.PutCalendarEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.PutCalendarEventResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.PutCalendarEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.PutCalendarEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.PutCalendarEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.CalendarEventServiceClient.prototype.putCalendarEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.CalendarEventService/PutCalendarEvent',
      request,
      metadata || {},
      methodDescriptor_CalendarEventService_PutCalendarEvent,
      callback);
};


/**
 * @param {!proto.dash.v1.PutCalendarEventRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.PutCalendarEventResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.CalendarEventServicePromiseClient.prototype.putCalendarEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.CalendarEventService/PutCalendarEvent',
      request,
      metadata || {},
      methodDescriptor_CalendarEventService_PutCalendarEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.ListCalendarEventsRequest,
 *   !proto.dash.v1.ListCalendarEventsResponse>}
 */
const methodDescriptor_CalendarEventService_ListCalendarEvents = new grpc.web.MethodDescriptor(
  '/dash.v1.CalendarEventService/ListCalendarEvents',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.ListCalendarEventsRequest,
  proto.dash.v1.ListCalendarEventsResponse,
  /**
   * @param {!proto.dash.v1.ListCalendarEventsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.ListCalendarEventsResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.ListCalendarEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.ListCalendarEventsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.ListCalendarEventsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.CalendarEventServiceClient.prototype.listCalendarEvents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.CalendarEventService/ListCalendarEvents',
      request,
      metadata || {},
      methodDescriptor_CalendarEventService_ListCalendarEvents,
      callback);
};


/**
 * @param {!proto.dash.v1.ListCalendarEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.ListCalendarEventsResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.CalendarEventServicePromiseClient.prototype.listCalendarEvents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.CalendarEventService/ListCalendarEvents',
      request,
      metadata || {},
      methodDescriptor_CalendarEventService_ListCalendarEvents);
};


module.exports = proto.dash.v1;

