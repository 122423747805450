import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

export default function Error404(props) {
  return (
    <main className="form-signin text-center">
        <h1 className="h3 mb-3 fw-normal">404</h1>
        <p>Sayfa Bulunamadı!</p>
      
        <div className="checkbox mb-3 mt-3">
            <label>
                <Link to="/">Anasayfa Sayfaya Dön</Link>
            </label>
        </div>
    </main>
  );
}
