// source: dash/v1/past_exam_booklet.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var dash_v1_models_pb = require('../../dash/v1/models_pb.js');
goog.object.extend(proto, dash_v1_models_pb);
var types_v1_academic_year_pb = require('../../types/v1/academic_year_pb.js');
goog.object.extend(proto, types_v1_academic_year_pb);
var types_v1_status_pb = require('../../types/v1/status_pb.js');
goog.object.extend(proto, types_v1_status_pb);
goog.exportSymbol('proto.dash.v1.GetPastExamBookletRequest', null, global);
goog.exportSymbol('proto.dash.v1.GetPastExamBookletResponse', null, global);
goog.exportSymbol('proto.dash.v1.ListPastExamBookletsRequest', null, global);
goog.exportSymbol('proto.dash.v1.ListPastExamBookletsResponse', null, global);
goog.exportSymbol('proto.dash.v1.ListPastExamBookletsResponse.ExamCount', null, global);
goog.exportSymbol('proto.dash.v1.PutPastExamBookletRequest', null, global);
goog.exportSymbol('proto.dash.v1.PutPastExamBookletResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.GetPastExamBookletRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.GetPastExamBookletRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.GetPastExamBookletRequest.displayName = 'proto.dash.v1.GetPastExamBookletRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.GetPastExamBookletResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.GetPastExamBookletResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.GetPastExamBookletResponse.displayName = 'proto.dash.v1.GetPastExamBookletResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.PutPastExamBookletRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.PutPastExamBookletRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.PutPastExamBookletRequest.displayName = 'proto.dash.v1.PutPastExamBookletRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.PutPastExamBookletResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.PutPastExamBookletResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.PutPastExamBookletResponse.displayName = 'proto.dash.v1.PutPastExamBookletResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.ListPastExamBookletsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.ListPastExamBookletsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.ListPastExamBookletsRequest.displayName = 'proto.dash.v1.ListPastExamBookletsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.ListPastExamBookletsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dash.v1.ListPastExamBookletsResponse.repeatedFields_, null);
};
goog.inherits(proto.dash.v1.ListPastExamBookletsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.ListPastExamBookletsResponse.displayName = 'proto.dash.v1.ListPastExamBookletsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.ListPastExamBookletsResponse.ExamCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.ListPastExamBookletsResponse.ExamCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.ListPastExamBookletsResponse.ExamCount.displayName = 'proto.dash.v1.ListPastExamBookletsResponse.ExamCount';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.GetPastExamBookletRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.GetPastExamBookletRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.GetPastExamBookletRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.GetPastExamBookletRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.GetPastExamBookletRequest}
 */
proto.dash.v1.GetPastExamBookletRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.GetPastExamBookletRequest;
  return proto.dash.v1.GetPastExamBookletRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.GetPastExamBookletRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.GetPastExamBookletRequest}
 */
proto.dash.v1.GetPastExamBookletRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.GetPastExamBookletRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.GetPastExamBookletRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.GetPastExamBookletRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.GetPastExamBookletRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.GetPastExamBookletRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.GetPastExamBookletRequest} returns this
 */
proto.dash.v1.GetPastExamBookletRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.GetPastExamBookletResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.GetPastExamBookletResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.GetPastExamBookletResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.GetPastExamBookletResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    booklet: (f = msg.getBooklet()) && dash_v1_models_pb.PastExamBooklet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.GetPastExamBookletResponse}
 */
proto.dash.v1.GetPastExamBookletResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.GetPastExamBookletResponse;
  return proto.dash.v1.GetPastExamBookletResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.GetPastExamBookletResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.GetPastExamBookletResponse}
 */
proto.dash.v1.GetPastExamBookletResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dash_v1_models_pb.PastExamBooklet;
      reader.readMessage(value,dash_v1_models_pb.PastExamBooklet.deserializeBinaryFromReader);
      msg.setBooklet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.GetPastExamBookletResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.GetPastExamBookletResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.GetPastExamBookletResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.GetPastExamBookletResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooklet();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dash_v1_models_pb.PastExamBooklet.serializeBinaryToWriter
    );
  }
};


/**
 * optional PastExamBooklet booklet = 1;
 * @return {?proto.dash.v1.PastExamBooklet}
 */
proto.dash.v1.GetPastExamBookletResponse.prototype.getBooklet = function() {
  return /** @type{?proto.dash.v1.PastExamBooklet} */ (
    jspb.Message.getWrapperField(this, dash_v1_models_pb.PastExamBooklet, 1));
};


/**
 * @param {?proto.dash.v1.PastExamBooklet|undefined} value
 * @return {!proto.dash.v1.GetPastExamBookletResponse} returns this
*/
proto.dash.v1.GetPastExamBookletResponse.prototype.setBooklet = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.GetPastExamBookletResponse} returns this
 */
proto.dash.v1.GetPastExamBookletResponse.prototype.clearBooklet = function() {
  return this.setBooklet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.GetPastExamBookletResponse.prototype.hasBooklet = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.PutPastExamBookletRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.PutPastExamBookletRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.PutPastExamBookletRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutPastExamBookletRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    booklet: (f = msg.getBooklet()) && dash_v1_models_pb.PastExamBooklet.toObject(includeInstance, f),
    file: (f = msg.getFile()) && dash_v1_models_pb.File.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.PutPastExamBookletRequest}
 */
proto.dash.v1.PutPastExamBookletRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.PutPastExamBookletRequest;
  return proto.dash.v1.PutPastExamBookletRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.PutPastExamBookletRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.PutPastExamBookletRequest}
 */
proto.dash.v1.PutPastExamBookletRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dash_v1_models_pb.PastExamBooklet;
      reader.readMessage(value,dash_v1_models_pb.PastExamBooklet.deserializeBinaryFromReader);
      msg.setBooklet(value);
      break;
    case 2:
      var value = new dash_v1_models_pb.File;
      reader.readMessage(value,dash_v1_models_pb.File.deserializeBinaryFromReader);
      msg.setFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.PutPastExamBookletRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.PutPastExamBookletRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.PutPastExamBookletRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutPastExamBookletRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooklet();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dash_v1_models_pb.PastExamBooklet.serializeBinaryToWriter
    );
  }
  f = message.getFile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      dash_v1_models_pb.File.serializeBinaryToWriter
    );
  }
};


/**
 * optional PastExamBooklet booklet = 1;
 * @return {?proto.dash.v1.PastExamBooklet}
 */
proto.dash.v1.PutPastExamBookletRequest.prototype.getBooklet = function() {
  return /** @type{?proto.dash.v1.PastExamBooklet} */ (
    jspb.Message.getWrapperField(this, dash_v1_models_pb.PastExamBooklet, 1));
};


/**
 * @param {?proto.dash.v1.PastExamBooklet|undefined} value
 * @return {!proto.dash.v1.PutPastExamBookletRequest} returns this
*/
proto.dash.v1.PutPastExamBookletRequest.prototype.setBooklet = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PutPastExamBookletRequest} returns this
 */
proto.dash.v1.PutPastExamBookletRequest.prototype.clearBooklet = function() {
  return this.setBooklet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PutPastExamBookletRequest.prototype.hasBooklet = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional File file = 2;
 * @return {?proto.dash.v1.File}
 */
proto.dash.v1.PutPastExamBookletRequest.prototype.getFile = function() {
  return /** @type{?proto.dash.v1.File} */ (
    jspb.Message.getWrapperField(this, dash_v1_models_pb.File, 2));
};


/**
 * @param {?proto.dash.v1.File|undefined} value
 * @return {!proto.dash.v1.PutPastExamBookletRequest} returns this
*/
proto.dash.v1.PutPastExamBookletRequest.prototype.setFile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PutPastExamBookletRequest} returns this
 */
proto.dash.v1.PutPastExamBookletRequest.prototype.clearFile = function() {
  return this.setFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PutPastExamBookletRequest.prototype.hasFile = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.PutPastExamBookletResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.PutPastExamBookletResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.PutPastExamBookletResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutPastExamBookletResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    booklet: (f = msg.getBooklet()) && dash_v1_models_pb.PastExamBooklet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.PutPastExamBookletResponse}
 */
proto.dash.v1.PutPastExamBookletResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.PutPastExamBookletResponse;
  return proto.dash.v1.PutPastExamBookletResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.PutPastExamBookletResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.PutPastExamBookletResponse}
 */
proto.dash.v1.PutPastExamBookletResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dash_v1_models_pb.PastExamBooklet;
      reader.readMessage(value,dash_v1_models_pb.PastExamBooklet.deserializeBinaryFromReader);
      msg.setBooklet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.PutPastExamBookletResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.PutPastExamBookletResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.PutPastExamBookletResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutPastExamBookletResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooklet();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dash_v1_models_pb.PastExamBooklet.serializeBinaryToWriter
    );
  }
};


/**
 * optional PastExamBooklet booklet = 1;
 * @return {?proto.dash.v1.PastExamBooklet}
 */
proto.dash.v1.PutPastExamBookletResponse.prototype.getBooklet = function() {
  return /** @type{?proto.dash.v1.PastExamBooklet} */ (
    jspb.Message.getWrapperField(this, dash_v1_models_pb.PastExamBooklet, 1));
};


/**
 * @param {?proto.dash.v1.PastExamBooklet|undefined} value
 * @return {!proto.dash.v1.PutPastExamBookletResponse} returns this
*/
proto.dash.v1.PutPastExamBookletResponse.prototype.setBooklet = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PutPastExamBookletResponse} returns this
 */
proto.dash.v1.PutPastExamBookletResponse.prototype.clearBooklet = function() {
  return this.setBooklet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PutPastExamBookletResponse.prototype.hasBooklet = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.ListPastExamBookletsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.ListPastExamBookletsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.ListPastExamBookletsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListPastExamBookletsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    areaId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    examGroupId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    examTypeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    academicYear: jspb.Message.getFieldWithDefault(msg, 4, 0),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.ListPastExamBookletsRequest}
 */
proto.dash.v1.ListPastExamBookletsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.ListPastExamBookletsRequest;
  return proto.dash.v1.ListPastExamBookletsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.ListPastExamBookletsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.ListPastExamBookletsRequest}
 */
proto.dash.v1.ListPastExamBookletsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAreaId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExamGroupId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExamTypeId(value);
      break;
    case 4:
      var value = /** @type {!proto.types.v1.AcademicYear} */ (reader.readEnum());
      msg.setAcademicYear(value);
      break;
    case 5:
      var value = /** @type {!proto.types.v1.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.ListPastExamBookletsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.ListPastExamBookletsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.ListPastExamBookletsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListPastExamBookletsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAreaId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getExamGroupId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getExamTypeId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAcademicYear();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int32 area_id = 1;
 * @return {number}
 */
proto.dash.v1.ListPastExamBookletsRequest.prototype.getAreaId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.ListPastExamBookletsRequest} returns this
 */
proto.dash.v1.ListPastExamBookletsRequest.prototype.setAreaId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 exam_group_id = 2;
 * @return {number}
 */
proto.dash.v1.ListPastExamBookletsRequest.prototype.getExamGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.ListPastExamBookletsRequest} returns this
 */
proto.dash.v1.ListPastExamBookletsRequest.prototype.setExamGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 exam_type_id = 3;
 * @return {number}
 */
proto.dash.v1.ListPastExamBookletsRequest.prototype.getExamTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.ListPastExamBookletsRequest} returns this
 */
proto.dash.v1.ListPastExamBookletsRequest.prototype.setExamTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional types.v1.AcademicYear academic_year = 4;
 * @return {!proto.types.v1.AcademicYear}
 */
proto.dash.v1.ListPastExamBookletsRequest.prototype.getAcademicYear = function() {
  return /** @type {!proto.types.v1.AcademicYear} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.types.v1.AcademicYear} value
 * @return {!proto.dash.v1.ListPastExamBookletsRequest} returns this
 */
proto.dash.v1.ListPastExamBookletsRequest.prototype.setAcademicYear = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional types.v1.Status status = 5;
 * @return {!proto.types.v1.Status}
 */
proto.dash.v1.ListPastExamBookletsRequest.prototype.getStatus = function() {
  return /** @type {!proto.types.v1.Status} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.types.v1.Status} value
 * @return {!proto.dash.v1.ListPastExamBookletsRequest} returns this
 */
proto.dash.v1.ListPastExamBookletsRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dash.v1.ListPastExamBookletsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.ListPastExamBookletsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.ListPastExamBookletsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.ListPastExamBookletsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListPastExamBookletsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookletsList: jspb.Message.toObjectList(msg.getBookletsList(),
    dash_v1_models_pb.PastExamBooklet.toObject, includeInstance),
    examCountsList: jspb.Message.toObjectList(msg.getExamCountsList(),
    proto.dash.v1.ListPastExamBookletsResponse.ExamCount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.ListPastExamBookletsResponse}
 */
proto.dash.v1.ListPastExamBookletsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.ListPastExamBookletsResponse;
  return proto.dash.v1.ListPastExamBookletsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.ListPastExamBookletsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.ListPastExamBookletsResponse}
 */
proto.dash.v1.ListPastExamBookletsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dash_v1_models_pb.PastExamBooklet;
      reader.readMessage(value,dash_v1_models_pb.PastExamBooklet.deserializeBinaryFromReader);
      msg.addBooklets(value);
      break;
    case 2:
      var value = new proto.dash.v1.ListPastExamBookletsResponse.ExamCount;
      reader.readMessage(value,proto.dash.v1.ListPastExamBookletsResponse.ExamCount.deserializeBinaryFromReader);
      msg.addExamCounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.ListPastExamBookletsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.ListPastExamBookletsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.ListPastExamBookletsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListPastExamBookletsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookletsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      dash_v1_models_pb.PastExamBooklet.serializeBinaryToWriter
    );
  }
  f = message.getExamCountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.dash.v1.ListPastExamBookletsResponse.ExamCount.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.ListPastExamBookletsResponse.ExamCount.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.ListPastExamBookletsResponse.ExamCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.ListPastExamBookletsResponse.ExamCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListPastExamBookletsResponse.ExamCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookletId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.ListPastExamBookletsResponse.ExamCount}
 */
proto.dash.v1.ListPastExamBookletsResponse.ExamCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.ListPastExamBookletsResponse.ExamCount;
  return proto.dash.v1.ListPastExamBookletsResponse.ExamCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.ListPastExamBookletsResponse.ExamCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.ListPastExamBookletsResponse.ExamCount}
 */
proto.dash.v1.ListPastExamBookletsResponse.ExamCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBookletId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.ListPastExamBookletsResponse.ExamCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.ListPastExamBookletsResponse.ExamCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.ListPastExamBookletsResponse.ExamCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListPastExamBookletsResponse.ExamCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookletId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 booklet_id = 1;
 * @return {number}
 */
proto.dash.v1.ListPastExamBookletsResponse.ExamCount.prototype.getBookletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.ListPastExamBookletsResponse.ExamCount} returns this
 */
proto.dash.v1.ListPastExamBookletsResponse.ExamCount.prototype.setBookletId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.dash.v1.ListPastExamBookletsResponse.ExamCount.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.ListPastExamBookletsResponse.ExamCount} returns this
 */
proto.dash.v1.ListPastExamBookletsResponse.ExamCount.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated PastExamBooklet booklets = 1;
 * @return {!Array<!proto.dash.v1.PastExamBooklet>}
 */
proto.dash.v1.ListPastExamBookletsResponse.prototype.getBookletsList = function() {
  return /** @type{!Array<!proto.dash.v1.PastExamBooklet>} */ (
    jspb.Message.getRepeatedWrapperField(this, dash_v1_models_pb.PastExamBooklet, 1));
};


/**
 * @param {!Array<!proto.dash.v1.PastExamBooklet>} value
 * @return {!proto.dash.v1.ListPastExamBookletsResponse} returns this
*/
proto.dash.v1.ListPastExamBookletsResponse.prototype.setBookletsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dash.v1.PastExamBooklet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dash.v1.PastExamBooklet}
 */
proto.dash.v1.ListPastExamBookletsResponse.prototype.addBooklets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dash.v1.PastExamBooklet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dash.v1.ListPastExamBookletsResponse} returns this
 */
proto.dash.v1.ListPastExamBookletsResponse.prototype.clearBookletsList = function() {
  return this.setBookletsList([]);
};


/**
 * repeated ExamCount exam_counts = 2;
 * @return {!Array<!proto.dash.v1.ListPastExamBookletsResponse.ExamCount>}
 */
proto.dash.v1.ListPastExamBookletsResponse.prototype.getExamCountsList = function() {
  return /** @type{!Array<!proto.dash.v1.ListPastExamBookletsResponse.ExamCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dash.v1.ListPastExamBookletsResponse.ExamCount, 2));
};


/**
 * @param {!Array<!proto.dash.v1.ListPastExamBookletsResponse.ExamCount>} value
 * @return {!proto.dash.v1.ListPastExamBookletsResponse} returns this
*/
proto.dash.v1.ListPastExamBookletsResponse.prototype.setExamCountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.dash.v1.ListPastExamBookletsResponse.ExamCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dash.v1.ListPastExamBookletsResponse.ExamCount}
 */
proto.dash.v1.ListPastExamBookletsResponse.prototype.addExamCounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.dash.v1.ListPastExamBookletsResponse.ExamCount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dash.v1.ListPastExamBookletsResponse} returns this
 */
proto.dash.v1.ListPastExamBookletsResponse.prototype.clearExamCountsList = function() {
  return this.setExamCountsList([]);
};


goog.object.extend(exports, proto.dash.v1);
