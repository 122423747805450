import React, { createContext, useState } from "react";
import { AuthStorage } from '../helpers/manager';
import { userServiceLogout } from "../api/user";



export const AuthContext = createContext();

const AuthContextProvider = (props) => {
    const [currentUser, setCurrentUser] = useState(AuthStorage.getCurrentUser());

    const signIn = async (currentUser, callback) => {
        setCurrentUser(AuthStorage.getCurrentUser());
        // AuthStorage.setCurrentUser(currentUser);
        callback();
    }

    const signOut = (callback) => {


        userServiceLogout().then(() => {

            setCurrentUser(null);
            AuthStorage.removeToken();
            callback();
        }).catch(() => {
            setCurrentUser(null);
            AuthStorage.removeToken();
            callback();
        })

        // setCurrentUser(null);
        // AuthStorage.removeToken();
        // callback();
    }
    //const currentUserSet = async (currentUser) => {
    //    setCurrentUser(currentUser);
    //    LocalStorageConfig.setCurrentUser(currentUser);
    //}



    const isAdmin = () => {
        return currentUser.roles.includes("Admin") || currentUser.roles.includes("SuperAdmin")
    }

    let value = { currentUser, signIn, signOut, isAdmin };
    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;