import logo from './logo.svg';
import './App.css';

import { Routes, Route, Outlet } from "react-router-dom";

import AuthContextProvider from './contexts/AuthContext';
import Error404 from './pages/errors/404';
import SignIn from './pages/SignIn';
import RequireAuth from './components/RequireAuth';

import Header from './components/Header';
import Home from './pages/Home';

import Areas from "./pages/areas";
import AreaEdit from "./pages/areas/Edit";
import PastExamBooklets from './pages/past_exam_booklets';
import PastExamBookletEdit from './pages/past_exam_booklets/Edit';
import Exams from './pages/exams';
import ExamCreate from './pages/exams/ExamCreate';
import ExamPreview from './pages/exams/ExamPreview';
import ExamTrim from './pages/exams/ExamTrim';

import ExamGroups from './pages/exam_groups';
import ExamGroupEdit from './pages/exam_groups/Edit';
import ExamTypes from './pages/exam_types';
import ExamTypeEdit from './pages/exam_types/Edit';
import LessonBooks from './pages/lesson_books';
import LessonBookEdit from './pages/lesson_books/Edit';
import Lessons from './pages/lessons';
import LessonEdit from './pages/lessons/Edit';
import Posts from './pages/posts';
import PostEdit from './pages/posts/Edit';
import CalendarEvents from './pages/calendar_events';



function Layout() {

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
}




function App() {
  return (
    <AuthContextProvider>
      <Routes>
        <Route path='*' element={<Error404 />} />
        <Route path="login" element={<SignIn />} />
        <Route element={<RequireAuth> <Layout /> </RequireAuth>}>
          <Route path="/" element={<Home />} />

          <Route path="areas">
            <Route path="" element={<Areas />} />
            <Route path="create" element={<AreaEdit />} />
            <Route path=":id" element={<AreaEdit />} />
          </Route>

          <Route path="calendar-events">
            <Route path="" element={<CalendarEvents />} />
          </Route>


          <Route path="past-exam-booklets">
            <Route path="" element={<PastExamBooklets />} />
            <Route path="create" element={<PastExamBookletEdit />} />
            <Route path=":id" element={<PastExamBookletEdit />} />
          </Route>
          <Route path="posts">
            <Route path="" element={<Posts />} />
            <Route path="create" element={<PostEdit />} />
            <Route path=":id" element={<PostEdit />} />
          </Route>

          <Route path="exam-groups">
            <Route path="" element={<ExamGroups />} />
            <Route path="create" element={<ExamGroupEdit />} />
            <Route path=":id" element={<ExamGroupEdit />} />
          </Route>

          <Route path="exam-types">
            <Route path="" element={<ExamTypes />} />
            <Route path="create" element={<ExamTypeEdit />} />
            <Route path=":id" element={<ExamGroupEdit />} />
          </Route>

          <Route path="lesson-books">
            <Route path="" element={<LessonBooks />} />
            <Route path="create" element={<LessonBookEdit />} />
            <Route path=":id" element={<LessonBookEdit />} />
          </Route>

          <Route path="lessons">
            <Route path="" element={<Lessons />} />
            <Route path="create" element={<LessonEdit />} />
            <Route path=":id" element={<LessonEdit />} />
          </Route>


          <Route path="exams">
            <Route path="" element={<Exams />} />
            <Route path="create" element={<ExamCreate />} />
            {/* <Route path=":id" element={<ExamEdit />} /> */}
            <Route path=":id/trim" element={<ExamTrim />} />
          </Route>

        </Route>
        <Route path="exams/:id/preview" element={<RequireAuth><ExamPreview /></RequireAuth>} />
      </Routes>
    </AuthContextProvider>
  );
}

export default App;
