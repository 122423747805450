import { userService, userClient, authClient } from './services';
import { InfoRequest, SignInUserRequest, RefreshTokenSignInUserRequest, GetActiveUserRequest, LogoutRequest } from '../grpc/auth/v1/user_pb';

import { AuthStorage } from '../helpers/manager';

// export const signInUserApi = (email, password) => authClient.signInUser(new SignInUserRequest().setEmail(email).setPassword(password));

export const refreshTokenSignInUserApi = (refreshToken) => authClient.refreshTokenSignInUser(new RefreshTokenSignInUserRequest().setRefreshToken(refreshToken));

export const getActiveUserApi = () => userClient.getActiveUser(new GetActiveUserRequest());

export const userServiceInfo = () => userService.info(new InfoRequest());

export const userServiceLogout = () => userService.logout(new LogoutRequest());