import { calendarEventService } from './services';
import { PutCalendarEventRequest, ListCalendarEventsRequest } from '../grpc/dash/v1/calendar_event_pb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';



export const calendarEventServiceListCalendarEvents = ({ areaId }) => calendarEventService.listCalendarEvents(new ListCalendarEventsRequest().setAreaId(areaId || 0));

export const calendarEventServicePutCalendarEvent = ({ id, areaId, status, eventType, title, description, startDate, endDate, examGroupId, examTypeId, academicYear }) => calendarEventService.putCalendarEvent(new PutCalendarEventRequest()
    .setId(id)
    .setAreaId(areaId)
    .setStatus(status)
    .setEventType(eventType)
    .setTitle(title)
    .setDescription(description)
    .setEventType(eventType)
    .setStartDate(
        startDate === undefined ? null : new Timestamp()
            .setSeconds(startDate.seconds)
            .setNanos(startDate.nanos)
    )
    .setEndDate(
        endDate === undefined ? null : new Timestamp()
            .setSeconds(endDate.seconds)
            .setNanos(endDate.nanos)
    )
    .setExamGroupId(examGroupId)
    .setExamTypeId(examTypeId)
    .setAcademicYear(academicYear)
);