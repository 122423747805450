import React, { useEffect, useState } from 'react';
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import LoadingButton from '../components/LoadingButton';
import { AuthStorage } from '../helpers/manager';

import { authServiceLogin } from '../api/auth';
import { userServiceInfo } from '../api/user';

export default function SignIn(props) {
  let authContext = React.useContext(AuthContext);
  let navigate = useNavigate();
  let location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');

  const onPressSigIn = async (event) => {
    event.preventDefault();

    setLoginLoading(true);

    try {
      const _response = await authServiceLogin(email, password);
      AuthStorage.setToken(_response.toObject());

      setLoginError(false);

      let userInfo = null;
      try {
        const responseUserInfo = await userServiceInfo();
        userInfo = responseUserInfo.toObject();
      } catch (err2) {
        if (err2.code && err2.message.includes('400 or 500')) {
          console.log('Unable to access server.');
        }
      }

      let from = location.state?.from?.pathname || "/";
      authContext.signIn(userInfo, () => navigate(from, { replace: true }));
    } catch (err) {
      console.log(err.message);
      setLoginError(true);
      setLoginErrorMessage(err.message);
    }
    setLoginLoading(false);
  };

  return (
    <div className='container'>

      <div className='row justify-content-md-center'>
        <div className='col-4 mt-5'>
          <div className="card">
            <div className="card-header">Giriş Yapın</div>
            <form className='card-body row g-3'>
              <div className='col-md-12'>
                <label htmlFor="form-email" className="form-label">Email</label>
                <input type="email" id='form-email' className="form-control" placeholder="name@example.com" value={email} onChange={event => setEmail(event.target.value)} />
              </div>
              <div className="col-12">
                <label htmlFor="form-password" className="form-label">Parola</label>
                <input type="password" id='form-password' className="form-control" placeholder="Parola" value={password} onChange={event => setPassword(event.target.value)} />
              </div>

              {
                loginError && !loginLoading ? (
                  <div className='col-12'>
                    <div className="alert alert-danger" role="alert">
                      {loginErrorMessage}
                    </div>
                  </div>
                ) : null
              }

              <div className='col-6'>
                <LoadingButton isLoading={loginLoading} onClick={onPressSigIn} Text="Giriş Yap" />
              </div>
              <div className='col-6'>
                <Link to="/forget-my-password">Şifremi Unuttum?</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}