import { jwtDecode } from "jwt-decode";

export class AuthStorage {


    static setToken = ({ accessToken, accessTokenExpiration, refreshToken, refreshTokenExpiration }) => {
        localStorage.setItem("token", JSON.stringify({ accessToken, accessTokenExpiration, refreshToken, refreshTokenExpiration }));
    }

    static getToken = () => {
        let token = localStorage.getItem("token");
        if (token !== null) {
            token = JSON.parse(token);
            token.accessTokenExpiration = new Date(token.accessTokenExpiration.seconds * 1000)
            token.refreshTokenExpiration = new Date(token.refreshTokenExpiration.seconds * 1000)
            return token;
        } else {
            return null;
        }
    }

    static getDeviceId = () => {
        
        return this.getCurrentUser().deviceId ?? "";
    }

    static getAccessToken = () => {
        return this.getToken()?.accessToken ?? null;
    }

    static removeToken = () => {
        return localStorage.removeItem("token");
    }


    static getCurrentUser = () => {
        let token = this.getAccessToken();
        if (token === null) {
            return {}
        }
        const decodedHeader = jwtDecode(token) ?? {};
        if (decodedHeader.role === undefined)
            decodedHeader.role = [];
        else if (typeof decodedHeader.role === "string")
            decodedHeader.role = [decodedHeader.role]
        return {
            id: decodedHeader.id ?? "",
            name: decodedHeader.name ?? "",
            email: decodedHeader.email ?? "",
            deviceId: decodedHeader.deviceId ?? "",
            roles: (decodedHeader.role),
        }
    }
}