import { AuthStorage } from '../../helpers/manager';

// eslint-disable-next-line func-names
const AuthStreamInterceptor = function () {};

/** @override */
// eslint-disable-next-line func-names
AuthStreamInterceptor.prototype.intercept = function (request, invoker) {
  const meta = request.getMetadata();
  meta.authorization =  `Bearer ${AuthStorage.getToken().accessToken}`;
  return invoker(request);
};

export { AuthStreamInterceptor };
export default { AuthStreamInterceptor };
