// source: dash/v1/area.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var dash_v1_models_pb = require('../../dash/v1/models_pb.js');
goog.object.extend(proto, dash_v1_models_pb);
var types_v1_status_pb = require('../../types/v1/status_pb.js');
goog.object.extend(proto, types_v1_status_pb);
goog.exportSymbol('proto.dash.v1.GetAreaRequest', null, global);
goog.exportSymbol('proto.dash.v1.GetAreaResponse', null, global);
goog.exportSymbol('proto.dash.v1.ListAreasRequest', null, global);
goog.exportSymbol('proto.dash.v1.ListAreasResponse', null, global);
goog.exportSymbol('proto.dash.v1.PutAreaRequest', null, global);
goog.exportSymbol('proto.dash.v1.PutAreaResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.GetAreaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.GetAreaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.GetAreaRequest.displayName = 'proto.dash.v1.GetAreaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.GetAreaResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.GetAreaResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.GetAreaResponse.displayName = 'proto.dash.v1.GetAreaResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.PutAreaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.PutAreaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.PutAreaRequest.displayName = 'proto.dash.v1.PutAreaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.PutAreaResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.PutAreaResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.PutAreaResponse.displayName = 'proto.dash.v1.PutAreaResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.ListAreasRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.ListAreasRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.ListAreasRequest.displayName = 'proto.dash.v1.ListAreasRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.ListAreasResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dash.v1.ListAreasResponse.repeatedFields_, null);
};
goog.inherits(proto.dash.v1.ListAreasResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.ListAreasResponse.displayName = 'proto.dash.v1.ListAreasResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.GetAreaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.GetAreaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.GetAreaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.GetAreaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.GetAreaRequest}
 */
proto.dash.v1.GetAreaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.GetAreaRequest;
  return proto.dash.v1.GetAreaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.GetAreaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.GetAreaRequest}
 */
proto.dash.v1.GetAreaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.GetAreaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.GetAreaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.GetAreaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.GetAreaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.GetAreaRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.GetAreaRequest} returns this
 */
proto.dash.v1.GetAreaRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.GetAreaResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.GetAreaResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.GetAreaResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.GetAreaResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    area: (f = msg.getArea()) && dash_v1_models_pb.Area.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.GetAreaResponse}
 */
proto.dash.v1.GetAreaResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.GetAreaResponse;
  return proto.dash.v1.GetAreaResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.GetAreaResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.GetAreaResponse}
 */
proto.dash.v1.GetAreaResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dash_v1_models_pb.Area;
      reader.readMessage(value,dash_v1_models_pb.Area.deserializeBinaryFromReader);
      msg.setArea(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.GetAreaResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.GetAreaResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.GetAreaResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.GetAreaResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArea();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dash_v1_models_pb.Area.serializeBinaryToWriter
    );
  }
};


/**
 * optional Area area = 1;
 * @return {?proto.dash.v1.Area}
 */
proto.dash.v1.GetAreaResponse.prototype.getArea = function() {
  return /** @type{?proto.dash.v1.Area} */ (
    jspb.Message.getWrapperField(this, dash_v1_models_pb.Area, 1));
};


/**
 * @param {?proto.dash.v1.Area|undefined} value
 * @return {!proto.dash.v1.GetAreaResponse} returns this
*/
proto.dash.v1.GetAreaResponse.prototype.setArea = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.GetAreaResponse} returns this
 */
proto.dash.v1.GetAreaResponse.prototype.clearArea = function() {
  return this.setArea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.GetAreaResponse.prototype.hasArea = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.PutAreaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.PutAreaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.PutAreaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutAreaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.PutAreaRequest}
 */
proto.dash.v1.PutAreaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.PutAreaRequest;
  return proto.dash.v1.PutAreaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.PutAreaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.PutAreaRequest}
 */
proto.dash.v1.PutAreaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {!proto.types.v1.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.PutAreaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.PutAreaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.PutAreaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutAreaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.PutAreaRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.PutAreaRequest} returns this
 */
proto.dash.v1.PutAreaRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.dash.v1.PutAreaRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dash.v1.PutAreaRequest} returns this
 */
proto.dash.v1.PutAreaRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional types.v1.Status status = 3;
 * @return {!proto.types.v1.Status}
 */
proto.dash.v1.PutAreaRequest.prototype.getStatus = function() {
  return /** @type {!proto.types.v1.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.types.v1.Status} value
 * @return {!proto.dash.v1.PutAreaRequest} returns this
 */
proto.dash.v1.PutAreaRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.PutAreaResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.PutAreaResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.PutAreaResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutAreaResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    area: (f = msg.getArea()) && dash_v1_models_pb.Area.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.PutAreaResponse}
 */
proto.dash.v1.PutAreaResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.PutAreaResponse;
  return proto.dash.v1.PutAreaResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.PutAreaResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.PutAreaResponse}
 */
proto.dash.v1.PutAreaResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dash_v1_models_pb.Area;
      reader.readMessage(value,dash_v1_models_pb.Area.deserializeBinaryFromReader);
      msg.setArea(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.PutAreaResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.PutAreaResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.PutAreaResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutAreaResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArea();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dash_v1_models_pb.Area.serializeBinaryToWriter
    );
  }
};


/**
 * optional Area area = 1;
 * @return {?proto.dash.v1.Area}
 */
proto.dash.v1.PutAreaResponse.prototype.getArea = function() {
  return /** @type{?proto.dash.v1.Area} */ (
    jspb.Message.getWrapperField(this, dash_v1_models_pb.Area, 1));
};


/**
 * @param {?proto.dash.v1.Area|undefined} value
 * @return {!proto.dash.v1.PutAreaResponse} returns this
*/
proto.dash.v1.PutAreaResponse.prototype.setArea = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PutAreaResponse} returns this
 */
proto.dash.v1.PutAreaResponse.prototype.clearArea = function() {
  return this.setArea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PutAreaResponse.prototype.hasArea = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.ListAreasRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.ListAreasRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.ListAreasRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListAreasRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.ListAreasRequest}
 */
proto.dash.v1.ListAreasRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.ListAreasRequest;
  return proto.dash.v1.ListAreasRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.ListAreasRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.ListAreasRequest}
 */
proto.dash.v1.ListAreasRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.types.v1.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.ListAreasRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.ListAreasRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.ListAreasRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListAreasRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional types.v1.Status status = 1;
 * @return {!proto.types.v1.Status}
 */
proto.dash.v1.ListAreasRequest.prototype.getStatus = function() {
  return /** @type {!proto.types.v1.Status} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.types.v1.Status} value
 * @return {!proto.dash.v1.ListAreasRequest} returns this
 */
proto.dash.v1.ListAreasRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dash.v1.ListAreasResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.ListAreasResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.ListAreasResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.ListAreasResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListAreasResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    areasList: jspb.Message.toObjectList(msg.getAreasList(),
    dash_v1_models_pb.Area.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.ListAreasResponse}
 */
proto.dash.v1.ListAreasResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.ListAreasResponse;
  return proto.dash.v1.ListAreasResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.ListAreasResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.ListAreasResponse}
 */
proto.dash.v1.ListAreasResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dash_v1_models_pb.Area;
      reader.readMessage(value,dash_v1_models_pb.Area.deserializeBinaryFromReader);
      msg.addAreas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.ListAreasResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.ListAreasResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.ListAreasResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListAreasResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAreasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      dash_v1_models_pb.Area.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Area areas = 1;
 * @return {!Array<!proto.dash.v1.Area>}
 */
proto.dash.v1.ListAreasResponse.prototype.getAreasList = function() {
  return /** @type{!Array<!proto.dash.v1.Area>} */ (
    jspb.Message.getRepeatedWrapperField(this, dash_v1_models_pb.Area, 1));
};


/**
 * @param {!Array<!proto.dash.v1.Area>} value
 * @return {!proto.dash.v1.ListAreasResponse} returns this
*/
proto.dash.v1.ListAreasResponse.prototype.setAreasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dash.v1.Area=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dash.v1.Area}
 */
proto.dash.v1.ListAreasResponse.prototype.addAreas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dash.v1.Area, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dash.v1.ListAreasResponse} returns this
 */
proto.dash.v1.ListAreasResponse.prototype.clearAreasList = function() {
  return this.setAreasList([]);
};


goog.object.extend(exports, proto.dash.v1);
