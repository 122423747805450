import $, { event } from 'jquery';
import React, { useEffect, useRef, useState } from 'react';
import './css/imgAreaSelect.css';
import './js/imgAreaSelect.js';


const Marker = ({ width = 0, height = 0, pages = [], resolution = undefined, col = 12, mark, markIndex, setMark }) => {
    const [isProcessing, setIsProcessing] = useState(false);




    // const markRate = () => {

    //     console.log(resolution, mark.resolution == undefined && mark.resolution == 0 ? resolution : mark.resolution);
    //     if (mark.resolution === undefined && mark.resolution == 0)
    //         return 1;
    //     return Math.ceil(resolution / mark.resolution)
    // }

    // console.log("mark rate", markRate())




    // const rate = Math.ceil(width / (window.innerWidth / 12 * col * 0.98));
    const rate = width / (window.innerWidth / 12 * col * 0.9);
    const imageRef = useRef(null);
    const [preview, setPreview] = useState({
        pageIndex: 0,
        mark: { width: 0, height: 0, x: 0, y: 0, page: 0, handcrafted: false }
    });

    const setImgAreaSelect = (width, height, x, y) => {

        let markRate = resolution / (mark.resolution == undefined || mark.resolution == 0 ? resolution : mark.resolution);
        $(imageRef.current).imgAreaSelect({
            x1: x / rate * markRate || 0,
            y1: y / rate * markRate || 0,
            x2: (x + width) / rate * markRate || 0,
            y2: (y + height) / rate * markRate || 0,
        })
    };

    useEffect(() => {
        if (preview.mark.page - 1 == preview.pageIndex) {
            setImgAreaSelect(
                preview.mark.width,
                preview.mark.height,
                preview.mark.x,
                preview.mark.y
            );
        } else {
            setImgAreaSelect(0, 0, 0, 0);
        }
    }, [preview]);

    useEffect(() => {
        let pageIndex = (mark.page || 0) === 0 ? preview.pageIndex : mark.page - 1;
        setPreview({
            pageIndex: pageIndex,
            mark: {
                width: mark.width || 0,
                height: mark.height || 0,
                x: mark.x || 0,
                y: mark.y || 0,
                page: pageIndex + 1
            }
        })
    }, [mark]);

    const [trimMark, setTrimMark] = useState({
        width: 0,
        height: 0,
        x: 0,
        y: 0,
    });

    useEffect(() => {
        // seçim alanını otomatik temizlemek için
        //fetchTrimMark()
        setMark(markIndex, {
            x: parseInt(trimMark.x),
            y: parseInt(trimMark.y),
            width: parseInt(trimMark.width),
            height: parseInt(trimMark.height),
            page: preview.pageIndex + 1,
            resolution: resolution,
            handcrafted: mark.handcrafted
        });

    }, [trimMark])

    useEffect(() => {
        var area = $(imageRef.current).imgAreaSelect({
            handles: true,
            movable: false,
            resizable: true,
            instance: true,
            onSelectEnd: function (img, selection) {
                setTrimMark({
                    width: selection.width * rate,
                    height: selection.height * rate,
                    x: selection.x1 * rate,
                    y: selection.y1 * rate,
                });
            }
        });
        return () => {
            area.setOptions({ hide: true });
            area.update();
        }
    }, []);

    return <div className={`col-${col}`}>
        {
            isProcessing ? <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#00000050', zIndex: 99, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div className='spinner-grow text-primary' role='status'><span className='visually-hidden'>Loading...</span></div></div> : null
        }


        <div className='row'>
            <div className='col-1'>
                <input className="form-control form-control-sm" type="number" min={1} value={preview.pageIndex + 1} max={pages.length}
                    onFocus={(event) => {
                        event.target.select();
                    }}
                    onChange={(event) => {
                        setPreview({ ...preview, pageIndex: event.target.value - 1 })
                    }} />
            </div>
            <div className='col-2'>
                <nav>
                    <ul className="pagination pagination-sm justify-content-center">
                        <li className={preview.pageIndex == 0 ? `page-item disabled` : `page-item`}><a className="page-link" href='#' onClick={() => setPreview({ ...preview, pageIndex: 0 })}>{`|<`}</a></li>
                        <li className={preview.pageIndex == 0 ? `page-item disabled` : `page-item`}><a className="page-link" href='#' onClick={() => setPreview({ ...preview, pageIndex: preview.pageIndex - 1 })}>{`<`}</a></li>
                    </ul>
                </nav>
            </div>
            <div className='col-6'>
                <nav>
                    <ul className="pagination pagination-sm justify-content-center">
                        {

                            pages.map((link, index) => <li key={"page" + index} className={preview.pageIndex == index ? `page-item disabled` : `page-item`}><a className="page-link" href='#' onClick={() => setPreview({ ...preview, pageIndex: index })}>{index + 1}</a></li>)



                                .splice(preview.pageIndex >= pages.length - 3 ? pages.length - 7 : preview.pageIndex > 3 ? preview.pageIndex - 3 : 0, 7)
                        }
                    </ul>
                </nav>
            </div>
            <div className='col-2'>
                <ul className="pagination pagination-sm justify-content-center">
                    <li className={preview.pageIndex == pages.length - 1 ? `page-item disabled` : `page-item`}><a className="page-link" href='#' onClick={() => setPreview({ ...preview, pageIndex: preview.pageIndex + 1 })}>{`>`}</a></li>
                    <li className={preview.pageIndex == pages.length - 1 ? `page-item disabled` : `page-item`}><a className="page-link" href='#' onClick={() => setPreview({ ...preview, pageIndex: pages.length - 1 })}>{`>|`}</a></li>
                </ul>
            </div>
        </div>
        <div className='col-12 d-flex justify-content-center'>
            <img
                width={width / rate}
                height={height / rate}
                ref={imageRef}
                src={pages[preview.pageIndex]}
            />
        </div>
    </div>
}

export default Marker