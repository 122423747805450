import React from 'react';
import { Link } from "react-router-dom";

const Breadcrumbs = ({ data }) => {
    if (data === null || data === undefined)
        return <></>;
    return (
        <nav className="navbar navbar-expand-md">
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <ol className="breadcrumb me-auto mb-2 mb-md-0">
                    {
                        data.map((item, index) => {
                            if (item.url === "")
                                return <li className="breadcrumb-item active" aria-current="page" key={"bre_" + index}>{item.name}</li>
                            else
                                return <li className="breadcrumb-item" key={"bre_" + index}><Link to={item.url}>{item.name}</Link></li>
                        })
                    }
                </ol>
            </div>
        </nav>
    );
}
export default Breadcrumbs;