import React from 'react';
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate, NavLink } from 'react-router-dom';

export default function Header(props) {
    let navigate = useNavigate();
    let authContext = React.useContext(AuthContext);

    const onPressSignOut = () => {
        authContext.signOut(() => navigate("/"))
    }
    return (
        <header className="mb-3">
            <nav className="navbar navbar-expand-md navbar-light bg-light">

                <div className="container-fluid">
                    <NavLink to="/" className="navbar-brand">KİTAPLIK</NavLink>
                    <div className="collapse navbar-collapse" id="navbarCollapse">

                        <ul className="navbar-nav me-auto mb-2 mb-md-0">
                            {
                                authContext.isAdmin() ?
                                    <>
                                        <li className="nav-item">
                                            <NavLink to="/areas" className="nav-link" >ALANLAR</NavLink>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <NavLink className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">KİTAPÇIKLAR</NavLink>
                                            <ul className="dropdown-menu">
                                                <li><NavLink to="/past-exam-booklets" className="dropdown-item">GEÇMİŞ SINAVLAR</NavLink></li>
                                                <li><NavLink to="/lesson-books" className="dropdown-item">DERS KİTAPLARI</NavLink></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/posts" className="nav-link" >YAZILAR</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/calendar-events" className="nav-link" >TAKVİM</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/exam-groups" className="nav-link" >SINAV GRUPLARI</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/exam-types" className="nav-link" >SINAV TİPLERİ</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/lessons" className="nav-link" >DERSLER</NavLink>
                                        </li>
                                    </>
                                    : ""
                            }
                            <li className="nav-item">
                                <NavLink to="/exams" className="nav-link" >SINAVLAR</NavLink>
                            </li>
                        </ul>
                        <div className="dropdown">
                            <NavLink className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" id="navbarDropdown">


                                <span style={{ marginLeft: 5 }}>{authContext.currentUser.name}</span>

                            </NavLink>

                            <ul className="dropdown-menu dropdown-menu-end">
                                <li><button className="dropdown-item" type="button" onClick={onPressSignOut}>Çıkış Yap</button></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};
