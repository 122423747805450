import { postService } from './services';
import { GetPostRequest, PutPostRequest, ListPostsRequest } from '../grpc/dash/v1/post_pb';
import { Post } from '../grpc/dash/v1/models_pb';

export const postServiceGetPost = (id) => postService.getPost(new GetPostRequest().setId(id));

export const postServiceListPosts = ({ areaId }) => postService.listPosts(new ListPostsRequest().setAreaId(areaId || 0));

export const postServicePutPost = ({ id, areaId, title, description, content, postType, status, seoTitle, seoDescription }) => postService.putPost(new PutPostRequest()
    .setId(id)
    .setAreaId(areaId)
    .setTitle(title)
    .setDescription(description)
    .setContent(content)
    .setPostType(postType)
    .setStatus(status)
    .setSeoTitle(seoTitle)
    .setSeoDescription(seoDescription)
);