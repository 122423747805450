import React from 'react';

const LoadingButton = ({isLoading, onClick, Text}) => {
    if(isLoading){
        return (
            <button className="btn btn-primary" type="submit" disabled>
                <div className="spinner-border text-light spinner-border-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </button>
        );
    }
    else {
        return (
            <button className="btn btn-primary" type="submit" onClick={onClick}>{Text}</button>
        );
    }
};

export default LoadingButton;
