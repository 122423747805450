import { pastExamService } from './services';
import { GetPastExamRequest, PutPastExamRequest, TrimPastExamRequest, ListPastExamsRequest, CreatePastExamsRequest } from '../grpc/dash/v1/past_exam_pb';
import { PastExam, PastExamQuestion, Area, ExamGroup, ExamType, Lesson } from '../grpc/dash/v1/models_pb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';


export const pastExamServiceGetPastExam = (id) => pastExamService.getPastExam(new GetPastExamRequest().setId(id));

export const pastExamServiceListPastExams = ({ areaId, examGroupId, examTypeId, academicYear, lessonId, status }) => pastExamService.listPastExams(new ListPastExamsRequest()
    .setAreaId(areaId)
    .setExamGroupId(examGroupId)
    .setExamTypeId(examTypeId)
    .setLessonId(lessonId)
    .setAcademicYear(academicYear)
    .setStatus(status)
);

export const pastExamServicePutPastExam = (exam) => pastExamService.putPastExam(
    new PutPastExamRequest()
        .setPastExam(
            new PastExam()
                .setId(exam.id)
                .setStatus(exam.status)
        ));

export const pastExamServiceTrimPastExam = (id, questions) => pastExamService.trimPastExam(new TrimPastExamRequest()
    .setId(id)
    .setQuestionsList(
        questions.map((question) => {
            return new PastExamQuestion()
                .setId(question.id)
                .setExamId(question.examId)
                .setContentMark(
                    new PastExamQuestion.Mark()
                        .setX(question.contentMark.x)
                        .setY(question.contentMark.y)
                        .setWidth(question.contentMark.width)
                        .setHeight(question.contentMark.height)
                        .setPage(question.contentMark.page)
                        .setResolution(question.contentMark.resolution)
                        .setHandcrafted(question.contentMark.handcrafted ?? false)
                )
                .setHintMark(
                    new PastExamQuestion.Mark()
                        .setX(question.hintMark.x)
                        .setY(question.hintMark.y)
                        .setWidth(question.hintMark.width)
                        .setHeight(question.hintMark.height)
                        .setPage(question.hintMark.page)
                        .setResolution(question.hintMark.resolution)
                        .setHandcrafted(question.hintMark.handcrafted ?? false)
                )
                .setInfoMark(
                    new PastExamQuestion.Mark()
                        .setX(question.infoMark.x)
                        .setY(question.infoMark.y)
                        .setWidth(question.infoMark.width)
                        .setHeight(question.infoMark.height)
                        .setPage(question.infoMark.page)
                        .setResolution(question.infoMark.resolution)
                        .setHandcrafted(question.infoMark.handcrafted ?? false)
                )
                .setCorrect(question.correct)
                .setQuestionNumber(question.questionNumber)
        })
    ));

export const pastExamServiceCreatePastExams = (exams) => pastExamService.createPastExams(
    new CreatePastExamsRequest().setPastExamsList(
        exams.map(exam => new PastExam()
            .setBookletId(exam.bookletId)
            .setArea(exam.area ? new Area.Simple().setId(exam.area.id) : undefined)
            .setExamGroup(exam.examGroup ? new ExamGroup.Simple().setId(exam.examGroup.id) : undefined)
            .setExamType(exam.examType ? new ExamType.Simple().setId(exam.examType.id) : undefined)
            .setLesson(exam.lesson ? new Lesson.Simple().setId(exam.lesson.id) : undefined)
            .setAcademicYear(exam.academicYear)
            .setDate(exam.date ? new Timestamp().setSeconds(exam.date.seconds).setNanos(exam.date.nanos) : undefined)
        ))
);