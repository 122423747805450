import { examTypeService } from './services';
import { GetExamTypeRequest, PutExamTypeRequest, ListExamTypesRequest } from '../grpc/dash/v1/exam_type_pb';
import { ExamType } from '../grpc/dash/v1/models_pb';

export const examTypeServiceGetExamType = (id) => examTypeService.getExamType(new GetExamTypeRequest().setId(id));

export const examTypeServiceListExamTypes = ({ areaId }) => examTypeService.listExamTypes(new ListExamTypesRequest().setAreaId(areaId || 0));

export const examTypeServicePutExamType = (examType) => examTypeService.putExamType(new PutExamTypeRequest()
    .setExamType(
        new ExamType()
            .setId(examType.id)
            .setAreaId(examType.areaId)
            .setTitle(examType.title)
    ));