// source: dash/v1/past_exam.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var dash_v1_models_pb = require('../../dash/v1/models_pb.js');
goog.object.extend(proto, dash_v1_models_pb);
var types_v1_academic_year_pb = require('../../types/v1/academic_year_pb.js');
goog.object.extend(proto, types_v1_academic_year_pb);
var types_v1_exam_status_pb = require('../../types/v1/exam_status_pb.js');
goog.object.extend(proto, types_v1_exam_status_pb);
goog.exportSymbol('proto.dash.v1.CreatePastExamsRequest', null, global);
goog.exportSymbol('proto.dash.v1.CreatePastExamsResponse', null, global);
goog.exportSymbol('proto.dash.v1.GetPastExamRequest', null, global);
goog.exportSymbol('proto.dash.v1.GetPastExamResponse', null, global);
goog.exportSymbol('proto.dash.v1.ListPastExamsRequest', null, global);
goog.exportSymbol('proto.dash.v1.ListPastExamsResponse', null, global);
goog.exportSymbol('proto.dash.v1.PutPastExamRequest', null, global);
goog.exportSymbol('proto.dash.v1.PutPastExamResponse', null, global);
goog.exportSymbol('proto.dash.v1.TrimPastExamRequest', null, global);
goog.exportSymbol('proto.dash.v1.TrimPastExamResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.GetPastExamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.GetPastExamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.GetPastExamRequest.displayName = 'proto.dash.v1.GetPastExamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.GetPastExamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.GetPastExamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.GetPastExamResponse.displayName = 'proto.dash.v1.GetPastExamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.TrimPastExamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dash.v1.TrimPastExamRequest.repeatedFields_, null);
};
goog.inherits(proto.dash.v1.TrimPastExamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.TrimPastExamRequest.displayName = 'proto.dash.v1.TrimPastExamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.TrimPastExamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dash.v1.TrimPastExamResponse.repeatedFields_, null);
};
goog.inherits(proto.dash.v1.TrimPastExamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.TrimPastExamResponse.displayName = 'proto.dash.v1.TrimPastExamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.PutPastExamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.PutPastExamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.PutPastExamRequest.displayName = 'proto.dash.v1.PutPastExamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.PutPastExamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.PutPastExamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.PutPastExamResponse.displayName = 'proto.dash.v1.PutPastExamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.ListPastExamsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.ListPastExamsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.ListPastExamsRequest.displayName = 'proto.dash.v1.ListPastExamsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.ListPastExamsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dash.v1.ListPastExamsResponse.repeatedFields_, null);
};
goog.inherits(proto.dash.v1.ListPastExamsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.ListPastExamsResponse.displayName = 'proto.dash.v1.ListPastExamsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.CreatePastExamsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dash.v1.CreatePastExamsRequest.repeatedFields_, null);
};
goog.inherits(proto.dash.v1.CreatePastExamsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.CreatePastExamsRequest.displayName = 'proto.dash.v1.CreatePastExamsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dash.v1.CreatePastExamsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dash.v1.CreatePastExamsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dash.v1.CreatePastExamsResponse.displayName = 'proto.dash.v1.CreatePastExamsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.GetPastExamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.GetPastExamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.GetPastExamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.GetPastExamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.GetPastExamRequest}
 */
proto.dash.v1.GetPastExamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.GetPastExamRequest;
  return proto.dash.v1.GetPastExamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.GetPastExamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.GetPastExamRequest}
 */
proto.dash.v1.GetPastExamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.GetPastExamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.GetPastExamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.GetPastExamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.GetPastExamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.GetPastExamRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.GetPastExamRequest} returns this
 */
proto.dash.v1.GetPastExamRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.GetPastExamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.GetPastExamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.GetPastExamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.GetPastExamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pastExam: (f = msg.getPastExam()) && dash_v1_models_pb.PastExam.toObject(includeInstance, f),
    fileInfo: (f = msg.getFileInfo()) && dash_v1_models_pb.BookletFileInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.GetPastExamResponse}
 */
proto.dash.v1.GetPastExamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.GetPastExamResponse;
  return proto.dash.v1.GetPastExamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.GetPastExamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.GetPastExamResponse}
 */
proto.dash.v1.GetPastExamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dash_v1_models_pb.PastExam;
      reader.readMessage(value,dash_v1_models_pb.PastExam.deserializeBinaryFromReader);
      msg.setPastExam(value);
      break;
    case 2:
      var value = new dash_v1_models_pb.BookletFileInfo;
      reader.readMessage(value,dash_v1_models_pb.BookletFileInfo.deserializeBinaryFromReader);
      msg.setFileInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.GetPastExamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.GetPastExamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.GetPastExamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.GetPastExamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPastExam();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dash_v1_models_pb.PastExam.serializeBinaryToWriter
    );
  }
  f = message.getFileInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      dash_v1_models_pb.BookletFileInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional PastExam past_exam = 1;
 * @return {?proto.dash.v1.PastExam}
 */
proto.dash.v1.GetPastExamResponse.prototype.getPastExam = function() {
  return /** @type{?proto.dash.v1.PastExam} */ (
    jspb.Message.getWrapperField(this, dash_v1_models_pb.PastExam, 1));
};


/**
 * @param {?proto.dash.v1.PastExam|undefined} value
 * @return {!proto.dash.v1.GetPastExamResponse} returns this
*/
proto.dash.v1.GetPastExamResponse.prototype.setPastExam = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.GetPastExamResponse} returns this
 */
proto.dash.v1.GetPastExamResponse.prototype.clearPastExam = function() {
  return this.setPastExam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.GetPastExamResponse.prototype.hasPastExam = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BookletFileInfo file_info = 2;
 * @return {?proto.dash.v1.BookletFileInfo}
 */
proto.dash.v1.GetPastExamResponse.prototype.getFileInfo = function() {
  return /** @type{?proto.dash.v1.BookletFileInfo} */ (
    jspb.Message.getWrapperField(this, dash_v1_models_pb.BookletFileInfo, 2));
};


/**
 * @param {?proto.dash.v1.BookletFileInfo|undefined} value
 * @return {!proto.dash.v1.GetPastExamResponse} returns this
*/
proto.dash.v1.GetPastExamResponse.prototype.setFileInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.GetPastExamResponse} returns this
 */
proto.dash.v1.GetPastExamResponse.prototype.clearFileInfo = function() {
  return this.setFileInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.GetPastExamResponse.prototype.hasFileInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dash.v1.TrimPastExamRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.TrimPastExamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.TrimPastExamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.TrimPastExamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.TrimPastExamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    dash_v1_models_pb.PastExamQuestion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.TrimPastExamRequest}
 */
proto.dash.v1.TrimPastExamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.TrimPastExamRequest;
  return proto.dash.v1.TrimPastExamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.TrimPastExamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.TrimPastExamRequest}
 */
proto.dash.v1.TrimPastExamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new dash_v1_models_pb.PastExamQuestion;
      reader.readMessage(value,dash_v1_models_pb.PastExamQuestion.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.TrimPastExamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.TrimPastExamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.TrimPastExamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.TrimPastExamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      dash_v1_models_pb.PastExamQuestion.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.dash.v1.TrimPastExamRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.TrimPastExamRequest} returns this
 */
proto.dash.v1.TrimPastExamRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated PastExamQuestion questions = 2;
 * @return {!Array<!proto.dash.v1.PastExamQuestion>}
 */
proto.dash.v1.TrimPastExamRequest.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.dash.v1.PastExamQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, dash_v1_models_pb.PastExamQuestion, 2));
};


/**
 * @param {!Array<!proto.dash.v1.PastExamQuestion>} value
 * @return {!proto.dash.v1.TrimPastExamRequest} returns this
*/
proto.dash.v1.TrimPastExamRequest.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.dash.v1.PastExamQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dash.v1.PastExamQuestion}
 */
proto.dash.v1.TrimPastExamRequest.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.dash.v1.PastExamQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dash.v1.TrimPastExamRequest} returns this
 */
proto.dash.v1.TrimPastExamRequest.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dash.v1.TrimPastExamResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.TrimPastExamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.TrimPastExamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.TrimPastExamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.TrimPastExamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    dash_v1_models_pb.PastExamQuestion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.TrimPastExamResponse}
 */
proto.dash.v1.TrimPastExamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.TrimPastExamResponse;
  return proto.dash.v1.TrimPastExamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.TrimPastExamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.TrimPastExamResponse}
 */
proto.dash.v1.TrimPastExamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dash_v1_models_pb.PastExamQuestion;
      reader.readMessage(value,dash_v1_models_pb.PastExamQuestion.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.TrimPastExamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.TrimPastExamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.TrimPastExamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.TrimPastExamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      dash_v1_models_pb.PastExamQuestion.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PastExamQuestion questions = 1;
 * @return {!Array<!proto.dash.v1.PastExamQuestion>}
 */
proto.dash.v1.TrimPastExamResponse.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.dash.v1.PastExamQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, dash_v1_models_pb.PastExamQuestion, 1));
};


/**
 * @param {!Array<!proto.dash.v1.PastExamQuestion>} value
 * @return {!proto.dash.v1.TrimPastExamResponse} returns this
*/
proto.dash.v1.TrimPastExamResponse.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dash.v1.PastExamQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dash.v1.PastExamQuestion}
 */
proto.dash.v1.TrimPastExamResponse.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dash.v1.PastExamQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dash.v1.TrimPastExamResponse} returns this
 */
proto.dash.v1.TrimPastExamResponse.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.PutPastExamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.PutPastExamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.PutPastExamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutPastExamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pastExam: (f = msg.getPastExam()) && dash_v1_models_pb.PastExam.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.PutPastExamRequest}
 */
proto.dash.v1.PutPastExamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.PutPastExamRequest;
  return proto.dash.v1.PutPastExamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.PutPastExamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.PutPastExamRequest}
 */
proto.dash.v1.PutPastExamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dash_v1_models_pb.PastExam;
      reader.readMessage(value,dash_v1_models_pb.PastExam.deserializeBinaryFromReader);
      msg.setPastExam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.PutPastExamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.PutPastExamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.PutPastExamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutPastExamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPastExam();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dash_v1_models_pb.PastExam.serializeBinaryToWriter
    );
  }
};


/**
 * optional PastExam past_exam = 1;
 * @return {?proto.dash.v1.PastExam}
 */
proto.dash.v1.PutPastExamRequest.prototype.getPastExam = function() {
  return /** @type{?proto.dash.v1.PastExam} */ (
    jspb.Message.getWrapperField(this, dash_v1_models_pb.PastExam, 1));
};


/**
 * @param {?proto.dash.v1.PastExam|undefined} value
 * @return {!proto.dash.v1.PutPastExamRequest} returns this
*/
proto.dash.v1.PutPastExamRequest.prototype.setPastExam = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PutPastExamRequest} returns this
 */
proto.dash.v1.PutPastExamRequest.prototype.clearPastExam = function() {
  return this.setPastExam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PutPastExamRequest.prototype.hasPastExam = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.PutPastExamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.PutPastExamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.PutPastExamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutPastExamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pastExam: (f = msg.getPastExam()) && dash_v1_models_pb.PastExam.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.PutPastExamResponse}
 */
proto.dash.v1.PutPastExamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.PutPastExamResponse;
  return proto.dash.v1.PutPastExamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.PutPastExamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.PutPastExamResponse}
 */
proto.dash.v1.PutPastExamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dash_v1_models_pb.PastExam;
      reader.readMessage(value,dash_v1_models_pb.PastExam.deserializeBinaryFromReader);
      msg.setPastExam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.PutPastExamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.PutPastExamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.PutPastExamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.PutPastExamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPastExam();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dash_v1_models_pb.PastExam.serializeBinaryToWriter
    );
  }
};


/**
 * optional PastExam past_exam = 1;
 * @return {?proto.dash.v1.PastExam}
 */
proto.dash.v1.PutPastExamResponse.prototype.getPastExam = function() {
  return /** @type{?proto.dash.v1.PastExam} */ (
    jspb.Message.getWrapperField(this, dash_v1_models_pb.PastExam, 1));
};


/**
 * @param {?proto.dash.v1.PastExam|undefined} value
 * @return {!proto.dash.v1.PutPastExamResponse} returns this
*/
proto.dash.v1.PutPastExamResponse.prototype.setPastExam = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dash.v1.PutPastExamResponse} returns this
 */
proto.dash.v1.PutPastExamResponse.prototype.clearPastExam = function() {
  return this.setPastExam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dash.v1.PutPastExamResponse.prototype.hasPastExam = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.ListPastExamsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.ListPastExamsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.ListPastExamsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListPastExamsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    areaId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bookletId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    examGroupId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    examTypeId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    lessonId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    academicYear: jspb.Message.getFieldWithDefault(msg, 6, 0),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.ListPastExamsRequest}
 */
proto.dash.v1.ListPastExamsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.ListPastExamsRequest;
  return proto.dash.v1.ListPastExamsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.ListPastExamsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.ListPastExamsRequest}
 */
proto.dash.v1.ListPastExamsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAreaId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBookletId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExamGroupId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExamTypeId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLessonId(value);
      break;
    case 6:
      var value = /** @type {!proto.types.v1.AcademicYear} */ (reader.readEnum());
      msg.setAcademicYear(value);
      break;
    case 7:
      var value = /** @type {!proto.types.v1.ExamStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.ListPastExamsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.ListPastExamsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.ListPastExamsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListPastExamsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAreaId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBookletId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getExamGroupId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getExamTypeId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLessonId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getAcademicYear();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional int32 area_id = 1;
 * @return {number}
 */
proto.dash.v1.ListPastExamsRequest.prototype.getAreaId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.ListPastExamsRequest} returns this
 */
proto.dash.v1.ListPastExamsRequest.prototype.setAreaId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 booklet_id = 2;
 * @return {number}
 */
proto.dash.v1.ListPastExamsRequest.prototype.getBookletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.ListPastExamsRequest} returns this
 */
proto.dash.v1.ListPastExamsRequest.prototype.setBookletId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 exam_group_id = 3;
 * @return {number}
 */
proto.dash.v1.ListPastExamsRequest.prototype.getExamGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.ListPastExamsRequest} returns this
 */
proto.dash.v1.ListPastExamsRequest.prototype.setExamGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 exam_type_id = 4;
 * @return {number}
 */
proto.dash.v1.ListPastExamsRequest.prototype.getExamTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.ListPastExamsRequest} returns this
 */
proto.dash.v1.ListPastExamsRequest.prototype.setExamTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 lesson_id = 5;
 * @return {number}
 */
proto.dash.v1.ListPastExamsRequest.prototype.getLessonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dash.v1.ListPastExamsRequest} returns this
 */
proto.dash.v1.ListPastExamsRequest.prototype.setLessonId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional types.v1.AcademicYear academic_year = 6;
 * @return {!proto.types.v1.AcademicYear}
 */
proto.dash.v1.ListPastExamsRequest.prototype.getAcademicYear = function() {
  return /** @type {!proto.types.v1.AcademicYear} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.types.v1.AcademicYear} value
 * @return {!proto.dash.v1.ListPastExamsRequest} returns this
 */
proto.dash.v1.ListPastExamsRequest.prototype.setAcademicYear = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional types.v1.ExamStatus status = 7;
 * @return {!proto.types.v1.ExamStatus}
 */
proto.dash.v1.ListPastExamsRequest.prototype.getStatus = function() {
  return /** @type {!proto.types.v1.ExamStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.types.v1.ExamStatus} value
 * @return {!proto.dash.v1.ListPastExamsRequest} returns this
 */
proto.dash.v1.ListPastExamsRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dash.v1.ListPastExamsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.ListPastExamsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.ListPastExamsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.ListPastExamsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListPastExamsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pastExamsList: jspb.Message.toObjectList(msg.getPastExamsList(),
    dash_v1_models_pb.PastExam.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.ListPastExamsResponse}
 */
proto.dash.v1.ListPastExamsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.ListPastExamsResponse;
  return proto.dash.v1.ListPastExamsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.ListPastExamsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.ListPastExamsResponse}
 */
proto.dash.v1.ListPastExamsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dash_v1_models_pb.PastExam;
      reader.readMessage(value,dash_v1_models_pb.PastExam.deserializeBinaryFromReader);
      msg.addPastExams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.ListPastExamsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.ListPastExamsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.ListPastExamsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.ListPastExamsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPastExamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      dash_v1_models_pb.PastExam.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PastExam past_exams = 1;
 * @return {!Array<!proto.dash.v1.PastExam>}
 */
proto.dash.v1.ListPastExamsResponse.prototype.getPastExamsList = function() {
  return /** @type{!Array<!proto.dash.v1.PastExam>} */ (
    jspb.Message.getRepeatedWrapperField(this, dash_v1_models_pb.PastExam, 1));
};


/**
 * @param {!Array<!proto.dash.v1.PastExam>} value
 * @return {!proto.dash.v1.ListPastExamsResponse} returns this
*/
proto.dash.v1.ListPastExamsResponse.prototype.setPastExamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dash.v1.PastExam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dash.v1.PastExam}
 */
proto.dash.v1.ListPastExamsResponse.prototype.addPastExams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dash.v1.PastExam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dash.v1.ListPastExamsResponse} returns this
 */
proto.dash.v1.ListPastExamsResponse.prototype.clearPastExamsList = function() {
  return this.setPastExamsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dash.v1.CreatePastExamsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.CreatePastExamsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.CreatePastExamsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.CreatePastExamsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.CreatePastExamsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pastExamsList: jspb.Message.toObjectList(msg.getPastExamsList(),
    dash_v1_models_pb.PastExam.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.CreatePastExamsRequest}
 */
proto.dash.v1.CreatePastExamsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.CreatePastExamsRequest;
  return proto.dash.v1.CreatePastExamsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.CreatePastExamsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.CreatePastExamsRequest}
 */
proto.dash.v1.CreatePastExamsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dash_v1_models_pb.PastExam;
      reader.readMessage(value,dash_v1_models_pb.PastExam.deserializeBinaryFromReader);
      msg.addPastExams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.CreatePastExamsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.CreatePastExamsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.CreatePastExamsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.CreatePastExamsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPastExamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      dash_v1_models_pb.PastExam.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PastExam past_exams = 1;
 * @return {!Array<!proto.dash.v1.PastExam>}
 */
proto.dash.v1.CreatePastExamsRequest.prototype.getPastExamsList = function() {
  return /** @type{!Array<!proto.dash.v1.PastExam>} */ (
    jspb.Message.getRepeatedWrapperField(this, dash_v1_models_pb.PastExam, 1));
};


/**
 * @param {!Array<!proto.dash.v1.PastExam>} value
 * @return {!proto.dash.v1.CreatePastExamsRequest} returns this
*/
proto.dash.v1.CreatePastExamsRequest.prototype.setPastExamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dash.v1.PastExam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dash.v1.PastExam}
 */
proto.dash.v1.CreatePastExamsRequest.prototype.addPastExams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dash.v1.PastExam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dash.v1.CreatePastExamsRequest} returns this
 */
proto.dash.v1.CreatePastExamsRequest.prototype.clearPastExamsList = function() {
  return this.setPastExamsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dash.v1.CreatePastExamsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dash.v1.CreatePastExamsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dash.v1.CreatePastExamsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.CreatePastExamsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dash.v1.CreatePastExamsResponse}
 */
proto.dash.v1.CreatePastExamsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dash.v1.CreatePastExamsResponse;
  return proto.dash.v1.CreatePastExamsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dash.v1.CreatePastExamsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dash.v1.CreatePastExamsResponse}
 */
proto.dash.v1.CreatePastExamsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dash.v1.CreatePastExamsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dash.v1.CreatePastExamsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dash.v1.CreatePastExamsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dash.v1.CreatePastExamsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.dash.v1);
