import React from 'react';

const LoadingScreen = () => {
    return (
        <div style={{width:"100%", display:"flex", justifyContent: 'center'}}>
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
};

export default LoadingScreen;
