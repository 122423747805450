/**
 * @fileoverview gRPC-Web generated client stub for dash.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: dash/v1/faq.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var dash_v1_models_pb = require('../../dash/v1/models_pb.js')

var types_v1_entity_pb = require('../../types/v1/entity_pb.js')

var types_v1_status_pb = require('../../types/v1/status_pb.js')
const proto = {};
proto.dash = {};
proto.dash.v1 = require('./faq_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.FAQServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.FAQServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.PutFAQRequest,
 *   !proto.dash.v1.PutFAQResponse>}
 */
const methodDescriptor_FAQService_PutFAQ = new grpc.web.MethodDescriptor(
  '/dash.v1.FAQService/PutFAQ',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.PutFAQRequest,
  proto.dash.v1.PutFAQResponse,
  /**
   * @param {!proto.dash.v1.PutFAQRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.PutFAQResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.PutFAQRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.PutFAQResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.PutFAQResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.FAQServiceClient.prototype.putFAQ =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.FAQService/PutFAQ',
      request,
      metadata || {},
      methodDescriptor_FAQService_PutFAQ,
      callback);
};


/**
 * @param {!proto.dash.v1.PutFAQRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.PutFAQResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.FAQServicePromiseClient.prototype.putFAQ =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.FAQService/PutFAQ',
      request,
      metadata || {},
      methodDescriptor_FAQService_PutFAQ);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.ListFAQsRequest,
 *   !proto.dash.v1.ListFAQsResponse>}
 */
const methodDescriptor_FAQService_ListFAQs = new grpc.web.MethodDescriptor(
  '/dash.v1.FAQService/ListFAQs',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.ListFAQsRequest,
  proto.dash.v1.ListFAQsResponse,
  /**
   * @param {!proto.dash.v1.ListFAQsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.ListFAQsResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.ListFAQsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.ListFAQsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.ListFAQsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.FAQServiceClient.prototype.listFAQs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.FAQService/ListFAQs',
      request,
      metadata || {},
      methodDescriptor_FAQService_ListFAQs,
      callback);
};


/**
 * @param {!proto.dash.v1.ListFAQsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.ListFAQsResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.FAQServicePromiseClient.prototype.listFAQs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.FAQService/ListFAQs',
      request,
      metadata || {},
      methodDescriptor_FAQService_ListFAQs);
};


module.exports = proto.dash.v1;

