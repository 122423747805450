import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen.js';
import ErrorContent from '../../components/ErrorContent.js';
import NoContent from '../../components/NoContent.js';
import Breadcrumbs from '../../components/Breadcrumbs.js';
import { areaServiceListAreas } from '../../api/area.js';
import { pastExamBookletServiceListPastExamBooklets } from '../../api/past_exam_booklet.js';
import { examGroupServiceListExamGroups } from '../../api/exam_group.js';
import { examTypeServiceListExamTypes } from '../../api/exam_type.js';
import { lessonServiceListLessons } from '../../api/lesson.js';
import { academicYear } from '../../api/types.js';

function PastExamBooklets() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [examCounts, setExamCounts] = useState([]);
    const [breadcrumbData, setBreadcrumbData] = useState(null);

    const [areas, setAreas] = useState([]);
    const [examGroups, setExamGroups] = useState([]);
    const [examTypes, setExamTypes] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [formAreaId, setFormAreaId] = useState(parseInt(searchParams.get('areaId')) || "")
    const [formExamGroupId, setFormExamGroupId] = useState(parseInt(searchParams.get('examGroupId')) || "")
    const [formExamTypeId, setFormExamTypeId] = useState(parseInt(searchParams.get('examTypeId')) || "")
    const [formLessonId, setFormLessonId] = useState(parseInt(searchParams.get('lesson')) || "")
    const [formAcademicYear, setFormAcademicYear] = useState(parseInt(searchParams.get('academicYear')) || academicYear().ACADEMIC_YEAR_UNSPECIFIED)

    const loadScreen = async () => {
        try {
            await loadAreas();
            await loadExamGroups();
            await loadExamTypes();
            await loadLessons();
            await loadBooklets();
            setLoading(false);
        } catch (err) {
            console.log(err);
            setError(err);
            setLoading(false);
        }
    };

    const loadAreas = async () => {
        const response = await areaServiceListAreas();
        setAreas(response.toObject().areasList);
    }

    const loadBooklets = async () => {
        const response = await pastExamBookletServiceListPastExamBooklets({ areaId: formAreaId, examGroupId: formExamGroupId, examTypeId: formExamTypeId, academicYear: formAcademicYear });
        const object = response.toObject();
        setData(object.bookletsList);
        setExamCounts(object.examCountsList);
    }

    const loadExamGroups = async () => {
        const response = await examGroupServiceListExamGroups({});
        setExamGroups(response.toObject().examGroupsList);
    }

    const loadLessons = async () => {
        const response = await lessonServiceListLessons({});
        setLessons(response.toObject().lessonsList);
    }

    const loadExamTypes = async () => {
        const response = await examTypeServiceListExamTypes({});
        setExamTypes(response.toObject().examTypesList);
    }

    useEffect(() => {
        setSearchParams({
            areaId: formAreaId,
        });

        console.log("reload data");
        const fetchData = async () => {

            await loadBooklets();
        }
        fetchData();
    }, [formAreaId, formExamGroupId, formExamTypeId, formAcademicYear]);

    useEffect(() => {
        document.title = "Geçmiş Sınav Kitapçıkları"

        setBreadcrumbData([
            { name: 'Dash', url: '/' },
            { name: 'Geçmiş Sınav Kitapçıkları', url: '' },
        ]);
        loadScreen();
    }, []);

    const RenderContent = () => {
        if (loading) {
            return (
                <LoadingScreen />
            );
        }
        else if (error !== null) {
            return (
                <ErrorContent error={error} clickFunction={() => console.log("Tekrar Yükle")} />
            );
        }
        else {
            return (
                <table className="card-body table">
                    <thead>
                        <tr>
                            <th scope="col">Alan</th>
                            <th scope="col">Grup</th>
                            <th scope="col">Tip</th>
                            <th scope="col">Ders</th>
                            <th scope="col">Akademik Yıl</th>
                            <th scope="col">Tarih</th>
                            <th scope="col">Sınav</th>
                            <th scope="col">İşlemler</th>
                        </tr>
                        <tr>
                            <th scope="col">
                                <select id="form-area" className="form-select form-select-sm" select={formAreaId} value={formAreaId} onChange={e => {
                                    setFormAreaId(e.target.value);
                                    setFormExamGroupId("");
                                    setFormExamTypeId("");
                                    setFormLessonId("");
                                }}>
                                    <option key={`area-0`} value={""}>Belirtilmemiş</option>
                                    {
                                        areas.map((item, index) => (
                                            <option key={`area-${index}`} value={item.id}>{item.title}</option>
                                        ))
                                    }
                                </select>
                            </th>
                            <th scope="col">
                                <select disabled={formAreaId == ""} id="form-exam-group" className="form-select form-select-sm" select={formExamGroupId} value={formExamGroupId} onChange={e => setFormExamGroupId(e.target.value)}>
                                    <option key={`exam-group-0`} value={""}>Belirtilmemiş</option>
                                    {
                                        examGroups.filter(s => s.areaId == formAreaId).map((item, index) => (
                                            <option key={`exam-group-${index}`} value={item.id}>{item.title}</option>
                                        ))
                                    }
                                </select>
                            </th>
                            <th scope="col">
                                <select disabled={formAreaId == ""} id="form-exam-type" className="form-select form-select-sm" select={formExamTypeId} value={formExamTypeId} onChange={e => setFormExamTypeId(e.target.value)}>
                                    <option key={`exam-type-0`} value={""}>Belirtilmemiş</option>
                                    {
                                        examTypes.filter(s => s.areaId == formAreaId).map((item, index) => (
                                            <option key={`exam-type-${index}`} value={item.id}>{item.title}</option>
                                        ))
                                    }
                                </select>
                            </th>
                            <th scope="col">
                                <select disabled={formAreaId == ""} id="form-lesson" className="form-select form-select-sm" select={formLessonId} value={formLessonId} onChange={e => setFormLessonId(e.target.value)}>
                                    <option key={`lesson-0`} value={""}>Belirtilmemiş</option>
                                    {
                                        lessons.filter(s => s.areaId == formAreaId).map((item, index) => (
                                            <option key={`lesson-${index}`} value={item.id}>{item.title}</option>
                                        ))
                                    }
                                </select>
                            </th>
                            <th>
                                <select id="form-academic-year" className="form-select form-select-sm" select={formAcademicYear} value={formAcademicYear} onChange={e => setFormAcademicYear(e.target.value)}>
                                    {
                                        Object.values(academicYear()).map((value, index) => (
                                            <option key={"academic-year" + index} value={value}>{academicYear(value)}</option>
                                        ))
                                    }
                                </select>

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data === null || data.length === 0 ? <tr><td colSpan={7} className='text-center'>Henüz içerik eklenmemiş.</td></tr> : data.map((item, Index) => {
                                return (
                                    <tr key={"area_" + Index}>
                                        <td >{areas.find(s => s.id == item.areaId)?.title ?? "-"} </td>
                                        <td >{examGroups.find(s => s.id == item.examGroupId)?.title ?? "-"} </td>
                                        <td >{examTypes.find(s => s.id == item.examTypeId)?.title ?? "-"} </td>
                                        <td >{lessons.find(s => s.id == item.lessonId)?.title ?? "-"} </td>
                                        <td>{academicYear(item.academicYear)}</td>
                                        <td>{item.date ? new Date(item.date.seconds * 1000).toISOString().slice(0, -5).replace("T", " ") : "-"}</td>
                                        <td >{examCounts.find(s => s.bookletId == item.id)?.count ?? "-"} </td>

                                        <td>
                                            <div className="btn-group" role="group">
                                                <Link to={item.id.toString()} className="btn btn-sm btn-primary" role="button">DÜZENLE</Link>
                                                <Link target='_blank' to={`https://fma.sinavhane.net/booklet/${item.id}.pdf`} className="btn btn-sm btn-secondary" role="button">ÖNİZLE</Link>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            );
        }
    };

    return (
        <>
            <div className="container-fluid">

                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <Breadcrumbs data={breadcrumbData} />
                        <div className="btn-group">
                            <Link to="create" className="btn btn-sm btn-primary">+</Link>
                        </div>
                    </div>
                    {RenderContent()}

                </div>
            </div>

        </>
    );
}

export default PastExamBooklets;