import React, { useEffect } from "react";
import { AuthContext } from "../contexts/AuthContext";

function Home() {
    let auth = React.useContext(AuthContext);
    useEffect(() => {
        document.title = "Dash"
        console.log(auth.isAdmin());
    }, []);

    const RenderContent = () => {
        return (
            <div className="row">
                <h1>Dashboard</h1>
            </div>
        );
    };
    return (
        <>
            <div className="container">
                {RenderContent()}
            </div>
        </>
    );
}

export default Home;