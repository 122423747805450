/**
 * @fileoverview gRPC-Web generated client stub for dash.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: dash/v1/past_exam_booklet.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var dash_v1_models_pb = require('../../dash/v1/models_pb.js')

var types_v1_academic_year_pb = require('../../types/v1/academic_year_pb.js')

var types_v1_status_pb = require('../../types/v1/status_pb.js')
const proto = {};
proto.dash = {};
proto.dash.v1 = require('./past_exam_booklet_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.PastExamBookletServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dash.v1.PastExamBookletServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.GetPastExamBookletRequest,
 *   !proto.dash.v1.GetPastExamBookletResponse>}
 */
const methodDescriptor_PastExamBookletService_GetPastExamBooklet = new grpc.web.MethodDescriptor(
  '/dash.v1.PastExamBookletService/GetPastExamBooklet',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.GetPastExamBookletRequest,
  proto.dash.v1.GetPastExamBookletResponse,
  /**
   * @param {!proto.dash.v1.GetPastExamBookletRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.GetPastExamBookletResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.GetPastExamBookletRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.GetPastExamBookletResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.GetPastExamBookletResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.PastExamBookletServiceClient.prototype.getPastExamBooklet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.PastExamBookletService/GetPastExamBooklet',
      request,
      metadata || {},
      methodDescriptor_PastExamBookletService_GetPastExamBooklet,
      callback);
};


/**
 * @param {!proto.dash.v1.GetPastExamBookletRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.GetPastExamBookletResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.PastExamBookletServicePromiseClient.prototype.getPastExamBooklet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.PastExamBookletService/GetPastExamBooklet',
      request,
      metadata || {},
      methodDescriptor_PastExamBookletService_GetPastExamBooklet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.PutPastExamBookletRequest,
 *   !proto.dash.v1.PutPastExamBookletResponse>}
 */
const methodDescriptor_PastExamBookletService_PutPastExamBooklet = new grpc.web.MethodDescriptor(
  '/dash.v1.PastExamBookletService/PutPastExamBooklet',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.PutPastExamBookletRequest,
  proto.dash.v1.PutPastExamBookletResponse,
  /**
   * @param {!proto.dash.v1.PutPastExamBookletRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.PutPastExamBookletResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.PutPastExamBookletRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.PutPastExamBookletResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.PutPastExamBookletResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.PastExamBookletServiceClient.prototype.putPastExamBooklet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.PastExamBookletService/PutPastExamBooklet',
      request,
      metadata || {},
      methodDescriptor_PastExamBookletService_PutPastExamBooklet,
      callback);
};


/**
 * @param {!proto.dash.v1.PutPastExamBookletRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.PutPastExamBookletResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.PastExamBookletServicePromiseClient.prototype.putPastExamBooklet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.PastExamBookletService/PutPastExamBooklet',
      request,
      metadata || {},
      methodDescriptor_PastExamBookletService_PutPastExamBooklet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dash.v1.ListPastExamBookletsRequest,
 *   !proto.dash.v1.ListPastExamBookletsResponse>}
 */
const methodDescriptor_PastExamBookletService_ListPastExamBooklets = new grpc.web.MethodDescriptor(
  '/dash.v1.PastExamBookletService/ListPastExamBooklets',
  grpc.web.MethodType.UNARY,
  proto.dash.v1.ListPastExamBookletsRequest,
  proto.dash.v1.ListPastExamBookletsResponse,
  /**
   * @param {!proto.dash.v1.ListPastExamBookletsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dash.v1.ListPastExamBookletsResponse.deserializeBinary
);


/**
 * @param {!proto.dash.v1.ListPastExamBookletsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dash.v1.ListPastExamBookletsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dash.v1.ListPastExamBookletsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dash.v1.PastExamBookletServiceClient.prototype.listPastExamBooklets =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dash.v1.PastExamBookletService/ListPastExamBooklets',
      request,
      metadata || {},
      methodDescriptor_PastExamBookletService_ListPastExamBooklets,
      callback);
};


/**
 * @param {!proto.dash.v1.ListPastExamBookletsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dash.v1.ListPastExamBookletsResponse>}
 *     Promise that resolves to the response
 */
proto.dash.v1.PastExamBookletServicePromiseClient.prototype.listPastExamBooklets =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dash.v1.PastExamBookletService/ListPastExamBooklets',
      request,
      metadata || {},
      methodDescriptor_PastExamBookletService_ListPastExamBooklets);
};


module.exports = proto.dash.v1;

